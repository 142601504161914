import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomFormValidators } from '@spartacus/storefront';
import { LoginFormComponentService } from '@spartacus/user/account/components';

@Injectable()
export class CjLoginFormComponentService extends LoginFormComponentService {
  override form: UntypedFormGroup = new UntypedFormGroup({
    userId: new UntypedFormControl('', {
      validators: [Validators.required, CustomFormValidators.emailValidator],
      updateOn: 'blur'
    }),
    password: new UntypedFormControl('', Validators.required),
    rememberMe: new UntypedFormControl(false),
  });
}
