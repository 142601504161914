import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { CjCmsProductCard, CjProductCardDisplayType } from '../product-card.model';

@Component({
  selector: 'cj-product-card-wrapper',
  templateUrl: './product-card-wrapper.component.html',
  styleUrls: ['./product-card-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjProductCardWrapperComponent {
  displayTypes = CjProductCardDisplayType;

  constructor(readonly component: CmsComponentData<CjCmsProductCard>) {}
}
