import { Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import 'img-comparison-slider';
import { CjCmsComparativeBannerComponent } from './product-comparative-banner.model';

@Component({
  selector: 'cj-product-comparative-banner',
  templateUrl: './product-comparative-banner.component.html',
  styleUrls: ['./product-comparative-banner.component.scss'],
})
export class CjProductComparativeBannerComponent {
  constructor(protected component: CmsComponentData<CjCmsComparativeBannerComponent>) {}
}
