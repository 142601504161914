<ng-container *ngIf="order$ | async as order">
    <button 
        *ngIf="order.printTicket"
        (click)="downloadTicket(order)" 
        class="btn btn-block btn-outline-primary" 
        [disabled]="loading$ | async"
    >
        <span *ngIf="loading$ | async" class="spinner-border spinner-border-sm"></span>
        {{ 'orderDetails.actions.downloadInvoice.invoiceAction' | cxTranslate }}
    </button>
</ng-container>