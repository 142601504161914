import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CmsComponentData, LinkComponent } from '@spartacus/storefront';
import { CjCmsLinkComponent } from './link.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'cj-link',
  templateUrl: './link.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjLinkComponent extends LinkComponent {
  override data$: Observable<CjCmsLinkComponent> = this.data$;

  constructor(protected override component: CmsComponentData<CjCmsLinkComponent>) {
    super(component);
  }
}
