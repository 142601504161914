import { ChangeDetectionStrategy, Component, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { CheckoutPlaceOrderComponent } from '@spartacus/checkout/base/components';
import { RoutingService, UserIdService, WindowRef } from '@spartacus/core';
import { OrderFacade } from '@spartacus/order/root';
import { LaunchDialogService } from '@spartacus/storefront';
import { combineLatest, map, take } from 'rxjs';
import { CjActiveCartService } from '../../cart/base/core/facade/active-cart.service';
import { CjCart } from '../../cart/base/models/cart.model';
import { CjCheckoutPaymentTypeFacade } from '../checkout-payment-type/facade/checkout-payment-type.facade';
import { CjPaymentType } from '../checkout-payment-type/model/payment-types.model';
import { CjRedsysService } from './redsys/redsys.service';

@Component({
  selector: 'cj-place-order',
  templateUrl: './checkout-place-order.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjCheckoutPlaceOrderComponent extends CheckoutPlaceOrderComponent {
  selectedPayment$ = this.activeCartService.getActive().pipe(map((cart) => (cart as CjCart)?.paymentMode));
  paymentType = CjPaymentType;

  constructor(
    protected override orderFacade: OrderFacade,
    protected override routingService: RoutingService,
    protected override fb: UntypedFormBuilder,
    protected override launchDialogService: LaunchDialogService,
    protected override vcr: ViewContainerRef,
    private readonly activeCartService: CjActiveCartService,
    private readonly userIdService: UserIdService,
    private readonly redsysService: CjRedsysService,
    private readonly checkoutPaymentTypeFacade: CjCheckoutPaymentTypeFacade,
    private readonly winRef: WindowRef,
  ) {
    super(orderFacade, routingService, fb, launchDialogService, vcr);
  }

  payWithRedsys() {
    if (this.winRef.isBrowser()) {
      combineLatest([
        this.userIdService.getUserId(),
        this.activeCartService.takeActiveCartId(),
        this.checkoutPaymentTypeFacade.getSavePaymentCard(),
      ])
        .pipe(
          take(1),
          map(([userId, cartId, saveCard]) => {
            this.redsysService.pay(userId, cartId, saveCard);
          }),
        )
        .subscribe();
    }
  }
}
