import { Injectable } from '@angular/core';
import { AuthStatePersistenceService, StorageSyncType } from '@spartacus/core';

@Injectable({ providedIn: 'root' })
export class CjAuthStatePersistenceService extends AuthStatePersistenceService {
  override initSync() {
    this.subscription.add(
      this.statePersistenceService.syncWithStorage({
        key: this.key,
        state$: this.getAuthState(),
        onRead: (state) => this.onRead(state),
        storageType: StorageSyncType.LOCAL_STORAGE,
      }),
    );
  }
}
