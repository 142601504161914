import { Component, OnInit } from '@angular/core';
import { CjPaypalService } from './paypal-button.service';

@Component({
  selector: 'cj-paypal-button',
  templateUrl: './paypal-button.component.html',
})
export class CjPaypalButtonComponent implements OnInit {
  constructor(protected paypalService: CjPaypalService) {}

  ngOnInit(): void {
    this.paypalService.loadButtons();
  }
}
