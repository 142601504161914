import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { fromEvent, map, startWith } from 'rxjs';
import { BREAKPOINTS } from 'src/app/shared/constants/screen-breakpoints';
import { CjCmsWebsiteLogos } from './logo.model';

@Component({
  selector: 'cj-logo',
  templateUrl: './logo.component.html',
  styleUrl: './logo.component.scss',
})
export class CjLogoComponent implements OnInit {
  screenWidth?: number;
  breakpoints = BREAKPOINTS;

  constructor(
    protected componentData: CmsComponentData<CjCmsWebsiteLogos>,
    private readonly windowRef: WindowRef,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.screenWidth = this.windowRef.nativeWindow?.innerWidth;

    if (this.windowRef.nativeWindow) {
      fromEvent(this.windowRef.nativeWindow, 'resize')
        .pipe(
          map(() => this.windowRef.nativeWindow?.innerWidth),
          startWith(this.windowRef.nativeWindow?.innerWidth),
        )
        .subscribe((size) => {
          this.screenWidth = size;
          this.cdr.detectChanges();
        });
    }
  }
}
