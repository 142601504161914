import { Component, HostListener, ElementRef } from '@angular/core';
import { Product, WindowRef } from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'cj-product-scroll-to-top',
  templateUrl: './product-scroll-to-top.component.html',
  styleUrls: ['./product-scroll-to-top.component.scss'],
})
export class CjProductScrollToTopComponent {
  product$: Observable<Product | null> = this.currentProductService.getProduct();
  hasPassedScroll = false;

  constructor(
    protected currentProductService: CurrentProductService,
    private elementRef: ElementRef,
    private windowRef: WindowRef,
  ) {}

  scrollToTop() {
    if (this.windowRef.isBrowser() && this.windowRef.nativeWindow) {
      this.windowRef.nativeWindow.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (this.windowRef.isBrowser() && this.windowRef.nativeWindow) {
      const element = this.elementRef.nativeElement;

      // Obtener la posición relativa al viewport del elemento
      const elementRect = element.getBoundingClientRect();

      // Calcular la posición relativa al documento
      const elementTopRelativeToDocument = elementRect.top + this.windowRef.nativeWindow.scrollY;

      // Comparar las posiciones de desplazamiento
      if (this.windowRef.nativeWindow.scrollY >= elementTopRelativeToDocument) {
        this.hasPassedScroll = true;
      } else {
        this.hasPassedScroll = false;
      }
    }
  }
}
