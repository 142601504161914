import { Component } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { CustomFormValidators } from '@spartacus/storefront';
import { RegisterComponent } from '@spartacus/user/profile/components';

@Component({
  selector: 'cj-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class CjRegisterComponent extends RegisterComponent {
  override registerForm: UntypedFormGroup = this.fb.group(
    {
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: [
        '',
        {
          validators: [
            Validators.required,
            CustomFormValidators.emailValidator,
          ],
          updateOn: 'blur',
        },
      ],
      password: [
        '',
        [Validators.required, CustomFormValidators.passwordValidator],
      ],
      passwordconf: ['', Validators.required],
      newsletter: new UntypedFormControl({
        value: false,
        disabled: this.overrideIsConsentRequired(),
      }),
      additionalConsents:
        this.registerComponentService.generateAdditionalConsentsFormControl?.() ??
        this.fb.array([]),
      termsandconditions: [false, Validators.requiredTrue],
    },
    {
      validators: CustomFormValidators.passwordsMustMatch(
        'password',
        'passwordconf',
      ),
    },
  );

  overrideIsConsentRequired(): boolean {
    this.anonymousConsentsService.loadTemplates();

    const requiredConsents =
      this.anonymousConsentsConfig?.anonymousConsents?.requiredConsents;
    const registerConsent =
      this.anonymousConsentsConfig?.anonymousConsents?.registerConsent;

    if (requiredConsents && registerConsent) {
      return requiredConsents.includes(registerConsent);
    }

    return false;
  }
}
