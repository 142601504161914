import { Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CjCmsUtilsService } from 'src/app/shared/utils/cms-utils.service';
import { OsborneFooterIconComponent, OsborneFooterPaymentMethodsComponent } from '../footer-navigation.model';

@Component({
  selector: 'cj-footer-payment-methods',
  templateUrl: './footer-payment-methods.component.html',
  styleUrls: ['./footer-payment-methods.component.scss'],
})
export class CjFooterPaymentMethodsComponent {
  constructor(
    protected cmsUtils: CjCmsUtilsService,
    protected componentData: CmsComponentData<OsborneFooterPaymentMethodsComponent>,
  ) {}

  icons$: Observable<Observable<OsborneFooterIconComponent>[]> = this.componentData.data$.pipe(
    map((data) => {
      if (!data.icons) {
        return [];
      }
      return this.cmsUtils.getComponentDataList<OsborneFooterIconComponent>(data.icons);
    }),
  );
}
