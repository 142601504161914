<ng-container *ngIf="componentData.data$ | async as data">
  <div class="cj-nav-tabs" *ngIf="data.navigationNode?.children">
    <div class="cj-nav-tabs__child" *ngFor="let node of data.navigationNode?.children">
      <ng-container *ngIf="node.entries && node.entries[0] as link">
        <div *ngIf="link.itemId" class="cj-nav-tabs__item" [class.active]="isActiveCategory(link.itemId) | async">
          <ng-container
            [cxComponentWrapper]="{
              flexType: link.itemType,
              typeCode: link.itemType,
              uid: link.itemId
            }"
          ></ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
