import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { GlobalMessageService, TranslationService } from '@spartacus/core';
import { OrderConfirmationThankYouMessageComponent } from '@spartacus/order/components';
import { OrderFacade, OrderHistoryFacade } from '@spartacus/order/root';
import { filter, tap } from 'rxjs';

@Component({
  selector: 'cj-order-confirmation-thank-you-message',
  templateUrl: './order-confirmation-thank-you-message.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjOrderConfirmationThankYouMessageComponent extends OrderConfirmationThankYouMessageComponent {
  orderCode?: string;
  clientName?: string;
  clientEmail?: string;

  override ngOnInit(): void {
    this.order$ = this.orderHistoryFacade.getOrderDetails().pipe(
      filter((o) => !!o && !!Object.keys(o).length),
      tap((order) => {
        this.isGuestCustomer = order && 'guestCustomer' in order ? order.guestCustomer ?? false : false;
        this.orderGuid = order?.guid;
        this.orderCode = order?.code;
        this.clientName = order.user?.name;
        this.clientEmail = order.user?.uid?.split('|').reverse()[0];
        this.cdr.detectChanges();
      }),
    );
  }

  constructor(
    protected override orderFacade: OrderFacade,
    protected override globalMessageService: GlobalMessageService,
    protected override translationService: TranslationService,
    private readonly orderHistoryFacade: OrderHistoryFacade,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super(orderFacade, globalMessageService, translationService);
  }
}
