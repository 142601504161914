export const myAccount = {
  myAccountCommon: {
    hello: 'Salut, ',
  },
  updatePasswordForm: {
    passwordInfo: 'Mot de passe',
    oldPassword: {
      label: 'Ancien mot de passe',
      placeholder: 'Ancien mot de passe',
    },
    oldPasswordIsRequired: 'L`ancien mot de passe est requis.',
    newPassword: {
      label: 'Nouveau mot de passe',
      placeholder: 'Nouveau mot de passe',
    },
    passwordMinRequirements:
      'Le mot de passe doit comporter au minimum six caractères, dont une lettre majuscule, un chiffre et un symbole.',
    confirmPassword: {
      label: 'Confirmer le nouveau mot de passe',
      placeholder: 'Confirmez le mot de passe',
    },
    bothPasswordMustMatch: 'Les deux mots de passe doivent correspondre',
    passwordUpdateSuccess: 'Mot de passe mis à jour avec succès',
    accessDeniedError: 'L`accès est refusé',
  },
  updateProfileForm: {
    title: 'Traitement',
    firstName: {
      label: 'Nom et prénom',
      placeholder: 'Nom et prénom',
    },
    firstNameIsRequired: 'Le nom est requis.',
    contactInfo: 'Contact',
    addressInfo: 'Adresse de facturation',
    email: 'Courrier électronique',
    documentIdentifier: 'NIF',
    phone: 'Numéro de téléphone (pour l`opérateur)',
    address: 'Adresse',
    postalCode: 'Code postal',
    town: 'Ville',
    country: {
      label: 'Pays',
      placeholder: 'Pays',
    },
    region: {
      label: 'Région',
      placeholder: 'Région',
    },
    profileUpdateSuccess: 'Données personnelles mises à jour avec succès',
    updateEmailText: 'Para modificaciones del email, por favor ponte en contacto con sac_web@osborne.es'
  },
};
