<ng-container *ngIf="(product$ | async) || product as product">
  <div [class]="displayType" [ngSwitch]="displayType">
    <ng-container *ngSwitchCase="displayTypes.OPTION_ONE">
      <cx-generic-link [url]="product.url || ''">
        <div class="product-card-media">
          <ng-container *ngTemplateOutlet="image"></ng-container>
        </div>
        <div class="product-card-name">
          <h3 *ngIf="product.name">{{ product.name | uppercase }}</h3>
        </div>
        <p *ngIf="product.shortDescription" class="product-card-shortDescription">
          {{ product.shortDescription }}
        </p>
      </cx-generic-link>
      <cx-generic-link *ngIf="product.url" class="product-card-link" [url]="product.url">
        {{ 'common.discover' | cxTranslate | uppercase }}
      </cx-generic-link>
    </ng-container>

    <ng-container *ngSwitchCase="displayTypes.OPTION_TWO">
      <cx-generic-link class="product-card-media" [url]="product.url || ''">
        <ng-container *ngTemplateOutlet="image"></ng-container>
        <span class="product-card-stock" *ngIf="product.stock?.stockLevelStatus" [class]="product?.webAvailability">
          {{ 'webAvailability.' + product?.webAvailability | cxTranslate: { count: product.stock?.stockLevel } }}
        </span>
      </cx-generic-link>

      <cx-generic-link class="product-card-name" [url]="product.url || ''">
        <h3 *ngIf="product.name">{{ product.name | uppercase }}</h3>
      </cx-generic-link>
      <ng-container *ngIf="product.discountPrice?.formattedValue; then discountPrice; else price"></ng-container>
      <cx-generic-link *ngIf="product.url" class="product-card-link" [url]="product.url">
        {{ 'common.discover' | cxTranslate | uppercase }}
      </cx-generic-link>
    </ng-container>

    <ng-container *ngSwitchCase="displayTypes.OPTION_THREE">
      <cx-generic-link [url]="product.url || ''">
        <div class="product-card-content">
          <div class="product-card__info">
            <div class="product-card-name">
              <h3 *ngIf="product.name">{{ product.name | uppercase }}</h3>
            </div>
            <p *ngIf="product.shortDescription" class="product-card-shortDescription">
              {{ product.shortDescription }}
            </p>
            <ng-container *ngIf="product.discountPrice?.formattedValue; then discountPrice; else price"></ng-container>
          </div>

          <div class="product-card-media">
            <ng-container *ngTemplateOutlet="image"></ng-container>
          </div>

          <a [routerLink]="product.url" class="product-card-link">
            {{ 'common.discover' | cxTranslate | uppercase }}
          </a>

          <span class="product-card-stock" *ngIf="product.stock?.stockLevelStatus" [class]="product?.webAvailability">
            {{ 'webAvailability.' + product?.webAvailability | cxTranslate: { count: product.stock?.stockLevel } }}
          </span>
        </div>
      </cx-generic-link>
    </ng-container>

    <div *ngIf="product.discountTag" class="discount-tag">
      {{ product.discountTag }}
    </div>
  </div>

  <ng-template #image>
    <cx-media
      *ngIf="product.images && (product.images['ARTISTIC'] || product.images['PRIMARY'])"
      [container]="artistic ? product.images['ARTISTIC'] || product.images['PRIMARY'] : product.images['PRIMARY']"
      gsapAnimation
      [animationType]="'fadeInSimple'"
    >
    </cx-media>
    <cx-media *ngIf="hover && product.images && product.images['HOVER']" class="hover" [container]="product.images['HOVER']"></cx-media>
  </ng-template>

  <ng-template #price>
    <p *ngIf="product.price?.formattedValue" class="product-card-price">
      {{ product.price?.formattedValue }}
    </p>
  </ng-template>

  <ng-template #discountPrice>
    <p *ngIf="product.price?.formattedValue" class="product-card-price">
      <span class="product-card-price__discounted">
        {{ product.discountPrice?.formattedValue }}
      </span>
      <span *ngIf="product.price?.formattedValue" class="product-card-price__original">
        {{ product.price?.formattedValue }}
      </span>
    </p>
  </ng-template>
</ng-container>
