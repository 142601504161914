<div *ngIf="component.data$ | async as data" class="cj-product-healthy-slider">
  <swiper-container #swiperContainer *ngIf="slides$ | async as slides" init="false" navigation="true">
    <ng-container *ngIf="screenWidth && screenWidth >= breakpoints.LG">
      <swiper-slide *ngIf="data.title">
        <h2 class="cj-product-healthy-slider__title">
          {{ data.title }}
        </h2>
      </swiper-slide>
    </ng-container>

    <swiper-slide *ngFor="let slide$ of slides">
      <cj-product-healthy-item *ngIf="slide$ | async as item" [item]="item"> </cj-product-healthy-item>
    </swiper-slide>
  </swiper-container>
</div>
