import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { MediaComponent } from '@spartacus/storefront';
import { CjCmsBannerComponentMedia } from './media.model';

@Component({
  selector: 'cj-media',
  templateUrl: './media.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjMediaComponent extends MediaComponent implements AfterViewInit {
  @ViewChild('videoPlayer') videoPlayer!: ElementRef<HTMLVideoElement>;

  ngAfterViewInit(): void {
    if (this.videoPlayer && this.isVideo(this.container)) {
      this.playVideo();
    }
  }

  playVideo(): void {
    const media = this.videoPlayer.nativeElement;
    media.muted = true;
    media.play().catch((error: DOMException) => {
      console.error('Error al reproducir el video automáticamente:', error);
    });
  }

  isVideo(container: unknown): boolean {
    return !!(
      (container as CjCmsBannerComponentMedia)?.mime?.includes('video') ||
      (container as CjCmsBannerComponentMedia).isExternalUrlVideo === true
    );
  }
}
