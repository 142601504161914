<div class="cj-footer-categories" *ngIf="componentData.data$ | async as data">
  <div class="row" *ngIf="data.navigationNode?.children">
    <div class="col-sm-4" *ngFor="let node of data.navigationNode?.children">
      <div class="cj-footer-categories__item">
        <h4 class="cj-footer-categories__title" *ngIf="node.title && node.uid" (click)="toggleCollapse(node.uid)">
          {{ node.title }}
        </h4>
        <ul class="cj-footer-categories__list list-unstyled {{ node.uid }}" *ngIf="node.uid" [class.collapsed]="isCollapsed(node.uid)">
          <li *ngFor="let node of node.children">
            <cj-footer-categories-entry *ngFor="let entry of node.entries" [entry]="entry"></cj-footer-categories-entry>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
