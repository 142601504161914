import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'cj-item-counter',
  templateUrl: './item-counter.component.html',
  styleUrls: ['./item-counter.component.scss'],
})
export class CjItemCounterComponent implements OnInit, OnDestroy {
  @Input() control!: UntypedFormControl;
  @Input() min = 1;
  @Input() max!: number;
  @Input() step = 1;
  @Input() allowZero = false;
  @Input() readOnly = false;

  private sub!: Subscription;
  quantityOptions: number[] = [];

  isOpen = false;
  optionsOpen = false;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.sub = this.control.valueChanges
      .pipe(startWith(this.control.value))
      .subscribe((value) => {
        this.control.setValue(this.getValidCount(value), { emitEvent: false });
      });
    this.calculateQuantityOptions();
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  toggleOptions() {
    this.optionsOpen = !this.optionsOpen;
  }

  selectOption(option: number, event: Event) {
    event.stopPropagation();
    this.control.setValue(option);
    this.optionsOpen = false;
  }

  private getValidCount(value: number) {
    if (value < this.min && !(value === 0 && this.allowZero)) {
      value = this.min;
    }
    if (this.max && value > this.max) {
      value = this.max;
    }
    return value;
  }

  private calculateQuantityOptions() {
    this.quantityOptions = [];
    for (let i = this.min; i <= this.max; i += this.step) {
      this.quantityOptions.push(i);
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.optionsOpen = false;
    }
  }
}
