<div *ngIf="component.data$ | async as data" (click)="openDialog(data.modal)" class="cj-delivery-banner">
  <ng-container *ngFor="let card$ of cards$ | async">
    <div *ngIf="card$ | async as card" class="cj-delivery-banner-item">
      <cx-media *ngIf="card.icon" class="cj-delivery-banner-item__icon" [container]="card.icon"></cx-media>
      <span *ngIf="card.primaryText" class="cj-delivery-banner-item__primary-text">
        {{ card.primaryText }}
      </span>
      <span *ngIf="card.secondaryText" class="cj-delivery-banner-item__secondary-text">
        {{ card.secondaryText }}
      </span>
    </div>
  </ng-container>
</div>
