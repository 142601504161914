<div *ngIf="component.data$ | async as data" class="cj-product-cutter-banner">
  <div class="cj-product-cutter-banner__video">
    <cj-media *ngIf="data.video as media" [container]="cjCmsMediaService.getMedia(media)"></cj-media>
  </div>
  <div class="cj-product-cutter-banner__info">
    <p *ngIf="data.primaryTitle" class="cj-product-cutter-banner__primary-title">
      {{ data.primaryTitle }}
    </p>
    <p *ngIf="data.secondaryTitle" class="cj-product-cutter-banner__secondary-title">
      {{ data.secondaryTitle }}
    </p>
    <p *ngIf="data.description" class="cj-product-cutter-banner__description">
      {{ data.description }}
    </p>
    <ng-container *ngIf="data.link">
      <button *ngIf="data.link.linkName" (click)="openDialog(data.video)">
        {{ data.link.linkName }}
      </button>
    </ng-container>
  </div>
</div>
