import { Component } from '@angular/core';
import { CmsComponentData, CurrentProductService } from '@spartacus/storefront';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { CjStructuredDataUtilsService } from 'src/app/seo/structured-data/structured-data-utils-service';
import { CjCmsUtilsService } from 'src/app/shared/utils/cms-utils.service';
import { CjCmsFAQComponent, CjCmsFAQItemComponent } from './product-faq-banner.model';

@Component({
  selector: 'cj-product-faq-banner',
  templateUrl: './product-faq-banner.component.html',
  styleUrls: ['./product-faq-banner.component.scss'],
})
export class CjProductFaqBannerComponent {
  collapsedNodes: Set<string> = new Set();

  qas$: Observable<Observable<CjCmsFAQItemComponent>[]> = this.component.data$.pipe(
    map((data) => data.qas),
    filter((qas) => !!qas),
    map((qas) => {
      if (!qas) {
        return [];
      }
      return this.cmsUtils.getComponentDataList<CjCmsFAQItemComponent>(qas);
    }),
  );

  structuredData$: Observable<Object> = this.qas$.pipe(
    take(1),
    switchMap((qas) => combineLatest(qas)),
    switchMap((qas) =>
      this.currentProductService.getProduct().pipe(
        take(1),
        map((product) => ({
          '@type': 'FAQPage',
          // TODO: Handle ID for different pages (ProductPage, CategoryPage, FAQPage)
          '@id': (product?.url ? this.structuredDataUtils.hostUrl + product.url : this.structuredDataUtils.currentUrl) + '#faq',
          mainEntity: qas.map((qa) => ({
            '@type': 'Question',
            name: qa.question,
            acceptedAnswer: {
              '@type': 'Answer',
              text: qa.answer,
            },
          })),
        })),
      ),
    ),
  );

  constructor(
    protected component: CmsComponentData<CjCmsFAQComponent>,
    protected cmsUtils: CjCmsUtilsService,
    private readonly structuredDataUtils: CjStructuredDataUtilsService,
    private readonly currentProductService: CurrentProductService,
  ) {}

  toggleCollapse(nodeUid: string): void {
    if (this.collapsedNodes.has(nodeUid)) {
      this.collapsedNodes.delete(nodeUid);
    } else {
      this.collapsedNodes.add(nodeUid);
    }
  }

  isCollapsed(nodeUid: string): boolean {
    return this.collapsedNodes.has(nodeUid);
  }
}
