import { Component } from '@angular/core';
import { User } from '@spartacus/core';
import { LoginComponent } from '@spartacus/user/account/components';

@Component({
  selector: 'cj-login',
  templateUrl: './login.component.html',
})
export class CjLoginComponent extends LoginComponent {
  getInitials(user: User): string {
    // Check if the name is null or an empty string
    if (!user.name || user.name.trim() === '') {
      return '';
    }
    // Split the name into an array of words
    const words = user.name.trim().split(/\s+/);
    // Get the first letter of each word
    const initials = words.map(function (word) {
      return word.charAt(0).toUpperCase();
    });
    // Return the first two initials
    return initials.slice(0, 2).join('');
  }
}
