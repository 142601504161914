import { NgModule } from '@angular/core';
import {
  BreadcrumbSchemaBuilder,
  JsonLdBuilderModule,
  JsonLdScriptFactory,
  ProductSchemaBuilder,
  SCHEMA_BUILDER,
} from '@spartacus/storefront';
import { CjJsonLdScriptFactory } from './json-ld-script.factory';
import { CjOfferCatalogSchemaBuilder } from './schema-builders/category/offer-catalog-schema.builder';
import { CjBreadcrumbSchemaBuilder } from './schema-builders/common/breadcrumb-schema.builder';
import { CjOrganizationSchemaBuilder } from './schema-builders/common/organization-schema.builder';
import { CjWebPageSchemaBuilder } from './schema-builders/common/web-page-schema.builder';
import { CjWebSiteSchemaBuilder } from './schema-builders/common/web-site-schema.builder';
import { CjItemPageSchemaBuilder } from './schema-builders/product/item-page-schema.builder';
import { CjProductImageSchemaBuilder } from './schema-builders/product/product-image-schema.builder';
import { CjProductSchemaBuilder } from './schema-builders/product/product-schema.builder';

@NgModule({
  imports: [JsonLdBuilderModule],
  providers: [
    // Product detail page
    {
      provide: ProductSchemaBuilder,
      useExisting: CjProductSchemaBuilder,
    },
    {
      provide: SCHEMA_BUILDER,
      useExisting: CjProductImageSchemaBuilder,
      multi: true,
    },
    {
      provide: SCHEMA_BUILDER,
      useExisting: CjItemPageSchemaBuilder,
      multi: true,
    },

    // Product list page
    {
      provide: SCHEMA_BUILDER,
      useExisting: CjOfferCatalogSchemaBuilder,
      multi: true,
    },

    // Common
    {
      provide: JsonLdScriptFactory,
      useExisting: CjJsonLdScriptFactory,
    },
    {
      provide: SCHEMA_BUILDER,
      useExisting: CjOrganizationSchemaBuilder,
      multi: true,
    },
    {
      provide: SCHEMA_BUILDER,
      useExisting: CjWebSiteSchemaBuilder,
      multi: true,
    },
    {
      provide: SCHEMA_BUILDER,
      useExisting: CjWebPageSchemaBuilder,
      multi: true,
    },
    {
      provide: BreadcrumbSchemaBuilder,
      useExisting: CjBreadcrumbSchemaBuilder,
    },
  ],
})
export class CjStructuredDataModule {}
