import { Component, Input } from '@angular/core';
import { UserIdService, WindowRef } from '@spartacus/core';
import { Order } from '@spartacus/order/root';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { CjOrderDetailTicketDownloadService } from './cj-order-detail-ticket-download.service';
import { CjOrder } from 'src/app/cms-components/order/order.model';

@Component({
  selector: 'cj-order-detail-ticket-download',
  templateUrl: './order-detail-ticket-download.component.html',
})
export class CjOrderDetailTicketDownloadComponent {
  @Input() order$: Observable<CjOrder> | undefined;

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    protected userIdService: UserIdService,
    protected downloadService: CjOrderDetailTicketDownloadService,
    protected winRef: WindowRef,
  ) { }


  downloadTicket(order: Order): void {
    this.loading$.next(true);
    this.userIdService.getUserId().pipe(
      take(1)
    ).subscribe((userId) => {
      this.downloadService.getOrderTicketDownloadUrl(userId, order.code)
        .pipe(
          take(1),
        )
        .subscribe((downloadUrl) => {
          this.loading$.next(false);
          if (downloadUrl) {
            const link = this.winRef.document?.createElement('a');
            link.href = downloadUrl;
            link.download = order.code ?? order.guid ?? "";
            link.hidden = true;
            this.winRef.document?.body?.appendChild(link);
            link.click();
            this.winRef.document?.body?.removeChild(link);
          }
        })
    });
  }

}
