export const checkout = {
  checkout: {
    securePurchase: 'Achat sécurisé',
  },
  checkoutAddress: {
    addNewAddress: 'Ajouter une nouvelle adresse',
    viewAddresses: 'Voir mes adresses',
    defaultDeliveryAddressTitle: '',
    deliveryAddressSelected: 'Adresse de livraison sélectionnée',
  },
  checkoutGift: {
    giftWithMessage: 'Cadeau avec un message personnalisé',
    giftMessagePlaceholder: 'Message cadeau',
  },
  checkoutPayment: {
    paymentType: {
      CreditCard: 'Paiement par carte de crédit',
      Bizum: 'Paiement avec Bizum',
      PayPal: 'Paiement avec PayPal',
    },
    newCard: 'Nouvelle carte',
    saveCard: 'Conservez cette carte pour vos futurs achats',
    confirmThatRead: 'En passant votre commande, vous acceptez notre',
    termsAndConditions: 'politique de confidentialité',
    placeOrder: 'Passer une commande',
  },
  paypal: {
    error: {
      default: 'Une erreur s’est produite avec PayPal. Veuillez réessayer plus tard.',
      detail: 'Impossible de finaliser la transaction. {{msg}}',
      cancel: 'Paiement annulé.',
    },
  },
  checkoutOrderConfirmation: {
    thankYou: 'Merci beaucoup de <br>faire confiance à Cinco Jotas, <br><span>{{name}}</span>.',
    invoiceArriveTime: 'Dans 24 à 48 heures ouvrables, vous recevrez votre commande.',
    invoiceCancelTime: "Vous disposez d'une marge de 30 minutes si vous souhaitez annuler la commande",
    orderNumber: 'N° de commande: {{code}}',
    orderItems: 'Ma commande',
    createYourAccount: 'Créez votre compte 5J',
    createAccountRequirements: {
      requirements: "Vous avez seulement besoin d'un mot de passe pour :",
      followOrder: 'Suivre votre commande.',
      downloadInvoices: 'Téléchargez les factures.',
      buyQuicker: 'Achetez plus vite.',
    },
    wantToCreateAccount: 'Je souhaite créer mon compte Cinco Jotas',
    createAccount: 'Créer un compte 5J',
    createAccountForNext: 'Créez un compte avec <b>{{email}}</b> pour un paiement plus rapide lors de votre prochaine visite.',
    continueBuying: 'Continuer à acheter',
    orderDetails: 'Détails de la commande',
  },
};
