import { Injectable } from '@angular/core';
import { CurrentProductService, SchemaBuilder } from '@spartacus/storefront';
import { Observable, of } from 'rxjs';
import { CjStructuredDataUtilsService } from '../../structured-data-utils-service';

@Injectable({
  providedIn: 'root',
})
export class CjWebSiteSchemaBuilder implements SchemaBuilder {
  constructor(
    private readonly utils: CjStructuredDataUtilsService,
    private readonly currentProduct: CurrentProductService,
  ) {}

  build(): Observable<any> {
    // Common to all pages
    return of({
      '@type': 'WebSite',
      '@id': this.utils.hostUrl + '/#website',
      url: this.utils.hostUrl + '/',
      name: this.utils.siteName,
    });
  }
}
