import { NgModule } from '@angular/core';
import { CheckoutComponentsModule } from '@spartacus/checkout/base/components';
import {
  CheckoutConnector,
  CheckoutCoreModule,
  CheckoutPageMetaResolver,
  CheckoutPaymentService
} from '@spartacus/checkout/base/core';
import { CheckoutOccModule } from '@spartacus/checkout/base/occ';
import { CjCheckoutPageMetaResolver } from 'src/app/seo/meta-tags/meta-resolvers/checkout-page-meta.resolver';
import { CjCheckoutConnector } from './connectors/checkout.connector';
import { CjCheckoutPaymentService } from './facade/checkout-payment.service';
import { CjCheckoutStoreModule } from './store/checkout-store.module';

@NgModule({
  imports: [CheckoutComponentsModule, CheckoutCoreModule, CheckoutOccModule, CjCheckoutStoreModule],
  providers: [
    { provide: CheckoutPaymentService, useClass: CjCheckoutPaymentService },
    { provide: CheckoutConnector, useClass: CjCheckoutConnector },
    { provide: CheckoutPageMetaResolver, useExisting: CjCheckoutPageMetaResolver },
  ],
})
export class CjCheckoutModule {}
