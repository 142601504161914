<div
  class="cj-product-multimedia-banner"
  *ngIf="component.data$ | async as data"
>
  <div
    class="cj-product-multimedia-banner__container"
    gsapAnimation
    [animationType]="'fadeInStaggered'"
  >
    <div class="cj-product-multimedia-banner__small-image">
      <cx-media
        *ngIf="data.smallImage"
        [container]="data.smallImage"
      ></cx-media>
    </div>
    <div class="cj-product-multimedia-banner__image">
      <cx-media
        *ngIf="data.image"
        [container]="data.image"
        class="cj-media--zoom"
        gsapAnimation
        [animationType]="'zoomInOut'"
      ></cx-media>
    </div>
    <div class="cj-product-multimedia-banner__info">
      <p *ngIf="data.title" class="cj-product-multimedia-banner__title">
        {{ data.title }}
      </p>
      <p
        *ngIf="data.description?.content"
        class="cj-product-multimedia-banner__text"
        [innerHTML]="data.description?.content | sanitizeHtml"
      ></p>
      <cj-product-characteristics
        *ngIf="data.characteristics"
        [characteristics]="data.characteristics"
      >
      </cj-product-characteristics>
    </div>
  </div>
</div>
