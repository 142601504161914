import { Injectable } from '@angular/core';
import { Image } from '@spartacus/core';
import { Media, MediaContainer, MediaService } from '@spartacus/storefront';
import { CjImage } from './media.model';

@Injectable({
  providedIn: 'root',
})
export class CjMediaService extends MediaService {
  /**
   * Returns a `Media` object with the main media (`src`) and various media (`src`)
   * for specific formats.
   */
  override getMedia(mediaContainer?: MediaContainer | Image, format?: string, alt?: string, role?: string): Media | undefined {
    if (!mediaContainer) {
      return;
    }

    const mainMedia: CjImage = (
      mediaContainer.url ? mediaContainer : this.resolveMedia(mediaContainer as MediaContainer, format)
    ) as CjImage;

    return {
      src: this.resolveAbsoluteUrl(mainMedia?.externalUrl ?? mainMedia?.url ?? ''),
      alt: alt ?? mainMedia?.altText,
      role: role ?? mainMedia?.role,
      srcset: this.resolveSrcSet(mediaContainer, format),
    };
  }

  /**
   * Returns a set of media for the available media formats. Additionally, the configured media
   * format width is added to the srcset, so that browsers can select the appropriate media.
   *
   * The optional maxFormat indicates that only sources till a certain format should be added
   * to the srcset.
   */
  protected override resolveSrcSet(media: MediaContainer | Image, maxFormat?: string): string | undefined {
    if (!media) {
      return undefined;
    }

    // Only create srcset images that are smaller than the given `maxFormat` (if any)
    let formats = this.sortedFormats;
    const max: number = formats.findIndex((f) => f.code === maxFormat);
    if (max > -1) {
      formats = formats.slice(0, max + 1);
    }

    const srcset = formats.reduce((set, format) => {
      const image: CjImage = (media as MediaContainer)[format.code] as CjImage;
      if (image) {
        if (set) {
          set += ', ';
        }
        set += `${this.resolveAbsoluteUrl(image.externalUrl ?? image.url ?? '')} ${format.size.width}w`;
      }
      return set;
    }, '');

    return srcset === '' ? undefined : srcset;
  }
}
