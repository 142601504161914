/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export enum CjProductDetailOutlets {
  INTRO = 'PDP.INTRO',
  SHARE = 'PDP.SHARE',
  NAME = 'PDP.NAME',
  SUMMARY = 'PDP.SUMMARY',
  DESCRIPTION = 'PDP.DESCRIPTION',
  PRICE = 'PDP.PRICE',
  ATTRIBUTES = 'PDP.ATTRIBUTES',
}
export enum CjProductListOutlets {
  ITEM_ACTIONS = 'cx-product-list-item.actions',
  ITEM_DETAILS = 'cx-product-list-item.details',
}
