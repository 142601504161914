export const userProfile = {
  register: {
    registerTitle: 'No tengo una cuenta',
    createAccount: 'Crea una cuenta',
    termsAndConditions: 'Términos y condiciones',
    confirmThatRead: 'Confirmo que he leído y estoy de acuerdo con las',
    newsletter: 'Quiero recibir la newsletter',
    firstName: {
      label: 'Nombre',
      placeholder: 'Nombre',
    },
    lastName: {
      label: 'Apellido',
      placeholder: 'Apellido',
    },
    emailAddress: {
      label: 'Correo electrónico',
      placeholder: 'Correo electrónico',
    },
    password: {
      label: 'Contraseña',
      placeholder: 'Contraseña',
    },
    confirmPassword: {
      action: 'Confirmar Contraseña',
      label: 'Confirmar Contraseña',
      placeholder: 'Confirmar Contraseña',
    },
    postRegisterMessage: 'Por favor, inicie sesión con las credenciales proporcionadas.',
    newPassword: 'Nueva contraseña',
    passwordMinRequirements: 'La contraseña debe tener un mínimo de seis caracteres, una letra mayúscula, una letra minúscula, un número y un símbolo',
    resetPassword: 'Cambiar contraseña',
  },
  forgottenPassword: {
    resetPassword: 'Recuperar contraseña',
    resetButton: 'Recuperar',
    sendResetPassword: 'Cambiar contraseña',
    enterEmailAddressAssociatedWithYourAccount: 'Introduce la contraseña asociada a tu cuenta',
    emailAddress: {
      label: 'Email',
      placeholder: 'Email',
    },
    enterValidEmail: 'Por favor, introduce un email váido.',
    passwordResetEmailSent: 'Si existe una cuenta con el email proporcionado, recibirás un correo para restablecer tu contraseña.',
    passwordResetSuccess: 'Éxito! Puedes iniciar sesión con tu nueva contraseña.',
    TokenInvalidatedError:
      'El enlace utilizado no es correcto. Si ha realizado varios intentos asegúrese de utilizar el enlace del último mail recibido. En caso contrario solicítelo de nuevo.',
    IllegalArgumentError: 'El enlace ha expirado',
  },
};
