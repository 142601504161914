import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, I18nModule, provideDefaultConfig } from '@spartacus/core';
import { GenericLinkModule, IconModule, MediaModule, PageComponentModule } from '@spartacus/storefront';
import { CjMenuCollapsableComponent } from './menu-collapsable/menu-collapsable.component';
import { CjMenuLinksComponent } from './menu-links/menu-links.component';
import { CjMenuNonCollapsableComponent } from './menu-non-collapsable/menu-non-collapsable.component';
import { CjMenuComponent } from './menu.component';

@NgModule({
  imports: [CommonModule, RouterModule, GenericLinkModule, MediaModule, I18nModule, IconModule, PageComponentModule],
  providers: [
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        OsborneMenuComponent: {
          component: () => import('./menu.component').then((m) => m.CjMenuComponent),
        },
      },
    }),
  ],
  declarations: [CjMenuComponent, CjMenuLinksComponent, CjMenuNonCollapsableComponent, CjMenuCollapsableComponent],
  exports: [CjMenuComponent, CjMenuLinksComponent, CjMenuNonCollapsableComponent, CjMenuCollapsableComponent],
})
export class CjMenuModule {}
