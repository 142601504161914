import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CmsNavigationNode } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CjModalItemComponent } from '../icons/icons.model';
import { CjCmsCategoryHeaderBannerComponent } from './category-header-banner.model';

@Component({
  selector: 'cj-category-header-banner',
  templateUrl: './category-header-banner.component.html',
  styleUrls: ['./category-header-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjCategoryHeaderBannerComponent {
  icons$: Observable<CjModalItemComponent | undefined> = this.component.data$.pipe(map((data) => data['iconsComponent']));
  navigationNodes$: Observable<CmsNavigationNode | undefined> = this.component.data$.pipe(
    map((data) => data.navigationNode),
    filter((navigationNode) => !!navigationNode),
  );

  constructor(readonly component: CmsComponentData<CjCmsCategoryHeaderBannerComponent>) {}
}
