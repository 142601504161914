import { Injectable } from '@angular/core';
import { Converter, Occ, Product, SemanticPathService } from '@spartacus/core';
@Injectable({ providedIn: 'root' })
export class CjProductUrlNormalizer implements Converter<Occ.Product, Product> {
  constructor(private readonly urlService: SemanticPathService) {}

  convert(source: Occ.Product, target?: Product): Product {
    target = target ?? { ...(source as unknown as Partial<Product>) };

    // Create a worling product url if backend url is empty
    if (!source?.url || source?.url == '/') {
      target.url = this.urlService.transform({ cxRoute: 'product', params: target }).join('/');
    }

    return target as Product;
  }
}
