<ng-container *ngIf="navigationNodes">
  <div class="cj-category-navigation">
    <div *ngFor="let node of navigationNodes.children" class="cj-category-navigation__categories-node">
      <!-- If only has one link -->
      <ng-container *ngIf="!node.children?.length; else multipleEntries">
        <ng-container *ngIf="node.entries && node.entries[0] as link">
          <div *ngIf="link.itemId" [class.active]="isActiveCategory(link.itemId) | async">
            <ng-container
              [cxComponentWrapper]="{
                flexType: link.itemType,
                typeCode: link.itemType,
                uid: link.itemId
              }"
            ></ng-container>
          </div>
        </ng-container>
      </ng-container>
      <!-- If has multiple links -->
      <ng-template #multipleEntries>
        <div [class.active]="isChildActiveCategory(node) | async">
          <button (click)="toggleCategory(node)">{{ node.title | titlecase }}</button>
        </div>
        <div class="category-header-banner-category-node-items" *ngIf="node.children && activeCategory === node.uid">
          <ng-container *ngFor="let child of node.children">
            <ng-container *ngIf="child.entries && child.entries[0] as link">
              <div
                *ngIf="link.itemId"
                class="category-header-banner-category-node-items-child"
                [class.active]="isActiveCategory(link.itemId) | async"
                (click)="toggleCategory(node)"
              >
                <ng-container
                  [cxComponentWrapper]="{
                    flexType: link.itemType,
                    typeCode: link.itemType,
                    uid: link.itemId
                  }"
                ></ng-container>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>
