import { Injectable } from '@angular/core';
import { UserAddressService } from '@spartacus/core';
import { CjUserActions } from '../store/actions';

@Injectable({
  providedIn: 'root',
})
export class CjUserAddressService extends UserAddressService {
  /**
   * Sets user address as default
   * @param addressId a user address ID
   */
  override setAddressAsDefault(addressId: string): void {
    this.userIdService.takeUserId().subscribe((userId) => {
      this.store.dispatch(
        new CjUserActions.UpdateDefaultUserAddress({
          userId,
          addressId,
          address: { defaultAddress: true },
        }),
      );
    });
  }
}
