import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Address, ADDRESS_SERIALIZER, OccUserAddressAdapter } from '@spartacus/core';

@Injectable()
export class CjOccUserAddressAdapter extends OccUserAddressAdapter {
  override add(userId: string, address: Address): Observable<{}> {
    const url = this.occEndpoints.buildUrl('addressesBasestore', {
      urlParams: { userId },
    });
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    address = this.converter.convert(address, ADDRESS_SERIALIZER);

    return this.http.post(url, address, { headers }).pipe(catchError((error: any) => throwError(error)));
  }

  override update(userId: string, addressId: string, address: Address): Observable<{}> {
    const url = this.occEndpoints.buildUrl('addressDetailBasestore', {
      urlParams: { userId, addressId },
    });
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    address = this.converter.convert(address, ADDRESS_SERIALIZER);

    return this.http.put(url, address, { headers }).pipe(catchError((error: any) => throwError(error)));
  }

  updateDefault(userId: string, addressId: string, address: Address): Observable<{}> {
    const url = this.occEndpoints.buildUrl('addressDetail', {
      urlParams: { userId, addressId },
    });
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    address = this.converter.convert(address, ADDRESS_SERIALIZER);

    return this.http.patch(url, address, { headers }).pipe(catchError((error: any) => throwError(error)));
  }
}
