import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConsentTemplate, GlobalMessageService, GlobalMessageType, isNotUndefined } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable, Subject } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { CjCmsUtilsService } from 'src/app/shared/utils/cms-utils.service';
import { OsborneNewsletterComponent, OsborneNewsletterMediasComponent } from './newsletter.model';
import { CjNewsletterService } from './newsletter.service';

@Component({
  selector: 'cj-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss'],
})
export class CjNewsletterComponent {
  medias$: Observable<Observable<OsborneNewsletterMediasComponent>[]> = this.componentData.data$.pipe(
    map((data) => {
      if (!data.medias) {
        return [];
      }
      return this.cmsUtils.getComponentDataList<OsborneNewsletterMediasComponent>(data.medias);
    }),
  );

  newsletterForm: FormGroup = this.formBuilder.group({
    email: ['', { validators: [Validators.required, Validators.email], updateOn: 'blur' }],
    acceptTerms: [false, Validators.requiredTrue],
    templateId: [null, Validators.required],
    templateVersion: [null, Validators.required],
  });

  newsletterSuccess$: Subject<boolean> = new Subject();

  template$: Observable<ConsentTemplate | undefined> = this.componentData.data$.pipe(
    map((data) => {
      return data.consentTemplate;
    }),
    filter(isNotUndefined),
    tap((template) => {
      this.newsletterForm.patchValue({
        templateId: template.id,
        templateVersion: template.version,
      });
    }),
  );

  constructor(
    protected componentData: CmsComponentData<OsborneNewsletterComponent>,
    private formBuilder: FormBuilder,
    private newsletterService: CjNewsletterService,
    protected cmsUtils: CjCmsUtilsService,
    protected globalMessageService: GlobalMessageService,
  ) {}

  onSubmit(): void {
    if (this.newsletterForm.valid) {
      this.newsletterService
        .subscribeToNewsletter(
          this.newsletterForm.value.email,
          this.newsletterForm.value.templateId,
          this.newsletterForm.value.templateVersion,
          this.newsletterForm.value.acceptTerms,
        )
        .subscribe({
          error: () => this.globalMessageService.add({ key: 'newsletter.error' }, GlobalMessageType.MSG_TYPE_ERROR),
          complete: () => this.newsletterSuccess$.next(true),
        });
    } else {
      this.newsletterForm.markAllAsTouched();
    }
  }
}
