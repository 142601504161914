import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PageTitleComponent } from '@spartacus/storefront';

@Component({
  selector: 'cj-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjPageTitleComponent extends PageTitleComponent {}
