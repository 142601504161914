import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
} from '@angular/core';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

@Directive({
  selector: '[gsapAnimation]',
})
export class CjGsapAnimationDirective implements AfterViewInit, OnDestroy {
  @Input() animationType?: string;
  private mutationObserver: MutationObserver;
  private resizeObserver: ResizeObserver;

  constructor(
    private el: ElementRef,
    private ngZone: NgZone,
  ) {
    gsap.registerPlugin(ScrollTrigger);
    this.mutationObserver = new MutationObserver(() => {
      this.ngZone.runOutsideAngular(() => {
        this.applyAnimation();
        ScrollTrigger.refresh(true);
      });
    });

    this.resizeObserver = new ResizeObserver(() => {
      this.ngZone.runOutsideAngular(() => {
        ScrollTrigger.refresh(true);
      });
    });
  }

  ngAfterViewInit(): void {
    this.ngZone.runOutsideAngular(() => {
      this.mutationObserver.observe(this.el.nativeElement, {
        childList: true,
        subtree: true,
      });

      this.resizeObserver.observe(document.body);

      this.applyAnimation();
      ScrollTrigger.refresh(true);
    });
  }

  ngOnDestroy(): void {
    this.mutationObserver.disconnect();
    this.resizeObserver.disconnect();
    ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
  }

  private applyAnimation(): void {
    const element = this.el.nativeElement;
    if (!element) return;

    switch (this.animationType) {
      case 'parallaxVertical':
        gsap.fromTo(
          element.children[0],
          { yPercent: 0, scale: 1.4 },
          {
            yPercent: -20,
            ease: 'linear',
            scrollTrigger: {
              trigger: element,
              start: 'top bottom',
              end: 'bottom top',
              scrub: true,
            },
          },
        );
        break;

      case 'fadeInStaggered':
        gsap.fromTo(
          element.children,
          { opacity: 0 },
          {
            opacity: 1,
            duration: 0.75,
            ease: 'linear',
            stagger: (i) => 0.3 * i,
            scrollTrigger: {
              trigger: element,
              start: 'top 80%',
              once: true,
            },
          },
        );
        break;

      case 'fadeInSimple':
        gsap.fromTo(
          element,
          { opacity: 0 },
          {
            opacity: 1,
            duration: 0.8,
            ease: 'linear',
            scrollTrigger: {
              trigger: element,
              start: 'top 80%',
              once: true,
            },
          },
        );
        break;

      case 'scrollHorizontal':
        gsap.fromTo(
          element,
          { xPercent: -20 },
          {
            xPercent: 0,
            ease: 'linear',
            scrollTrigger: {
              trigger: element,
              start: 'top bottom',
              end: '80% top',
              scrub: true,
            },
          },
        );
        break;

      case 'zoomInOut':
        gsap.fromTo(
          element.children[0],
          { scale: 1 },
          {
            scale: 1.5,
            ease: 'linear',
            scrollTrigger: {
              trigger: element,
              start: 'top bottom',
              end: '80% top',
              scrub: true,
            },
          },
        );
        break;

      default:
        console.warn(
          'No animation type specified or animation type is not supported.',
        );
    }
  }
}
