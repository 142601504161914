import { Component, ElementRef, ViewChild, ViewContainerRef } from '@angular/core';
import { CmsOrderDetailOverviewComponent, TranslationService } from '@spartacus/core';
import { OrderDetailsService, OrderOverviewComponent } from '@spartacus/order/components';
import { Order } from '@spartacus/order/root';
import { CmsComponentData, LaunchDialogService } from '@spartacus/storefront';
import { Subscription, take } from 'rxjs';
import { ORDER_DETAIL_REORDER_DIALOG } from '../order-detail-actions/order-detail-reorder-dialog/order-detail-reorder-layout-config';

@Component({
  selector: 'cj-order-overview',
  templateUrl: './order-overview.component.html',
  styleUrl: './order-overview.component.scss',
})
export class CjOrderOverviewComponent extends OrderOverviewComponent {
  @ViewChild('element') reOrder: ElementRef | undefined;
  protected subscription = new Subscription();

  constructor(
    protected override translation: TranslationService,
    protected override orderDetailsService: OrderDetailsService,
    protected override component: CmsComponentData<CmsOrderDetailOverviewComponent>,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
  ) {
    super(translation, orderDetailsService, component);
  }

  onReorderClick(order: Order) {
    if (order?.code) {
      this.launchDialogService
        .openDialog(ORDER_DETAIL_REORDER_DIALOG, this.reOrder, this.vcr, { orderCode: order.code })
        ?.pipe(take(1))
        .subscribe();
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
