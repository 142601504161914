import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, provideDefaultConfig } from '@spartacus/core';
import { PageComponentModule } from '@spartacus/storefront';
import { CjMediaModule } from 'src/app/shared/components/media/media.module';
import { CjHtmlSanitizerPipeModule } from 'src/app/shared/pipes/html-sanitizer/html-sanitizer-pipe.module';
import { CjCategoryNavigationComponent } from './catagory-navigation/category-navigation.component';
import { CjCategoryHeaderBannerComponent } from './category-header-banner.component';

@NgModule({
  imports: [CommonModule, CjMediaModule, PageComponentModule, CjHtmlSanitizerPipeModule],
  providers: [
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        OsborneCategoryHeaderBanner: {
          component: () => import('./category-header-banner.component').then((m) => m.CjCategoryHeaderBannerComponent),
        },
      },
    }),
  ],
  declarations: [CjCategoryHeaderBannerComponent, CjCategoryNavigationComponent],
  exports: [CjCategoryHeaderBannerComponent, CjCategoryNavigationComponent],
})
export class CjCategoryHeaderBannerModule {}
