import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OccEndpointsService, PaymentDetails, UserIdService, WindowRef } from '@spartacus/core';
import { Observable, map, switchMap, take } from 'rxjs';
import { CjCartValidationService } from 'src/app/core/cart/facade/cart-validation.service';
import { CjOcc } from 'src/app/core/occ/occ-models/occ.models';
import { CjOccPaymentDetails } from './redsys-model';
import { CjRedsysActions } from './store/actions';
import { StateWithRedsys } from './store/redsys-state';

@Injectable({
  providedIn: 'root',
})
export class CjRedsysService {
  constructor(
    private readonly occEndpoints: OccEndpointsService,
    private readonly http: HttpClient,
    private readonly winRef: WindowRef,
    private readonly validationService: CjCartValidationService,
    private readonly userIdService: UserIdService,
    private readonly store: Store<StateWithRedsys>,
  ) {}

  loadCreditCards() {
    this.store.dispatch(new CjRedsysActions.LoadCards());
  }

  pay(userId: string, cartId: string, saveCard: boolean): void {
    if (this.winRef.isBrowser()) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });

      const url = this.occEndpoints.buildUrl('redsysPayment', {
        urlParams: { userId, cartId },
        queryParams: saveCard ? { saveCard } : {},
      });

      this.validationService
        .simulateCart()
        .pipe(
          take(1),
          switchMap(() => this.http.get<CjOcc.RedsysPayment>(url, { headers }).pipe(take(1))),
        )
        .subscribe((res) => {
          if (this.winRef.isBrowser()) {
            const form = this.winRef.document.createElement('form');
            form.style.display = 'none';
            form.method = 'POST';
            form.action = res.hopFormActionUrl;

            for (const [key, value] of Object.entries({
              Ds_MerchantParameters: res.paymentParameters,
              Ds_Signature: res.paymentSignature,
              Ds_SignatureVersion: res.signatureVersion,
            })) {
              const input = this.winRef.document.createElement('input');
              input.name = key;
              input.value = value;
              input.type = 'hidden';
              form.appendChild(input);
            }

            this.winRef.document.body.appendChild(form);
            form.submit();
            this.winRef.document.removeChild(form);
          }
        });
    }
  }

  getRedsysCards(): Observable<PaymentDetails[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.userIdService.getUserId().pipe(
      take(1),
      switchMap((userId) =>
        this.http
          .get<CjOccPaymentDetails>(
            this.occEndpoints.buildUrl('redsysCards', {
              urlParams: { userId },
            }),
            { headers },
          )
          .pipe(map((res) => res?.payments.filter((card) => card.saved))),
      ),
    );
  }

  deleteRedsysCard(paymentDetailsId: string) {
    return this.userIdService.getUserId().pipe(
      take(1),
      switchMap((userId) =>
        this.http.delete(
          this.occEndpoints.buildUrl('redsysCard', {
            urlParams: { userId, paymentDetailsId },
          }),
        ),
      ),
    );
  }
}
