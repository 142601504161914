import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { CheckoutStepService } from '@spartacus/checkout/base/components';
import { CheckoutStepType } from '@spartacus/checkout/base/root';
import { RoutingConfigService } from '@spartacus/core';
import { Observable, of } from 'rxjs';

/**
 * Checkout page is served every time a non existing page under /checkout/* is requested
 * This guard detects if that page is review-order and redirects to the last checkout step instead
 */
@Injectable({
  providedIn: 'root',
})
export class CjRedsysGuard {
  constructor(
    protected router: Router,
    protected routingConfigService: RoutingConfigService,
    protected checkoutStepService: CheckoutStepService,
  ) {}

  canActivate(activatedRoute: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return of(
      activatedRoute.url.map((url) => url.path).join('/') === 'checkout/review-order'
        ? this.router.parseUrl(
            this.routingConfigService.getRouteConfig(this.checkoutStepService.getCheckoutStep(CheckoutStepType.PAYMENT_DETAILS)!.routeName)
              ?.paths?.[0] as string,
          )
        : true,
    );
  }
}
