import { Component, Input } from '@angular/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CjCmsUtilsService } from 'src/app/shared/utils/cms-utils.service';
import { PRODUCT_HEALTHY_DIALOG } from '../product-healthy-dialog/product-healthy-dialog.config';
import {
  CjCmsProductHealthyItemCardComponent,
  CjCmsProductHealthyItemComponent,
  CjCmsProductHealthyItemModalComponent,
} from '../product-healthy-slider.model';

@Component({
  selector: 'cj-product-healthy-item',
  templateUrl: './product-healthy-item.component.html',
  styleUrls: ['./product-healthy-item.component.scss'],
})
export class CjProductHealthyItemComponent {
  @Input() set item(value: CjCmsProductHealthyItemComponent) {
    if (value) {
      this.item$.next(value);
    }
  }
  item$: BehaviorSubject<CjCmsProductHealthyItemComponent> = new BehaviorSubject<CjCmsProductHealthyItemComponent>({});
  cards$: Observable<Observable<CjCmsProductHealthyItemCardComponent>[]>;

  constructor(
    protected cmsUtils: CjCmsUtilsService,
    private readonly launchDialogService: LaunchDialogService,
  ) {
    this.cards$ = this.item$.pipe(
      map((data) => data.cards),
      filter((uids) => !!uids),
      map((uid) => {
        if (!uid) {
          return [];
        }
        return this.cmsUtils.getComponentDataList<CjCmsProductHealthyItemCardComponent>(uid);
      }),
    );
  }

  openDialog(data: CjCmsProductHealthyItemModalComponent | undefined): void {
    if (data) {
      this.launchDialogService.openDialogAndSubscribe(PRODUCT_HEALTHY_DIALOG, undefined, data);
    }
  }
}
