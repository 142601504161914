import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import {
  CurrencyService,
  GlobalMessageService,
  GlobalMessageType,
  InterceptorUtil,
  OccEndpointsService,
  RoutingService,
  ScriptLoader,
  ScriptPlacement,
  Translatable,
  USE_CLIENT_TOKEN,
  UserIdService,
  WindowRef,
} from '@spartacus/core';
import { OrderHistoryFacade } from '@spartacus/order/root';
import { EMPTY, catchError, lastValueFrom, map, switchMap, take, tap, withLatestFrom } from 'rxjs';
import { CjActiveCartService } from 'src/app/cms-components/cart/base/core/facade/active-cart.service';
import { CjMultiCartService } from 'src/app/cms-components/cart/base/core/facade/multi-cart.service';
import { CjCartValidationService } from 'src/app/core/cart/facade/cart-validation.service';
import { environment } from 'src/environments/environment';
import {
  PayPal,
  PayPalActions,
  PayPalApprove,
  PayPalCancel,
  PayPalCreateOrder,
  PayPalError,
  PayPalInit,
  PayPalOrderCaptureResponse,
  PayPalOrderCreateResponse,
} from './paypal.model';

declare let paypal: PayPal;

@Injectable({
  providedIn: 'root',
})
export class CjPaypalService {
  constructor(
    protected scriptLoader: ScriptLoader,
    protected currencyService: CurrencyService,
    protected userIdService: UserIdService,
    protected activeCartService: CjActiveCartService,
    protected occEndpoints: OccEndpointsService,
    protected ngZone: NgZone,
    protected orderHistoryFacade: OrderHistoryFacade,
    protected http: HttpClient,
    protected globalMessageService: GlobalMessageService,
    protected routingService: RoutingService,
    protected multiCartService: CjMultiCartService,
    private readonly winRef: WindowRef,
    private readonly validationService: CjCartValidationService,
  ) {}

  loadButtons(): void {
    this.hasPaypalScript() ? this.addButtons() : this.includePaypalSDK();
  }

  loadMessages(): void {
    this.hasPaypalScript() ? this.addMessages() : this.includePaypalSDK();
  }

  protected includePaypalSDK(): void {
    if (environment.paypal.src && environment.paypal.id) {
      this.currencyService
        .getActive()
        .pipe(take(1))
        .subscribe((currency) => {
          this.scriptLoader.embedScript({
            src: environment.paypal.src,
            params: {
              'client-id': environment.paypal.id,
              currency,
              components: 'messages,buttons',
            },
            callback: () => this.addAll(),
            errorCallback: () => this.handleLoadSDKError(),
            placement: ScriptPlacement.BODY,
          });
        });
    }
  }

  protected hasPaypalScript(): boolean {
    if (this.winRef.isBrowser()) {
      return !!this.winRef.document.querySelector(`script[src^="${environment.paypal.src}"]`);
    } else {
      return false;
    }
  }

  addAll() {
    this.addButtons();
    this.addMessages();
  }

  private addMessages(): void {}

  private addButtons(): void {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    this.userIdService
      .getUserId()
      .pipe(withLatestFrom(this.activeCartService.getActiveCartId()), take(1))
      .subscribe(([userId, cartId]) => {
        paypal
          .Buttons({
            fundingSource: paypal.FUNDING.PAYPAL,
            onInit: (res: PayPalInit, actions: PayPalActions) => actions.enable(),
            // This method is called AFTER the button is clicked and BEFORE the payment window is opened
            // It should create the order for paypal
            createOrder: async (res: PayPalCreateOrder, actions: PayPalActions) => {
              // We use lastValueFrom because retrun must be a promise
              return lastValueFrom(
                // Simulate cart
                this.validationService.simulateCart().pipe(
                  take(1),
                  switchMap(() => {
                    // Then call server to set up the transaction
                    const paypalOrderCreateUrl = this.occEndpoints.buildUrl('paypalOrderCreate', { urlParams: { userId, cartId } });
                    return this.http.post<PayPalOrderCreateResponse>(paypalOrderCreateUrl, { headers }).pipe(
                      take(1),
                      map((res: PayPalOrderCreateResponse) => res?.id),
                      catchError((error) => {
                        this.handleError(error?.error?.errors[0]?.message);
                        return EMPTY;
                      }),
                    );
                  }),
                ),
              );
            },
            // This method is called when the payment is successfull
            onApprove: async (res: PayPalApprove, actions: PayPalActions) => {
              // Call server to finalize the transaction
              const paypalOrderCaptureUrl = this.occEndpoints.buildUrl('paypalOrderCapture', { urlParams: { userId, cartId } });
              return lastValueFrom(
                this.http.post<PayPalOrderCaptureResponse>(paypalOrderCaptureUrl, { headers }).pipe(
                  take(1),
                  tap((orderData: PayPalOrderCaptureResponse) => {
                    if (!orderData) this.handleError();
                    // This example reads a v2/checkout/orders capture response, propagated from the server
                    // You could use a different API or structure for your 'orderData'
                    // Three cases to handle:
                    const errorDetail = Array.isArray(orderData.details) && orderData.details[0];
                    if (errorDetail) {
                      if (errorDetail.issue === 'INSTRUMENT_DECLINED') {
                        //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                        //   https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
                        return actions.restart!();
                      } else {
                        //   (2) Other non-recoverable errors -> Show a failure message
                        let msg = '';
                        if (errorDetail.description) msg = errorDetail.description;
                        if (orderData.debug_id) msg += ' (' + orderData.debug_id + ')';
                        return this.handleError({ key: 'paypal.error.detail', params: { msg } });
                      }
                    }
                    //   (3) Successful transaction -> Show confirmation or thank you
                    //   "links": [
                    //      {
                    //        "method": "GET",
                    //        "rel": "self",
                    //        "href": "https://api.sandbox.paypal.com/v2/checkout/orders/8PR8038441940191T"
                    //      },
                    //      {
                    //        "method": "GET",
                    //        "rel": "order",
                    //        "href": "e2619c6d-a2e5-493c-b7a7-a2553e5a0ebd"
                    //      }
                    //   ]
                    return this.onSuccess(cartId, orderData.links?.find((link: any) => link?.rel === 'order')?.href || '');
                  }),
                  catchError((error) => {
                    this.handleError(error?.error?.errors[0]?.message);
                    return EMPTY;
                  }),
                ),
              );
            },
            onCancel: async (res: PayPalCancel, actions: PayPalActions) => this.handleError({ key: 'paypal.error.cancel' }),
            onError: async (res: PayPalError) => {
              console.error('Paypal error', res);
              this.handleError({ key: 'paypal.error.default' });
            },
          })
          .render('#paypal-button-container');
      });
  }

  protected handleLoadSDKError(): void {
    // Remove script to be able to load it again later
    if (this.winRef.document && this.winRef.isBrowser()) {
      const script = this.winRef.document.querySelector('script[src^="' + environment.paypal.src + '"]');
      script?.parentElement?.removeChild(script);
      this.handleError();
    }
  }

  private handleError(error?: string | Translatable): void {
    this.globalMessageService?.add(error ?? { key: 'paypal.error.default' }, GlobalMessageType.MSG_TYPE_ERROR);
  }

  private onSuccess(cartId: string, code: string): void {
    this.orderHistoryFacade.loadOrderDetails(code);
    this.multiCartService.removeCart(cartId);
    this.ngZone.run(() => this.routingService.go('order-confirmation/' + code));
  }
}
