export const myAccount = {
  myAccountCommon: {
    hello: 'Hola, ',
  },
  updatePasswordForm: {
    passwordInfo: 'Contraseña',
    oldPassword: {
      label: 'Contraseña anterior',
      placeholder: 'Contraseña anterior',
    },
    oldPasswordIsRequired: 'Se requiere contraseña anterior.',
    newPassword: {
      label: 'Nueva contraseña',
      placeholder: 'Nueva contraseña',
    },
    passwordMinRequirements: 'La contraseña debe tener un mínimo de seis caracteres, con una letra mayúscula, un número y un símbolo.',
    confirmPassword: {
      label: 'Confirmar nueva contraseña',
      placeholder: 'Confirmar Contraseña',
    },
    bothPasswordMustMatch: 'Ambas contraseñas deben coincidir',
    passwordUpdateSuccess: 'Contraseña actualizada con éxito',
    accessDeniedError: 'Acceso denegado',
  },
  updateProfileForm: {
    title: 'Tratamiento',
    firstName: {
      label: 'Nombre y apellidos',
      placeholder: 'Nombre y apellidos',
    },
    firstNameIsRequired: 'Se requiere el nombre.',
    contactInfo: 'Contacto',
    addressInfo: 'Dirección de facturación',
    email: 'Correo electrónico',
    documentIdentifier: 'NIF',
    phone: 'Número de teléfono (para transportista)',
    address: 'Dirección',
    postalCode: 'Código Postal',
    town: 'Ciudad',
    country: {
      label: 'País',
      placeholder: 'País',
    },
    region: {
      label: 'Región',
      placeholder: 'Región',
    },
    profileUpdateSuccess: 'Datos personales actualizados con éxito',
    updateEmailText: 'Para modificaciones del email, por favor ponte en contacto con sac_web@osborne.es'
  },
};
