<ng-container *ngIf="paymentTypes$ | async as paymentTypes">
  <ng-container *ngIf="!!paymentTypes.length && (typeSelected$ | async) && (isUpdating$ | async) === false; else loading">
    <div role="status" [attr.aria-label]="'common.loaded' | cxTranslate"></div>
    <div class="row">
      <div class="col">
        <label class="cx-payment-type-container">
          <div class="form-check" *ngFor="let type of paymentTypes">
            <input
              id="paymentType-{{ type.code }}"
              class="form-check-input"
              role="radio"
              type="radio"
              aria-checked="true"
              (change)="type.code && changeType(type.code)"
              [value]="type.code"
              [checked]="type.code === typeSelected && !(selectedCard$ | async)?.id"
            />
            <label class="cx-payment-type-label form-check-label form-radio-label" for="paymentType-{{ type.code }}">
              <div class="cx-payment-type">
                {{ 'checkoutPayment.paymentType.' + type?.code | cxTranslate }}
              </div>
              <div [ngSwitch]="type?.code" class="cx-payment-type-description">
                <ng-container *ngSwitchCase="paymentType.CREDIT_CARD">
                  <ul class="list-unstyled d-flex my-0">
                    <li class="mr-3">
                      <img ngSrc="assets/images/payment/icon-payment-master.svg" alt="" height="23" width="35">
                    </li>
                    <li class="mr-3">
                      <img ngSrc="assets/images/payment/icon-payment-amex.svg" alt="" height="23" width="35">
                    </li>
                    <li>
                      <img ngSrc="assets/images/payment/icon-payment-visa.svg" alt="" height="23" width="35">
                    </li>
                  </ul>
                  <!-- TODO: Credit card imgs, add text to translations -->
                  <ng-container *ngIf="type.code === typeSelected">
                    <div class="save-card-checkbox" *ngIf="(selectedCard$ | async) === undefined">
                      <input type="checkbox" id="saveCard" [formControl]="saveCardFormControl" (change)="setSavePaymentCard()" />
                      <label for="saveCard"> {{ 'checkoutPayment.saveCard' | cxTranslate }} </label>
                    </div>
                    <ul class="list-unstyled card-list">
                      <li
                        *ngFor="let redsysCard of redsysCards$ | async"
                        class="card-item d-flex mt-3 align-items-center"
                        [class.selected]="redsysCard.id === (selectedCard$ | async)?.id"
                      >
                        <div
                          class="card-item__inner d-flex align-items-center"
                          [class.selected]="redsysCard.id === (selectedCard$ | async)?.id"
                          (click)="setRedsysCard(redsysCard)"
                        >
                          <input type="radio" [checked]="redsysCard.id === (selectedCard$ | async)?.id" />
                          <!-- TODO: Add card icon -->
                          <ul class="list-unstyled d-flex my-0 mr-2">
                            <li class="">
                              <img ngSrc="assets/images/payment/icon-payment-{{ redsysCard.cardType?.code }}.svg" alt="" height="23" width="35">
                            </li>
                          </ul>
                          <span class="card-info"> {{ getCardText(redsysCard) }} </span>
                        </div>
                        <button class="btn-remove-card" (click)="deleteRedsysCard(redsysCard)" *ngIf="redsysCard.id !== (selectedCard$ | async)?.id">
                          <span>{{ 'common.delete' | cxTranslate }}</span>
                          <i class="icomoon icon-trash"></i>
                        </button>
                      </li>
                    </ul>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="paymentType.BIZUM">
                  <!-- TODO: Bizum card icon -->
                  <ul class="list-unstyled d-flex my-0">
                    <li class="">
                      <img ngSrc="assets/images/payment/icon-payment-bizum.svg" alt="" height="23" width="35">
                    </li>
                  </ul>
                </ng-container>

                <ng-container *ngSwitchCase="paymentType.PAYPAL">
                  <!-- TODO: Paypal card icon -->
                  <ul class="list-unstyled d-flex my-0">
                    <li class="">
                      <img ngSrc="assets/images/payment/icon-payment-paypal.svg" alt="" height="23" width="35">
                    </li>
                  </ul>
                </ng-container>
              </div>
            </label>
          </div>
        </label>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #loading>
  <div class="cx-spinner" *ngIf="!paymentTypesError">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
