import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ConverterService, ProductService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { CjProduct } from 'src/app/shared/models/product.model';
import { CjProductCardDisplayType } from './product-card.model';
@Component({
  selector: 'cj-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjProductCardComponent {
  displayTypes = CjProductCardDisplayType;

  @Input() code?: string;
  @Input() product?: CjProduct;
  @Input() displayType: CjProductCardDisplayType | undefined = CjProductCardDisplayType.OPTION_ONE;
  @Input() artistic = true;
  @Input() hover = true;

  get product$(): Observable<CjProduct | undefined> | undefined {
    return this.code ? this.productService.get(this.code) : undefined;
  }

  constructor(
    private readonly productService: ProductService,
    protected converter: ConverterService,
  ) {}
}
