import { Component, OnDestroy } from '@angular/core';
import { CmsService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { CjPage } from 'src/app/core/cms/model/page.model';
import { CjProductGridService } from '../core/facade/product-grid.service';
import { CjCmsProductListBannerComponent } from './product-list-banner.model';

@Component({
  selector: 'cj-product-list-banner',
  template: '',
})
export class CjProductListBannerComponent implements OnDestroy {
  category$: Observable<string> = this.cmsService.getCurrentPage().pipe(
    map((page: CjPage) => page.plpCategoryCode || ''),
    filter((category) => !!category),
  );
  subscription = new Subscription();

  constructor(
    private readonly component: CmsComponentData<CjCmsProductListBannerComponent>,
    private readonly productGridService: CjProductGridService,
    private readonly cmsService: CmsService,
  ) {
    this.subscription.add(
      this.component.data$
        .pipe(
          switchMap((data) =>
            this.category$.pipe(
              map((category) => {
                if (data?.oneColumnBannerList) this.productGridService.setOneColumnBannerList(category, data.oneColumnBannerList);
                if (data?.threeColumnBannerList) this.productGridService.setThreeColumnBannerList(category, data.threeColumnBannerList);
              }),
            ),
          ),
        )
        .subscribe(),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
