import { RoutingConfig } from '@spartacus/core';

export const cjRoutesConfig: RoutingConfig = {
  routing: {
    routes: {
      shop: { paths: ['category/todos'] },
      addressBook: { paths: ['/my-account/address-book'] },
      product: { paths: ['product/:productCode/:name', 'product/:productCode'] },
      orderConfirmation: { paths: ['order-confirmation/:orderCode'], paramsMapping: { orderCode: 'code' } },
    },
  },
};
