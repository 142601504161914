<div class="cx-modal-container cj-product-video-dialog" (esc)="dismissModal('Escape pressed')">
  <div class="cx-modal-content" *ngIf="data$ | async as data">
    <!-- Modal Header -->
    <div class="cx-dialog-header modal-header">
      <button type="button" class="close" (click)="dismissModal('Cross click')">
        <span aria-hidden="true">
          <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
        </span>
      </button>
    </div>
    <!-- Modal Body -->
    <div class="cx-dialog-body modal-body">
      <cj-media *ngIf="data as media" [container]="cjCmsMediaService.getMedia(media)"></cj-media>
    </div>
  </div>
</div>
