import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GlobalMessageService, GlobalMessageType, LoggerService, UserAddressService, normalizeHttpError } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { CjUserAddressConnector } from '../../connectors/address/user-address.connector';
import { CjUserActions } from '../actions';

@Injectable()
export class CjUserAddressesEffects {
  protected logger = inject(LoggerService);

  updateDefaultUserAddress$: Observable<CjUserActions.CjUserAddressesAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(CjUserActions.UPDATE_DEFAULT_USER_ADDRESS),
      map((action: CjUserActions.UpdateDefaultUserAddress) => action.payload),
      mergeMap((payload) => {
        return this.userAddressConnector.getAllFromBasestore(payload.userId, payload.addressId, payload.address).pipe(
          map(() => {
            return new CjUserActions.UpdateDefaultUserAddressSuccess(payload);
          }),
          catchError((error) => {
            this.showGlobalMessage('addressForm.invalidAddress', GlobalMessageType.MSG_TYPE_ERROR);
            return of(new CjUserActions.UpdateDefaultUserAddressFail(normalizeHttpError(error, this.logger)));
          }),
        );
      }),
    ),
  );

  showGlobalMessageOnUpdateSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CjUserActions.UPDATE_DEFAULT_USER_ADDRESS_SUCCESS),
        map((action: CjUserActions.UpdateDefaultUserAddressSuccess) => action.payload),
        tap((payload) => {
          this.loadAddresses();
          // don't show the message if just setting address as default
          if (Object.keys(payload?.address).length !== 1 || !payload?.address?.defaultAddress) {
            this.showGlobalMessage('addressForm.userAddressUpdateSuccess');
          }
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private userAddressConnector: CjUserAddressConnector,
    private userAddressService: UserAddressService,
    private messageService: GlobalMessageService,
  ) {}

  /**
   * Show global confirmation message with provided text
   */
  private showGlobalMessage(key: string, type?: GlobalMessageType) {
    this.messageService.add({ key }, type ?? GlobalMessageType.MSG_TYPE_CONFIRMATION);
  }

  private loadAddresses() {
    this.userAddressService.loadAddresses();
  }
}
