<ng-container *ngIf="cart$ | async as cart">
  <div class="cj-delivery-address">
    <!-- Guest login for not logged user -->
    <cj-checkout-login #guestLoginForm [setEmail]="false" (submitEmail)="saveEmail($event)"></cj-checkout-login>

    <ng-container *ngIf="loggedIn$ | async; then loggedIn; else notLoggedIn"> </ng-container>

    <ng-template #notLoggedIn>
      <!-- Form address -->
      <cj-address-form
        #shippingAddressForm
        [formLayout]="addressFormLayouts.GUEST"
        [showActionBtns]="false"
        [addressData]="cart.deliveryAddress || {}"
        [loadAllCountries]="false"
        (submitAddress)="saveDeliveryAddress($event)"
      ></cj-address-form>
    </ng-template>

    <ng-template #loggedIn>
      <ng-container *ngIf="cards$ | async as cards; else loading">
        <ng-container *ngIf="cards?.length; then showExistingAddresses"> </ng-container>

        <!-- Address cards -->
        <ng-template #showExistingAddresses>
          <div class="cx-checkout-body row">
            <div class="cx-delivery-address-card col-12" *ngFor="let card of cards; let i = index" [class]="card.card.customClass">
              <div class="cx-delivery-address-card-inner" (click)="selectAddress(card.address)">
                <cx-card
                  [border]="true"
                  [index]="i"
                  [fitToContainer]="true"
                  [content]="card.card"
                  (sendCard)="selectAddress(card.address)"
                ></cx-card>
                <input type="radio" [checked]="card.card.customClass" />
              </div>
            </div>
            <div class="cx-checkout-btns col-12 mt-3">
              <!-- My addresses -->
              <a class="btn btn-link btn-view-address p-0 mt-2" [routerLink]="{ cxRoute: 'addressBook' } | cxUrl">{{
                'checkoutAddress.viewAddresses' | cxTranslate
              }}</a>
            </div>
          </div>
        </ng-template>
      </ng-container>
      <!-- Add new address -->
      <div class="cx-checkout-btns row">
        <div class="col-12">
          <button #newAddressButton (click)="showNewAddressForm()" class="btn btn-link btn-address">
            <span class="icomoon icon-plus"></span>
            <span class="btn-address__text">{{ 'checkoutAddress.addNewAddress' | cxTranslate }}</span>
          </button>
        </div>
      </div>
    </ng-template>

    <!-- Continue -->
    <div class="cx-checkout-btns row">
      <div class="col">
        <!-- <button class="cx-btn btn btn-block btn-primary" [disabled]="!(selectedAddress$ | async)?.id" (click)="next()"> -->
        <button class="cx-btn btn btn-block btn-primary" (click)="next()">
          {{ 'common.continue' | cxTranslate }}
        </button>
      </div>
    </div>

    <!-- Billing address form-->
    <ng-container *ngIf="{ user: user$ | async } as user">
      <ng-container *ngIf="!(user.user?.documentIdentifier && user.user?.defaultAddress)">
        <cj-billing-address #billingAddressForm (submitAddress)="saveBillingAddress($event)"></cj-billing-address>
      </ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-template #loading>
  <div class="cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
