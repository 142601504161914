import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CmsLinkComponent } from '@spartacus/core';
@Component({
  selector: 'cj-menu-links',
  templateUrl: './menu-links.component.html',
  styleUrls: ['./menu-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjMenuLinksComponent {
  @Input() data?: CmsLinkComponent | null;
}
