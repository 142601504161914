<ng-container *ngIf="thumbsOverride$ | async as thumbs">
  <ng-container *ngIf="product$ | async as product">
    <span *ngIf="product?.discountTag" class="discount-tag">
      {{ product?.discountTag }}
    </span>
  </ng-container>

  <swiper-container slides-per-view="1" navigation="true" pagination="true">
    <ng-container *ngIf="thumbs.length > 0; else main">
      <swiper-slide *ngFor="let item$ of thumbs">
        <div *ngIf="item$ | async as data">
          <cx-media
            [container]="data.container"
            gsapAnimation
            [animationType]="'fadeInSimple'"
          ></cx-media>
        </div>
      </swiper-slide>
    </ng-container>

    <ng-template #main>
      <swiper-slide *ngIf="mainImage$ | async as main">
        <cx-media
          [container]="main"
          gsapAnimation
          [animationType]="'fadeInSimple'"
        ></cx-media>
      </swiper-slide>
    </ng-template>
  </swiper-container>
</ng-container>
