import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CmsNavigationNode, CmsService } from '@spartacus/core';
import { Observable, combineLatest } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { CjPage } from 'src/app/core/cms/model/page.model';
import { CjCmsLinkComponent } from '../category-header-banner.model';

@Component({
  selector: 'cj-category-navigation',
  templateUrl: './category-navigation.component.html',
  styleUrls: ['./category-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjCategoryNavigationComponent {
  activeCategory?: string;

  @Input() navigationNodes?: CmsNavigationNode | null;

  constructor(private readonly cmsService: CmsService) {}

  toggleCategory(node: CmsNavigationNode | undefined): void {
    this.activeCategory = this.activeCategory != node?.uid ? node?.uid : undefined;
  }

  isActiveCategory(uid: string): Observable<boolean> {
    return this.cmsService
      .getComponentData<CjCmsLinkComponent>(uid)
      .pipe(
        switchMap((link) => this.cmsService.getCurrentPage().pipe(map((page: CjPage) => page?.plpCategoryCode === link?.categoryCode))),
      );
  }

  isChildActiveCategory(node: CmsNavigationNode): Observable<boolean> {
    return combineLatest(
      node.children
        ?.filter((child) => child.entries?.length && child.entries[0].itemId)
        .map((curr) => this.isActiveCategory(curr.entries![0].itemId!)) || [],
    ).pipe(
      take(1),
      map((active: boolean[]) => active.reduce((acc, curr) => acc || curr, false)),
    );
  }
}
