import { Injectable } from '@angular/core';
import { SchemaBuilder } from '@spartacus/storefront';
import { Observable, of } from 'rxjs';
import { CjStructuredDataUtilsService } from '../../structured-data-utils-service';

@Injectable({
  providedIn: 'root',
})
export class CjOrganizationSchemaBuilder implements SchemaBuilder {
  constructor(private readonly utils: CjStructuredDataUtilsService) {}

  build(): Observable<any> {
    // Common to all pages
    return of({
      '@type': 'Organization',
      name: this.utils.siteName,
      '@id': this.utils.hostUrl + '/#organization',
      url: this.utils.hostUrl + '/',
      logo: {
        '@type': 'ImageObject',
        // TODO: Find out which icon we should display
        url: this.utils.hostUrl + '/src/favicon.ico',
      },
      sameAs: this.utils.socialNetworkUrls,
    });
  }
}
