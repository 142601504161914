<cx-spinner class="overlay" *ngIf="isUpdating$ | async"> </cx-spinner>
<form
  *ngIf="token$ | async as token"
  (ngSubmit)="onSubmit(token)"
  [formGroup]="form"
>
  <h2>
    {{ 'register.resetPassword' | cxTranslate }}
  </h2>
  <label>
    <span class="label-content">{{
      'register.newPassword' | cxTranslate
    }}</span>
    <input
      class="form-control"
      type="password"
      placeholder="{{ 'register.password.placeholder' | cxTranslate }}"
      formControlName="password"
      cxPasswordVisibilitySwitch
    />
    <cx-form-errors [control]="form.get('password')"></cx-form-errors>
  </label>

  <label>
    <span class="label-content">{{
      'register.passwordMinRequirements' | cxTranslate
    }}</span>
    <input
      class="form-control"
      type="password"
      placeholder="{{ 'register.confirmPassword.placeholder' | cxTranslate }}"
      formControlName="passwordConfirm"
      cxPasswordVisibilitySwitch
    />
    <cx-form-errors [control]="form.get('passwordConfirm')"></cx-form-errors>
  </label>

  <button class="btn btn-block btn-primary" [disabled]="form.disabled">
    {{ 'register.resetPassword' | cxTranslate }}
  </button>
</form>
