<ng-container *ngIf="order$ | async as order">
  <div class="cx-nav row">
    
    <div class="col-xs-12 col-md-4">
      <cj-order-detail-ticket-download [order$]="order$"></cj-order-detail-ticket-download>
    </div>

    <div class="col-xs-12 col-md-4">
      <button *ngIf="order.cancellable" (click)="openModifyDialog()" class="btn btn-block btn-outline-primary">
        {{ 'orderDetails.actions.modify.modifyAction' | cxTranslate }}
      </button>
    </div>

    <div class="col-xs-12 col-md-4">
      <button *ngIf="order.cancellable" (click)="openCancelDialog()" class="btn btn-block btn-outline-primary">
        {{ 'orderDetails.actions.cancellationAndReturn.cancelAction' | cxTranslate }}
      </button>
    </div>
  </div>
</ng-container>
