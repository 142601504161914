import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { MediaModule, PageComponentModule } from '@spartacus/storefront';
import { CjMediaModule } from 'src/app/shared/components/media/media.module';
import { CjAnimationSliderComponent } from './animation-slider.component';

@NgModule({
  imports: [CommonModule, MediaModule, CjMediaModule, PageComponentModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        OsborneAnimationSliderComponent: {
          component: () => import('./animation-slider.component').then(() => CjAnimationSliderComponent),
        },
      },
    }),
  ],
  declarations: [CjAnimationSliderComponent],
  exports: [CjAnimationSliderComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CjAnimationSliderModule {}
