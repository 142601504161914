<ng-container *ngIf="component.data$ | async as data">
  <cj-media *ngIf="data.media?.media as media" class="animation-slider-background" [container]="getMedia(media)"></cj-media>

  <h1 *ngIf="data.logo?.media as media" class="animation-slider-logo">
    <cx-media [container]="getMedia(media)"></cx-media>
  </h1>

  <div class="animation-slider-container">
    <p *ngIf="data.text" class="animation-slider-container__text">{{ data.text }}</p>
    <ng-container
      *ngIf="data.link as link"
      [cxComponentWrapper]="{
        flexType: link.typeCode,
        typeCode: link.typeCode,
        uid: link.uid
      }"
    >
    </ng-container>
  </div>

  <swiper-container #swiperContainer *ngIf="slides$ | async as slides" init="false" navigation="true">
    <swiper-slide *ngFor="let slide$ of slides">
      <ng-container *ngIf="slide$ | async as slide">
        <cj-media *ngIf="slide.media?.media as media" class="slide-media" [container]="getMedia(media)"></cj-media>
        <div class="slide-caption">
          <div class="">
            <p *ngIf="slide.title" class="slide-title">{{ slide.title }}</p>
            <p *ngIf="slide.subtitle" class="slide-subtitle">{{ slide.subtitle }}</p>
          </div>

          <ng-container
            *ngIf="slide.link as link"
            [cxComponentWrapper]="{
              flexType: link.typeCode,
              typeCode: link.typeCode,
              uid: link.uid
            }"
          >
          </ng-container>
        </div>
      </ng-container>
    </swiper-slide>
  </swiper-container>
</ng-container>
