export const address = {
  addressForm: {
    alias: 'Alias',
    firstName: {
      label: 'Nombre',
      placeholder: 'Nombre',
    },
    lastName: {
      label: 'Apellidos',
      placeholder: 'Apellidos',
    },
    email: {
      label: 'Correo electrónico (notificación de envío)',
      placeholder: 'Correo electrónico',
    },
    phoneNumber: {
      label: 'Número de teléfono (notificación de envío)',
      placeholder: 'Número de teléfono',
    },
    address1: 'Calle y número',
    address2: 'Apto/Escalera (Opcional)',
    zipCode: {
      label: 'Código postal',
      placeholder: 'Código postal',
    },
    city: {
      label: 'Ciudad',
      placeholder: 'Ciudad',
    },
    nif: {
      label: 'NIF',
      placeholder: 'NIF',
    },
    country: {
      label: 'País',
      placeholder: 'País',
    },
    region: {
      label: 'Región',
      placeholder: 'Región',
    },
    saveAsDefault: 'Guardar como dirección por defecto',
    chooseAddress: 'Elegir dirección',
    streetAddress: 'Dirección',
    aptSuite: 'Apto, escalera',
    setAsDefault: 'Seleccionar como dirección por defecto',
    titleRequired: 'Título requerido.',
    requestBilling: '¿Solicitar factura completa?',
    sameAsDelivery: 'Igual que la dirección de envío',
    userAddressAddSuccess: 'La nueva dirección se agregó correctamente!',
    userAddressUpdateSuccess: 'Dirección actualizada exitosamente!',
    userAddressDeleteSuccess: 'Dirección eliminada exitosamente!',
    invalidAddress: 'Dirección inválida',
  },
  addressBook: {
    addNewDeliveryAddress: 'Agregar una nueva dirección de entrega',
    editDeliveryAddress: 'Editar dirección de entrega',
    areYouSureToDeleteAddress: '¿Estás seguro de que deseas eliminar esta dirección?',
    addNewAddress: 'Agregar nueva dirección',
    addAddress: 'Añadir dirección',
    updateAddress: 'Actualizar dirección',
    backToAddressList: 'Volver a la lista de direcciones',
    defaultDeliveryAddress: 'Dirección de entrega predeterminada',
    additionalDeliveryAddress: 'Dirección de entrega adicional {{number}}',
  },
  addressCard: {
    default: 'Dirección de entrega por defecto',
    selected: 'Seleccionado',
    setAsDefault: 'Establecer por defecto',
  },
};
