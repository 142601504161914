<ng-container *ngIf="order$ | async as order">
  <ng-container *ngIf="!(isOrderLoading$ | async)">
    <ng-container *ngIf="order.consignments?.length || order.unconsignedEntries?.length || order.replenishmentOrderCode">
      <cx-promotions [promotions]="order.appliedOrderPromotions || []"></cx-promotions>
    </ng-container>

    <ng-container *ngIf="order.entries">
      <cj-cart-item-list
        [items]="order.entries"
        [displayType]="cartItemDisplayTypes.ORDER_SUMMARY"
        [promotionLocation]="promotionLocation"
      ></cj-cart-item-list>
    </ng-container>
  </ng-container>

  <ng-template let-ctx #addToCartBtn>
    <cx-add-to-cart
      [productCode]="ctx.item.product?.code"
      [product]="ctx.item.product"
      [showQuantity]="false"
      [options]="ctx.options"
      [pickupStore]="ctx.item.deliveryPointOfService?.name"
      class="add-to-cart"
    >
    </cx-add-to-cart>
  </ng-template>
</ng-container>
