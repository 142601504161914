<div *ngIf="item$ | async as item" class="cj-product-healthy-slider" [class]="item.displayType">
  <p *ngIf="item.title" class="cj-product-healthy-slider__title">
    {{ item.title }}
  </p>
  <button class="cj-product-healthy-slider__btn" *ngIf="item.learnMoreModals" (click)="openDialog(item.learnMoreModals)">
    {{ 'common.knowMore' | cxTranslate | uppercase }}
  </button>
  <div *ngIf="cards$ | async as cards" class="cj-product-healthy-slider__info">
    <div *ngFor="let card$ of cards" class="cj-product-healthy-slider__item">
      <ng-container *ngIf="card$ | async as card">
        <p *ngIf="card.title" class="cj-product-healthy-slider__text tex-0">
          {{ card.title }}
        </p>
        <div class="">
          <span *ngIf="card.firstDescription" class="cj-product-healthy-slider__value">
            {{ card.firstDescription }}
          </span>
          <span *ngIf="card.secondDescription" class="cj-product-healthy-slider__type">
            {{ card.secondDescription }}
          </span>
          <span *ngIf="card.thirdDescription" class="cj-product-healthy-slider__list" [innerHTML]="card.thirdDescription"> </span>
        </div>
        <p *ngIf="card.description" class="cj-product-healthy-slider__data">
          {{ card.description }}
        </p>
      </ng-container>
    </div>
  </div>
</div>
