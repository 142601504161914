<div
  class="grid-item-wrapper"
  infiniteScroll
  [infiniteScrollDistance]="5"
  [infiniteScrollThrottle]="50"
  [infiniteScrollDisabled]="isMaxProducts || isLastPage || isEmpty"
  (scrolled)="scroll(model.pagination?.totalPages)"
>
  <ng-container *ngFor="let element of elements$ | async; index as n">
    <ng-container *ngIf="element">
      <cj-product-card
        *ngIf="isProduct(element); else banner"
        class="grid-item"
        [product]="asProduct(element)"
        [displayType]="displayTypes.OPTION_TWO"
        [artistic]="false"
        [hover]="false"
      ></cj-product-card>
    </ng-container>

    <ng-template #banner>
      <div *ngIf="asBanner(element) as bannerElement" class="banner" [class]="bannerElement.type">
        <ng-container *ngIf="bannerElement.banner">
          <ng-container
            *ngIf="getBanner(bannerElement.banner) | async as banner"
            [cxComponentWrapper]="{
              flexType: banner.typeCode,
              typeCode: banner.typeCode,
              uid: banner.uid,
            }"
          >
          </ng-container>
        </ng-container>
      </div>
    </ng-template>
  </ng-container>
</div>
