import { HttpClient } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CjNewsletterService {
  constructor(
    private http: HttpClient,
    protected occEndpoints: OccEndpointsService,
  ) {}

  subscribeToNewsletter(email: string, consentTemplateId: string, consentTemplateVersion: string, accept: boolean) {
    const url = this.occEndpoints.buildUrl('guests/consents');

    const params = {
      email: email,
      consentTemplateId: consentTemplateId,
      consentTemplateVersion: consentTemplateVersion,
      accept: accept,
    };

    return this.http.post(url, null, { params: params });
  }
}
