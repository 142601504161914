<div *ngIf="component.data$ | async as data" class="cj-product-tasting-banner">
  <div class="cj-product-tasting-banner__info">
    <h2
      *ngIf="data.title"
      class="cj-product-tasting-banner__title"
      gsapAnimation
      [animationType]="'fadeInSimple'"
    >
      {{ data.title }}
    </h2>
    <cj-product-characteristics
      *ngIf="data.characteristics"
      [characteristics]="data.characteristics"
    >
    </cj-product-characteristics>
  </div>
  <cx-media
    *ngIf="data.image"
    [container]="data.image"
    class="cj-product-tasting-banner__img cj-media--parallax"
    gsapAnimation
    [animationType]="'parallaxVertical'"
  ></cx-media>
</div>
