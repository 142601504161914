/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import { AddressBookComponentService } from '@spartacus/user/profile/components';
import { CjUserAddressService } from '../../user/facade/user-address.service';

@Injectable({
  providedIn: 'root',
})
export class CjAddressBookComponentService extends AddressBookComponentService {
  constructor(protected override userAddressService: CjUserAddressService) {
    super(userAddressService);
  }

  override setAddressAsDefault(addressId: string): void {
    this.userAddressService.setAddressAsDefault(addressId);
  }
}
