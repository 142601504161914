import { HttpClientModule } from '@angular/common/http';
import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppRoutingModule } from '@spartacus/storefront';
import { register as registerSwiper } from 'swiper/element/bundle';
import { AppComponent } from './app.component';
import { CjCustomModule } from './cms-components/custom.module';
import { CjCoreModule } from './core/core.module';
import { CjSiteContextModule } from './core/site-context/site-context.module';
import { ScriptsModule } from './scripts/scripts.module';
import { CjSeoModule } from './seo/seo.module';
import { CjGsapAnimationDirectiveModule } from './shared/directives/directives.module';
import { CjHeaderThemeColorDirectiveModule } from './shared/directives/header-theme-color.module';
import { CjBaseStoreInterceptorModule } from './shared/interceptors/base-store/base-store-interceptor.module';
import { CjSharedModule } from './shared/shared.module';
import { SpartacusModule } from './spartacus/spartacus.module';

// register Swiper custom elements
registerSwiper();

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    AppRoutingModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      logOnly: !isDevMode(),
      connectInZone: true,
    }),
    SpartacusModule,
    CjHeaderThemeColorDirectiveModule,
    CjSharedModule,
    CjCoreModule,
    CjCustomModule,
    ScriptsModule.forRoot(),
    CjBaseStoreInterceptorModule,
    CjSiteContextModule.forRoot(),
    CjSeoModule,
    CjCoreModule,
    CjCustomModule,
    CjSharedModule,
    CjHeaderThemeColorDirectiveModule,
    CjGsapAnimationDirectiveModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
