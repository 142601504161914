<div class="cx-order-summary cj-order-overview" *ngIf="order$ | async as order">
  <ng-container *ngIf="!(isOrderLoading$ | async)">
    <ng-container *ngIf="!(simple$ | async)">
      <ng-container *ngIf="order.replenishmentOrderCode; else otherOrder">
        <div class="divTable">
          <div class="divTableBody">
            <div class="divTableRow">
              <div class="divTableCell">
                <div class="cx-order-history-label">
                  {{ 'orderHistory.orderId' | cxTranslate }}
                </div>
                <span>{{ order?.replenishmentOrderCode }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="cx-summary-card">
          <cx-card [content]="getReplenishmentCodeCardContent(order?.replenishmentOrderCode) | async"></cx-card>

          <cx-card [content]="getReplenishmentActiveCardContent(order?.active) | async"></cx-card>
        </div>

        <div class="cx-summary-card">
          <cx-card [content]="getReplenishmentStartOnCardContent(order?.firstDate | cxDate) | async"></cx-card>

          <cx-card [content]="getReplenishmentFrequencyCardContent(order?.trigger?.displayTimeTable) | async"></cx-card>

          <cx-card [content]="getReplenishmentNextDateCardContent(order?.trigger?.activationTime | cxDate) | async"></cx-card>

          <!-- TODO:(CXINT-2309) for next major release remove feature level -->
          <ng-container *cxFeatureLevel="'6.4'">
            <ng-template [cxOutlet]="cartOutlets.ORDER_OVERVIEW" [cxOutletContext]="{ item: order, readonly: true }"> </ng-template>
          </ng-container>
        </div>
      </ng-container>

      <ng-template #otherOrder>
        <div class="divTable">
          <div class="divTableBody">
            <div class="divTableRow">
              <!-- Nº Pedido -->
              <div class="divTableCell">
                <div class="cx-order-history-label">
                  {{ 'orderHistory.orderId' | cxTranslate }}
                </div>
                <span class="cj-order-overview__value">{{ order?.code }}</span>
              </div>

              <!-- Enviado a  -->
              <div class="divTableCell">
                <div class="cx-order-history-label">
                  {{ 'orderHistory.sendTo' | cxTranslate }}
                </div>
                <span class="cj-order-overview__value">
                  {{ order?.deliveryAddress.firstName }}
                  {{ order?.deliveryAddress.lastName }}
                </span>
              </div>

              <!-- Fecha creación -->
              <div class="divTableCell">
                <div class="cx-order-history-label">
                  {{ 'orderHistory.date' | cxTranslate }}
                </div>
                <span class="cj-order-overview__value">{{ order?.created | cxDate }}</span>
              </div>

              <!-- Estado -->
              <div class="divTableCell">
                <div class="cx-order-history-label">
                  {{ 'orderHistory.status' | cxTranslate }}
                </div>
                <span class="cj-order-overview__value">{{ order?.statusDisplay }}</span>
              </div>

              <!-- Importe -->
              <div class="divTableCell">
                <div class="cx-order-history-label">
                  {{ 'orderHistory.total' | cxTranslate }}
                </div>
                <span class="cj-order-overview__value">{{ order?.totalPrice.formattedValue }}</span>
              </div>
              <!-- Reorder -->
              <div class="divTableCell">
                <div class="cx-order-history-label"></div>
                <button *ngIf="order?.status" class="btn-re-order btn btn-link btn-re-orde" #reOrder (click)="onReorderClick(order)">
                  <i class="icomoon icon-refresh"></i>
                  <span> {{ 'orderHistory.reorder' | cxTranslate }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
</div>
