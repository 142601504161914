import { AfterViewInit, ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import {
  CmsBannerComponent,
  CmsBannerComponentMedia,
  CmsResponsiveBannerComponentMedia,
  CmsService,
  Image,
  ImageGroup,
  PageType,
  SemanticPathService,
  WindowRef,
} from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';
import { CjArrayUtilsService } from 'src/app/shared/utils/array-utils.service';
import { CjCmsUtilsService } from 'src/app/shared/utils/cms-utils.service';
import { CjCmsInstagramSlider, CjInstagramSlide } from './instagram-slider.model';

@Component({
  selector: 'cj-instagram-slider',
  templateUrl: './instagram-slider.component.html',
  styleUrls: ['./instagram-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjInstagramSliderComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostListener('window:resize')
  setChunkSize() {
    if (this.windowRef.nativeWindow?.innerWidth) {
      const newChunkSize = this.windowRef.nativeWindow?.innerWidth > 750 ? 4 : 1;
      if (newChunkSize !== this.chunkSize$.value) {
        this.chunkSize$.next(0);
        this.reInitSwiper$.next(newChunkSize);
      }
    }
  }

  reInitSwiper$: Subject<number> = new Subject();
  chunkSize$: BehaviorSubject<number> = new BehaviorSubject(4);

  iconGroups$: Observable<Observable<CjInstagramSlide>[][]> = this.component.data$.pipe(
    map((data: CjCmsInstagramSlider) => data.icons),
    filter((icons) => !!icons),
    switchMap((icons) =>
      this.chunkSize$.pipe(
        map((size) =>
          icons
            ? this.arrayUtils.group<Observable<CjInstagramSlide>>(
                this.cmsUtils.getComponentDataList<CmsBannerComponent>(icons).map((icon) =>
                  icon.pipe(
                    map((icon: CmsBannerComponent) => ({
                      routerLink: this.getRouterLink(icon),
                      target: this.getTarget(icon),
                      media: this.getImage(icon.media),
                    })),
                  ),
                ),
                size,
              )
            : [],
        ),
      ),
    ),
  );

  constructor(
    readonly component: CmsComponentData<CjCmsInstagramSlider>,
    private readonly cmsUtils: CjCmsUtilsService,
    private readonly arrayUtils: CjArrayUtilsService,
    private readonly windowRef: WindowRef,
    private readonly urlService: SemanticPathService,
    private readonly cmsService: CmsService,
  ) {}

  ngOnInit(): void {
    this.reInitSwiper$.pipe(distinctUntilChanged(), debounceTime(0)).subscribe((val) => {
      this.chunkSize$.next(val);
    });
  }

  ngAfterViewInit(): void {
    this.setChunkSize();
  }

  protected getTarget(data: CmsBannerComponent): string | null {
    return data.external === 'true' || data.external === true ? '_blank' : null;
  }

  protected getRouterLink(data: CmsBannerComponent): unknown {
    let routerLink;
    if (data.urlLink) {
      routerLink = data.urlLink;
    } else if (data.contentPage) {
      this.cmsService
        .getPage({
          id: data.contentPage,
          type: PageType.CONTENT_PAGE,
        })
        .pipe(take(1))
        .subscribe((page) => {
          routerLink = page?.label;
        });
    } else if (data.product) {
      routerLink = this.urlService.transform({
        cxRoute: 'product',
        params: { code: data.product },
      });
    } else if (data.category) {
      routerLink = this.urlService.transform({
        cxRoute: 'category',
        params: { code: data.category },
      });
    }
    return routerLink;
  }

  protected getImage(image: CmsBannerComponentMedia | CmsResponsiveBannerComponentMedia | undefined): Image | ImageGroup | undefined {
    if (image) {
      if ('url' in image) {
        return image as Image;
      }
      return image as ImageGroup;
    }
    return undefined;
  }

  ngOnDestroy(): void {
    this.reInitSwiper$.complete();
  }
}
