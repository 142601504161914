import { Injectable } from '@angular/core';
import { GlobalMessageService, ScriptLoader, ScriptPlacement, WindowRef } from '@spartacus/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class BugherdService {
  constructor(
    protected scriptLoader: ScriptLoader,
    private readonly winRef: WindowRef,
    protected globalMessageService: GlobalMessageService,
  ) {}

  loadScript(): void {
    if (!this.hasScript() && environment.bugherd.enabled && environment.bugherd.src && environment.bugherd.apikey) {
      this.scriptLoader.embedScript({
        src: environment.bugherd.src,
        params: {
          apikey: environment.bugherd.apikey,
        },
        errorCallback: () => this.handleLoadError(),
        placement: ScriptPlacement.BODY
      });
    }
  }

  protected hasScript(): boolean {
    if (this.winRef.isBrowser()) {
      return !!this.winRef.document.querySelector(`script[src^="${environment.bugherd.src}"]`);
    }
    return false;
  }

  protected handleLoadError(): void {
    // Remove script to be able to load it again later
    if (this.winRef.isBrowser()) {
      const script = this.winRef.document.querySelector('script[src^="' + environment.bugherd.src + '"]');
      script?.parentElement?.removeChild(script);
      console.error('Error loading bugherd script');
    }
  }
}
