import { Component, OnInit } from '@angular/core';
import { AuthService, User } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, of, switchMap } from 'rxjs';

@Component({
  selector: 'cj-user-greeting',
  templateUrl: './user-greeting.component.html',
  styleUrl: './user-greeting.component.scss',
})
export class CjUserGreetingComponent implements OnInit {
  user$?: Observable<User | undefined>;

  constructor(
    private auth: AuthService,
    private userAccount: UserAccountFacade,
  ) {}

  ngOnInit(): void {
    this.user$ = this.auth.isUserLoggedIn().pipe(
      switchMap((isUserLoggedIn) => {
        if (isUserLoggedIn) {
          return this.userAccount.get();
        } else {
          return of(undefined);
        }
      }),
    );
  }
}
