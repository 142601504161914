<ng-container *ngIf="cmsComponentData.data$ | async as data">
  <ng-container *ngIf="items$ | async as items">
    <ng-container *ngIf="items.length">
      <h2 *ngIf="data.title" class="tit tit--h2 tit--center">{{ data.title }}</h2>
      <div class="d-none d-lg-block">
        <swiper-container
          slides-per-view="3"
          slides-per-group="3"
          space-between="24"
          navigation="true"
          observer="true"
          observe-parents="true"
        >
          <swiper-slide *ngFor="let product$ of items">
            <cj-product-card
              *ngIf="product$ | async as product"
              [code]="product.code"
              [displayType]="data.displayType"
              [artistic]="false"
              [hover]="false"
            ></cj-product-card>
          </swiper-slide>
        </swiper-container>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
