<div *ngIf="hasStock" class="cj-add-to-cart">
  <form *ngIf="productCode" [formGroup]="addToCartForm" (submit)="addToCart()">
    <div class="d-flex">
      <div
        class="cj-add-to-cart__quantity"
        *ngIf="
          showQuantity && webAvailability !== webAvailabilityType.NOT_AVAILABLE
        "
      >
        <cj-item-counter
          [max]="maxQuantity"
          [control]="quantityControl"
        ></cj-item-counter>
      </div>

      <ng-container>
        <ng-template
          [cxOutlet]="CartOutlets.ADD_TO_CART_PICKUP_OPTION"
          [(cxComponentRef)]="pickupOptionCompRef"
        ></ng-template>
      </ng-container>

      <button
        [ngClass]="
          options && options.displayAddToCart
            ? 'btn btn-tertiary'
            : 'btn btn-primary btn-block'
        "
        type="submit"
        [disabled]="
          quantity <= 0 ||
          quantity > maxQuantity ||
          webAvailability === webAvailabilityType.NOT_AVAILABLE
        "
      >
        <span
          *ngIf="
            options?.addToCartString === ('addToCart.buyItAgain' | cxTranslate)
          "
          class="repeat-icon"
          ><cx-icon [type]="iconTypes.REPEAT"></cx-icon
        ></span>
        <span
          attr.aria-label="{{
            (options && options.addToCartString) ??
              ('addToCart.addToCart' | cxTranslate)
          }}"
          [ngClass]="
            options &&
            options.addToCartString === ('addToCart.buyItAgain' | cxTranslate)
              ? 'buyItAgainLink'
              : ''
          "
        >
          {{
            (options && options.addToCartString) ??
              ('addToCart.addToCart' | cxTranslate)
          }}
        </span>
        <span *ngIf="!showQuantity"> - {{ price }} {{ currencySymbol }} </span>
      </button>
    </div>
  </form>
</div>
