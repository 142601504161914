import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable, fromEvent } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { BREAKPOINTS } from 'src/app/shared/constants/screen-breakpoints';
import { CjCmsUtilsService } from 'src/app/shared/utils/cms-utils.service';
import { CjCmsProductCharacteristicsComponent } from '../product-characteristics/product-characteristics.model';
import { CjCmsProductPartsBannerComponent } from './product-parts-banner.model';

@Component({
  selector: 'cj-product-parts-banner',
  templateUrl: './product-parts-banner.component.html',
  styleUrls: ['./product-parts-banner.component.scss'],
})
export class CjProductPartsBannerComponent implements AfterViewInit {
  screenWidth?: number;
  breakpoints = BREAKPOINTS;
  activeCharacteristic?: number;

  characteristics$: Observable<Observable<CjCmsProductCharacteristicsComponent>[]> = this.component.data$.pipe(
    map((data) => data.characteristics),
    filter((characteristics) => !!characteristics),
    map((characteristics) => {
      if (!characteristics) {
        return [];
      }
      return this.cmsUtils.getComponentDataList<CjCmsProductCharacteristicsComponent>(characteristics);
    }),
  );
  constructor(
    protected component: CmsComponentData<CjCmsProductPartsBannerComponent>,
    protected cmsUtils: CjCmsUtilsService,
    private cdr: ChangeDetectorRef,
    private readonly windowRef: WindowRef,
  ) {}

  ngAfterViewInit() {
    this.screenWidth = this.windowRef.nativeWindow?.innerWidth;

    if (this.windowRef.nativeWindow) {
      fromEvent(this.windowRef.nativeWindow, 'resize')
        .pipe(
          map(() => this.windowRef.nativeWindow?.innerWidth),
          startWith(this.windowRef.nativeWindow?.innerWidth),
        )
        .subscribe((size) => {
          this.screenWidth = size;
          this.cdr.detectChanges();
        });
    }
  }

  toggleCharacteristic(i: number | undefined) {
    this.activeCharacteristic = i === this.activeCharacteristic ? undefined : i;
  }
}
