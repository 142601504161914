import { NgModule } from '@angular/core';
import { CjCartModule } from './cart/cart.module';
import { CjCheckoutModule } from './checkout/checkout.module';
import { CjOccModule } from './occ/occ.module';
import { CjOrderModule } from './order/order.module';
import { CjUserCoreModule } from './user/user.module';

@NgModule({
  imports: [CjCartModule, CjCheckoutModule, CjOccModule, CjOrderModule, CjUserCoreModule],
})
export class CjCoreModule {}
