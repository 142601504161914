<div class="form-group">
  <span class="form-check-label">
    {{ 'checkoutPayment.confirmThatRead' | cxTranslate }}
    <a [routerLink]="{ cxRoute: 'termsAndConditions' } | cxUrl" class="cx-tc-link" target="_blank" rel="noopener noreferrer">
      {{ 'checkoutPayment.termsAndConditions' | cxTranslate }}
    </a>
  </span>
</div>

<div *ngIf="selectedPayment$ | async as selectedPayment" class="row">
  <div class="col-12 order-button">
    <ng-container *ngIf="selectedPayment.code === paymentType.PAYPAL; else redsys">
      <cj-paypal-button id="cj-paypal-button"></cj-paypal-button>
    </ng-container>

    <ng-template #redsys>
      <button class="btn btn-primary btn-block btn-action redsys-button" (click)="payWithRedsys()">
        {{ 'checkoutPayment.placeOrder' | cxTranslate }}
      </button>
    </ng-template>
  </div>
</div>
