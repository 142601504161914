<div *ngIf="componentData.data$ | async as data" (click)="openDialog(data.modal)" class="warranty-banner">
  <div class="warranty-banner__header">
    <cx-media *ngIf="data.iconWarranty" class="warranty-banner__icon" [container]="data.iconWarranty"></cx-media>
    <p *ngIf="data.title" class="warranty-banner__title">{{ data.title }} <i class="icomoon icon-info"></i></p>
    <i class="icomoon icon-chevron-down" [class.is-active]="isExpanded"></i>
  </div>
  <ng-container *ngIf="isExpanded">
    <div *ngIf="data.icons" class="warranty-banner__items">
      <div *ngFor="let icon$ of icons$ | async" class="warranty-banner__item">
        <ng-container *ngIf="icon$ | async as icon">
          <cx-media *ngIf="icon.media" [container]="icon.media"></cx-media>
          <span *ngIf="icon.title">{{ icon.title }}</span>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
