<div
  *ngIf="component.data$ | async as data"
  class="cj-category-banner"
  gsapAnimation
  [animationType]="'fadeInStaggered'"
>
  <cx-media
    class="cj-category-banner__left cj-media--parallax"
    [container]="data.mediaLeft"
    gsapAnimation
    [animationType]="'parallaxVertical'"
  ></cx-media>
  <cx-media
    class="cj-category-banner__right"
    [container]="data.mediaRight"
  ></cx-media>
</div>
