import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MiniCartComponent, MiniCartComponentService } from '@spartacus/cart/base/components/mini-cart';
import { LaunchDialogService } from '@spartacus/storefront';
import { ADDED_TO_CART_DIALOG } from '../cart/base/components/added-to-cart-dialog/added-to-cart-layout.config';

@Component({
  selector: 'cj-mini-cart',
  templateUrl: './mini-cart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjMiniCartComponent extends MiniCartComponent {
  constructor(
    protected override readonly miniCartComponentService: MiniCartComponentService,
    protected readonly dialogService: LaunchDialogService,
  ) {
    super(miniCartComponentService);
  }

  openCartModal(): void {
    this.dialogService.openDialogAndSubscribe(ADDED_TO_CART_DIALOG);
  }
}
