<ng-container *ngIf="component.data$ | async as data">
  <div *ngIf="data.mainLogo" class="menu-logo">
    <ng-container *ngIf="screenWidth && data.mainLogo && data.mobileLogo">
      <ng-container
        *ngIf="screenWidth >= breakpoints.SM"
        [cxComponentWrapper]="{
          flexType: data.mainLogo.typeCode,
          typeCode: data.mainLogo.typeCode,
          uid: data.mainLogo.uid
        }"
      ></ng-container>
      <ng-container
        *ngIf="screenWidth <= breakpoints.SM"
        [cxComponentWrapper]="{
          flexType: data.mobileLogo.typeCode,
          typeCode: data.mobileLogo.typeCode,
          uid: data.mobileLogo.uid
        }"
      ></ng-container>
    </ng-container>
  </div>
  <div class="menu-panel">
    <ng-container *ngIf="collapsables$ | async as collapsables">
      <ng-container *ngFor="let collapsable$ of collapsables">
        <cj-menu-collapsable
          *ngIf="collapsable$ | async as collapsable"
          [data]="collapsable"
          [isExpanded]="collapsable.uuid === openMenuItem"
          (openMenuItem)="changeOpenMenuItem($event)"
          class="animation--left"
        ></cj-menu-collapsable>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="nonCollapsables$ | async as nonCollapsables">
      <cj-menu-non-collapsable
        *ngFor="let link of nonCollapsables"
        [data]="link | async"
        class="d-block animation--left"
      ></cj-menu-non-collapsable>
    </ng-container>

    <div
      *ngIf="links$ | async as links"
      class="menu-panel__links animation--left"
    >
      <cj-menu-links
        *ngFor="let link of links"
        [data]="link | async"
      ></cj-menu-links>
    </div>
  </div>
</ng-container>
