<ng-container
  *ngIf="{
    orderHistory: orders$ | async,
    replenishmentOrder: hasReplenishmentOrder$ | async
  } as type"
>
  <ng-container *ngIf="type.orderHistory?.orders?.length; else noOrders">
    <div class="cj-order-history">
      <!-- HEADER -->
      <div [ngClass]="type.replenishmentOrder ? 'cj-replenishment-details-order-history-header' : 'cj-order-history-header'">
        <h4 *ngIf="type.replenishmentOrder">
          {{ 'orderHistory.replenishmentHistory' | cxTranslate }}
        </h4>
        <h2 *ngIf="!type.replenishmentOrder">
          {{ 'orderHistory.orderHistory' | cxTranslate }}
        </h2>
      </div>

      <!-- BODY -->
      <div *ngIf="type.orderHistory?.pagination" class="cx-order-history-body">
        <ng-container *ngIf="type.orderHistory?.pagination?.totalResults">
          <ng-container *ngIf="type?.orderHistory?.pagination?.totalResults; else noOrder">
            <!-- Pagination Top -->
            <div class="cx-order-history-sort top">
              <ng-container *ngIf="type?.orderHistory?.pagination?.totalPages">
                <div
                  *ngIf="type?.orderHistory?.pagination?.totalPages && type.orderHistory?.pagination?.totalPages! > 1"
                  class="cx-order-history-pagination"
                >
                  <cx-pagination [pagination]="type.orderHistory?.pagination" (viewPageEvent)="pageChange($event)"></cx-pagination>
                </div>
              </ng-container>
            </div>

            <div class="divTable cx-order-history-table" id="order-history-table">
              <div class="divTableBody">
                <div class="divTableRow" *ngFor="let order of type.orderHistory?.orders" [ngClass]="order.status">
                  <!-- Nº Pedido -->
                  <div class="divTableCell cj-order-history-code">
                    <div class="cj-order-history-label">
                      {{ 'orderHistory.orderId' | cxTranslate }}
                    </div>
                    <a
                      [routerLink]="
                        {
                          cxRoute: 'orderDetails',
                          params: order
                        } | cxUrl
                      "
                      class="btn btn-link btn-view-order"
                    >
                      {{ order?.code }}
                    </a>
                  </div>

                  <!-- Enviado a  -->
                  <div class="divTableCell cj-order-history-shipping">
                    <div class="cj-order-history-label">
                      {{ 'orderHistory.sendTo' | cxTranslate }}
                    </div>
                    <span class="cj-order-history__value">{{ order?.sendTo }}</span>
                  </div>

                  <!-- Fecha creación -->
                  <div class="divTableCell cj-order-history-placed">
                    <div class="cj-order-history-label">
                      {{ 'orderHistory.date' | cxTranslate }}
                    </div>
                    <span class="cj-order-history__value">{{ order?.placed | cxDate: 'longDate' }}</span>
                  </div>

                  <!-- Estado -->
                  <div class="divTableCell cj-order-history-status desktop-status">
                    <div class="cj-order-history-label">
                      {{ 'orderHistory.status' | cxTranslate }}
                    </div>
                    <span class="status-bag">{{ order?.statusDisplay }}</span>
                  </div>

                  <!-- Importe -->
                  <div class="divTableCell cj-order-history-total">
                    <div class="cj-order-history-label">
                      {{ 'orderHistory.total' | cxTranslate }}
                    </div>
                    <span class="cj-order-history__value">{{ order?.total?.formattedValue }}</span>
                  </div>

                  <!-- Estado -->
                  <div class="divTableCell cj-order-history-status mobile-status">
                    <div class="cj-order-history-label">
                      {{ 'orderHistory.status' | cxTranslate }}
                    </div>
                    <span class="status-bag">{{ order?.statusDisplay }}</span>
                  </div>

                  <!-- Reorder -->
                  <div class="divTableCell cj-order-history-reorder align-middle">
                    <button *ngIf="order?.status" class="btn btn-link btn-re-order" #reOrder (click)="onReorderClick(order)">
                      <i class="icomoon icon-refresh"></i>
                      <span>{{ 'orderHistory.reorder' | cxTranslate }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- Select Form and Pagination Bottom -->
            <div *ngIf="type.orderHistory?.pagination?.totalPages" class="cx-order-history-sort bottom">
              <div
                *ngIf="type.orderHistory?.pagination?.totalPages && type.orderHistory?.pagination?.totalPages! > 1"
                class="cx-order-history-pagination"
              >
                <cx-pagination [pagination]="type.orderHistory?.pagination" (viewPageEvent)="pageChange($event)"></cx-pagination>
              </div>
            </div>
          </ng-container>

          <!-- NO ORDER CONTAINER -->
          <ng-template #noOrder>
            <div
              *ngIf="isLoaded$ | async"
              [ngClass]="type.replenishmentOrder ? 'cx-replenishment-details-order-history-no-order' : 'cx-order-history-no-order'"
            >
              <div>
                <ng-container *ngIf="type.replenishmentOrder; else otherOrder">
                  <div>{{ 'orderHistory.notFound' | cxTranslate }}</div>
                </ng-container>

                <ng-template #otherOrder>
                  <div>{{ 'orderHistory.noOrders' | cxTranslate }}</div>
                  <a [routerLink]="{ cxRoute: 'home' } | cxUrl" routerLinkActive="active" class="btn btn-primary btn-block">{{
                    'orderHistory.startShopping' | cxTranslate
                  }}</a>
                </ng-template>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #noOrders>
  <!-- TODO: Add no orders image -->
  <p class="cj-order-history-no-order">
    <i class="icomoon icon-box"></i>
    <span class="qty">0</span>
    {{ 'orderHistory.noOrdersFound' | cxTranslate }}
  </p>
</ng-template>
