import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { MediaModule } from '@spartacus/storefront';
import { CjHtmlSanitizerPipeModule } from 'src/app/shared/pipes/html-sanitizer/html-sanitizer-pipe.module';
import { CjInstagramSliderComponent } from './instagram-slider.component';

@NgModule({
  imports: [CommonModule, MediaModule, CjHtmlSanitizerPipeModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        OsborneInstagramSliderComponent: {
          component: () => import('./instagram-slider.component').then(() => CjInstagramSliderComponent),
        },
      },
    }),
  ],
  declarations: [CjInstagramSliderComponent],
  exports: [CjInstagramSliderComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CjInstagramSliderModule {}
