import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { MediaModule, PageComponentModule } from '@spartacus/storefront';
import { CjGsapAnimationDirectiveModule } from 'src/app/shared/directives/directives.module';
import { CjProductBannerComponent } from './product-banner.component';

@NgModule({
  imports: [CommonModule, MediaModule, PageComponentModule, CjGsapAnimationDirectiveModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        OsborneProductBannerComponent: {
          component: () =>
            import('./product-banner.component').then(
              () => CjProductBannerComponent,
            ),
        },
      },
    }),
  ],
  declarations: [CjProductBannerComponent],
  exports: [CjProductBannerComponent],
})
export class CjProductBannerModule {}
