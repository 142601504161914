import { Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { CjOsborneProductTastingBannerComponent } from './product-tasting-banner.model';

@Component({
  selector: 'cj-product-tasting-banner',
  templateUrl: './product-tasting-banner.component.html',
  styleUrls: ['./product-tasting-banner.component.scss'],
})
export class CjProductTastingBannerComponent {
  constructor(protected component: CmsComponentData<CjOsborneProductTastingBannerComponent>) {}
}
