import { Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { CjCmsOsborneMyAccountHeaderBanner } from '../bottom-header-nav.model';
import { CmsService } from '@spartacus/core';
import { Observable, map, switchMap } from 'rxjs';
import { CjCmsLinkComponent } from 'src/app/cms-components/content/link/link.model';
import { CjPage } from 'src/app/core/cms/model/page.model';

@Component({
  selector: 'cj-nav-tabs',
  templateUrl: './nav-tabs.component.html',
  styleUrl: './nav-tabs.component.scss',
})
export class CjNavTabsComponent {
  constructor(
    protected componentData: CmsComponentData<CjCmsOsborneMyAccountHeaderBanner>,
    private readonly cmsService: CmsService,
  ) {}

  isActiveCategory(uid: string): Observable<boolean> {
    return this.cmsService
      .getComponentData<CjCmsLinkComponent>(uid)
      .pipe(switchMap((link) => this.cmsService.getCurrentPage().pipe(map((page: CjPage) => page?.label === link?.url))));
  }
}
