import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { CjCmsCategoryBanner } from './category-banner.model';

@Component({
  selector: 'cj-category-banner',
  templateUrl: './category-banner.component.html',
  styleUrls: ['./category-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjCategoryBannerComponent {
  constructor(protected component: CmsComponentData<CjCmsCategoryBanner>) {}
}
