<div class="entry row align-items-center" *ngIf="getItem() as item" [class]="displayType">
  <div
    class="entry-info"
    [ngClass]="displayType === cartItemDisplayTypes.MINI_CART ? 'col-12 mx-0 align-items-center align-items-sm-start' : 'col pr-md-4'"
  >
    <div class="row align-items-center cart-item-info">
      <!-- Item Image -->
      <div class="cj-image-container" [ngClass]="displayType === cartItemDisplayTypes.MINI_CART ? 'col-3' : 'col-3'">
        <a [routerLink]="item.product?.url" tabindex="0">
          <cx-media *ngIf="item.product?.images as images" [container]="images['PRIMARY']" format="cartIcon"></cx-media>
        </a>
      </div>
      <div class="" [ngClass]="displayType === cartItemDisplayTypes.MINI_CART ? 'col-9' : 'col'">
        <!-- Name -->
        <a *ngIf="item.product?.name" [routerLink]="item.product?.url" tabindex="0">
          <span class="entry-info-name"> {{ item.product?.name }}</span>
        </a>
        <!-- Availability -->
        <div *ngIf="item.product?.stock?.stockLevelStatus as stockLevelStatus" class="entry-info-availability" [class]="stockLevelStatus">
          {{ 'addToCart.stock.' + stockLevelStatus | cxTranslate }}
        </div>

        <div class="d-none d-md-flex align-items-center qty-and-price"  [ngClass]="displayType === cartItemDisplayTypes.MINI_CART ? '' : ''">
          <!-- Item Quantity -->
          <div *ngIf="displayType !== cartItemDisplayTypes.CART" class="entry-info-miniCart-qty">
            <span *ngIf="displayType === cartItemDisplayTypes.ORDER_SUMMARY">{{ 'addToCart.quantity' | cxTranslate }}</span>
            {{ quantityControl.value }}
          </div>
          <!-- Price -->
          <div class="entry-info-price" *ngIf="displayType !== cartItemDisplayTypes.ORDER_SUMMARY">
            <ng-container *ngTemplateOutlet="priceTemplate"></ng-container>
          </div>
        </div>
      </div>

      <!-- Promotions Mini Cart -->
      <span *ngIf="item.promotions" class="entry-info-promotion col-12" [ngClass]="displayType === cartItemDisplayTypes.MINI_CART ? '' : 'd-none'">
        <!-- TODO: Review once promotions are configured in backend -->
        <cx-promotions [promotions]="item.promotions"></cx-promotions>
      </span>

      <!-- Item Quantity -->
      <div *ngIf="displayType === cartItemDisplayTypes.CART" class="entry-info-qty col-3 d-none d-md-block">
        <cx-item-counter [control]="quantityControl" [readonly]="!!(!item.updateable || readonly)" [allowZero]="true"> </cx-item-counter>
      </div>

      <!-- Promotions Cart Page Mobile -->
      <span *ngIf="item.promotions" class="entry-info-promotion col-12" [ngClass]="displayType === cartItemDisplayTypes.MINI_CART ? 'd-none' : 'd-block d-md-none'">
        <!-- TODO: Review once promotions are configured in backend -->
        <cx-promotions [promotions]="item.promotions"></cx-promotions>
      </span>

      <div class="d-flex d-md-none align-items-center col-12 px-3 qty-and-price">
        <!-- Item Quantity -->
        <div class="entry-info-miniCart-qty">
          <span *ngIf="displayType === cartItemDisplayTypes.ORDER_SUMMARY">{{ 'addToCart.quantity' | cxTranslate }}</span>
          {{ quantityControl.value }}
        </div>
        <!-- Price -->
        <div class="entry-info-price ml-auto" *ngIf="displayType !== cartItemDisplayTypes.ORDER_SUMMARY">
          <ng-container *ngTemplateOutlet="priceTemplate"></ng-container>
        </div>

        <!-- Remove button -->
        <div *ngIf="displayType === cartItemDisplayTypes.CART" class="ml-3 pl-3 border-left remove-btn">
          <button
            (click)="removeItem()"
            [cxAtMessage]="'cartItems.itemRemoved' | cxTranslate"
            [disabled]="quantityControl.disabled"
            attr.aria-label="{{ 'addToCart.removeFromCart' | cxTranslate }}"
            class="btn btn-remove-item"
          >
            <i class="icomoon icon-trash"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Remove button -->
  <div *ngIf="displayType === cartItemDisplayTypes.CART" class="col-auto remove-btn d-none d-md-block">
    <button
      (click)="removeItem()"
      [cxAtMessage]="'cartItems.itemRemoved' | cxTranslate"
      [disabled]="quantityControl.disabled"
      attr.aria-label="{{ 'addToCart.removeFromCart' | cxTranslate }}"
      class="btn btn-remove-item"
    >
      <i class="icomoon icon-trash"></i>
      <span>{{ 'common.remove' | cxTranslate }}</span>
    </button>
  </div>

  <!-- Price -->
  <div class="entry-info-price col-auto px-0" *ngIf="displayType === cartItemDisplayTypes.ORDER_SUMMARY">
    <ng-container *ngTemplateOutlet="priceTemplate"></ng-container>
  </div>

  <ng-template #priceTemplate>
    <div *ngIf="item.basePrice" class="cx-price">
      {{ item.totalPrice?.formattedValue }}
    </div>
    <p *ngIf="item.basePriceWithoutDiscounts?.formattedValue" class="product-card-price">
      <span class="product-card-price__discounted"> {{ item.basePriceWithoutDiscounts.formattedValue }} </span>
      <!-- <span *ngIf="item.product?.totalPriceWithNoDiscount?.formattedValue" class="product-card-price__original">
        {{ item.product?.totalPriceWithNoDiscount.formattedValue }}</span
        > -->
    </p>
  </ng-template>

  <!-- Promotions Cart Page Desktop -->
  <div *ngIf="item.promotions" class="entry-info-promotion col-12" [ngClass]="displayType === cartItemDisplayTypes.MINI_CART ? 'd-none' : 'd-none d-md-block'">
    <!-- TODO: Review once promotions are configured in backend -->
    <cx-promotions [promotions]="item.promotions"></cx-promotions>
  </div>
</div>
