import { Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { OsborneFooterIconComponent, OsborneFooterLearnComponent } from '../footer-navigation.model';
import { CjCmsUtilsService } from 'src/app/shared/utils/cms-utils.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'cj-footer-learn',
  templateUrl: './footer-learn.component.html',
  styleUrls: ['./footer-learn.component.scss'],
})
export class CjFooterLearnComponent {
  showNewsletter$ = new BehaviorSubject<boolean>(false);

  constructor(
    protected cmsUtils: CjCmsUtilsService,
    protected componentData: CmsComponentData<OsborneFooterLearnComponent>,
  ) {}

  icons$: Observable<Observable<OsborneFooterIconComponent>[]> = this.componentData.data$.pipe(
    map((data) => {
      if (!data.icons) {
        return [];
      }
      return this.cmsUtils.getComponentDataList<OsborneFooterIconComponent>(data.icons);
    }),
  );

  openNewsletter() {
    this.showNewsletter$.next(!this.showNewsletter$.value);
  }
}
