import { Component } from '@angular/core';
import { ProductReferenceService } from '@spartacus/core';
import { CmsComponentData, CurrentProductService, ProductReferencesComponent } from '@spartacus/storefront';
import { CjCmsProductCarouselComponent } from './product-references-slider.model';

@Component({
  selector: 'cj-product-references-slider',
  templateUrl: './product-references-slider.component.html',
  styleUrls: ['./product-references-slider.component.scss'],
})
export class CjProductReferencesSliderComponent extends ProductReferencesComponent {
  constructor(
    protected override cmsComponentData: CmsComponentData<CjCmsProductCarouselComponent>,
    protected override currentProductService: CurrentProductService,
    protected override productReferenceService: ProductReferenceService,
  ) {
    super(cmsComponentData, currentProductService, productReferenceService);
  }
}
