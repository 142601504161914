export const common = {
  common: {
    send: 'Send',
    discover: 'Discover',
    knowMore: 'To know more',
    remove: 'Remove',
    delete: 'Delete',
    edit: 'Edit',
    save: 'Save',
    continue: 'Continue',
    free: 'Free',
    logout: 'Logout',
    cancel: 'Cancel',
    submit: 'Send',
    back: 'Return',
    update: 'Update',
  },
  httpHandlers: {
    badRequest: {
      bad_credentials: 'Incorrect credentials. Please log in again.',
      el_usuario_está_deshabilitado: 'User disabled. Please log in with another email.',
      user_is_disabled: 'User disabled. Please log in with another email.',
      le_compte_utilisateur_est_désactivé: 'User disabled. Please log in with another email.',
      usuário_desabilitado: 'User disabled. Please log in with another email.',
    },
    badGateway: 'A server error occurred. Please try again later.',
    badRequestPleaseLoginAgain: 'Incorrect credentials. Please log in again.',
    badRequestOldPasswordIncorrect: 'Old password incorrect.',
    badRequestGuestDuplicateEmail: 'The email address already exists. Please log in or enter a different email address.',
    conflict: 'Already exists.',
    forbidden: 'You are not authorized to perform this action. Please contact your administrator if you believe this is an error.',
    gatewayTimeout: 'The server is not responding, please try again later.',
    internalServerError: 'An internal server error occurred. Please try again later.',
    sessionExpired: 'Your session has expired. Please log in again.',
    unknownError: 'An unknown error occurred',
    unauthorized: {
      common: 'An unknown authorization error occurred',
      invalid_client: 'Incorrect credentials',
    },
    validationErrors: {
      missing: {
        bankAccountNumber: 'The IBAN field is required',
        card_cardType: 'The selected credit card is not compatible. Please select another one.',
        card_accountNumber: 'The entered credit card number is not valid.',
        card_cvNumber: 'The entered security code is not valid.',
        card_expirationMonth: 'The entered credit card expiration date is not valid.',
        card_expirationYear: 'The entered credit card expiration date is not valid.',
        billTo_firstName: 'The entered first name is not valid.',
        billTo_lastName: 'The entered last name is not valid.',
        billTo_street1: 'The entered address is not valid.',
        billTo_street2: 'The entered address is not valid.',
        billTo_city: 'The entered city is not valid for this credit card.',
        billTo_state: 'The entered state/province is not valid for this credit card.',
        billTo_country: 'The entered country is not valid for this credit card.',
        billTo_postalCode: 'The entered postal code is not valid for this credit card.',
        country: {
          isocode: 'Missing country',
        },
        firstName: 'The first name is required',
        lastName: 'The last name is required',
        password: 'The password is required',
        firstNameLastName: 'The first name and last name are required',
        uid: 'The email is required',
        code: 'The code is required',
        email: 'The email is required',
        iban: 'The IBAN field is required',
        documentIdentifier: 'The NIF field is required',
        postalCode: 'The postal code field is required',
        defaultPaymentAddress: {
          phone: 'The postal code field is required',
          firstName: 'The first name field is required',
          lastName: 'The last name field is required',
          password: 'The password field is required',
          line1: 'The address field is required',
          town: 'The city field is required',
          postalCode: 'The postal code field is required',
          country: {
            isocode: 'The country field is required',
          },
          toCustomersUid: 'Missing customers email address',
        },
        newUid: 'Please verify the user information',
        message: 'The message is required',
        subject: 'The subject is required',
        orderCode: 'The order number is required',
        productsAffected: 'The Products and Quantities affected field is required',
        qualityProblem: 'The Quality Problem field is required',
        requiredAction: 'The Required Action field is required',
      },
      invalid: {
        subject: 'The entered subject is not valid.',
        message: 'The entered message is not valid.',
        bankAccountNumber: 'The IBAN field is not valid',
        card_expirationMonth: 'The entered credit card expiration date is not valid.',
        firstName: 'The entered first name is not valid.',
        lastName: 'The entered last name is not valid.',
        firstNameLastName: 'The entered first name and last name are not valid',
        password:
          'The entered password is not valid, it must be at least six characters long, with an uppercase letter, a number, a symbol',
        uid: 'The email is not valid.',
        code: 'The code is not valid.',
        email: 'The email is not valid.',
        iban: 'The IBAN field is not valid',
        sessionCart: 'Payment information is not set.',
        documentIdentifier: 'The NIF is not valid.',
        postalCode: 'The postal code is not valid',
        defaultPaymentAddress: {
          phone: 'The postal code field is not valid',
          firstName: 'The first name field is not valid',
          lastName: 'The last name field is not valid',
          password: 'The password field is not valid',
          line1: 'The address field is not valid',
          town: 'The city field is not valid',
          postalCode: 'The code field is not valid',
          country: {
            isocode: 'The country field is not valid',
          },
        },
      },
    },
    cartModification: {
      giftBoxError: {
        label: 'The minimum number of products to complete the gift box has not been reached.',
        entries: '',
      },
      lowStock: {
        label: 'The following items in your cart have been modified: ',
        entries: 'The quantity of the product {{product}} has been reduced from {{oldQuantity}} to {{quantity}} due to insufficient stock.',
      },
      noStock: {
        label: 'The following items in your cart have been modified: ',
        entries: 'The product {{product}} has been removed due to insufficient stock.',
      },
      unavailable: {
        label: 'The following items that are not available for this destination have been removed: ',
        entries: '{{product}}.',
      },
    },
    cartNotFound: 'Cart not found.',
    invalidCodeProvided: 'Invalid code.',
    notActiveCodeProvided: 'Currently invalid code.',
    voucherExceeded: '{{voucherCode}}',
    cartSimulationError: 'Error simulating the order',
  },
  contextSelector: {
    title: 'Select country and language',
    country: 'Country',
    language: 'Language',
  },
  newsletter: {
    emailPlaceholder: 'Your e-mail',
    acceptThe: 'I accept the ',
    termsAndConditions: 'Privacy Policy',
    success: 'Thank you very much for being part of Cinco Jotas',
    error: 'An error occurred while subscribing to the newsletter',
  },
  formErrors: {
    cjEmailMustNotExist: 'This email is already registered in Cinco Jotas.',
    nifError: 'Name and Surname do not match your NIF.',
    globalMessage: 'The form you are trying to submit contains errors.',
    required: 'This field is required',
    cxInvalidEmail: 'The email format is incorrect',
    cxInvalidPassword:
      'The password must have a minimum of six characters, one uppercase letter, one lowercase letter, one number and one symbol.',
    cxPasswordsMustMatch: 'Password fields must match',
    cxEmailsMustMatch: 'Email fields must match',
    cxStarRatingEmpty: 'Rating field is required',
    cxNoSelectedItemToCancel: 'Select at least one item',
    cxNegativeAmount: 'Amount must be equal to or greater than zero',
    cxContainsSpecialCharacters: 'Field cannot contain special characters',
    cxIban: 'IBAN field is incorrect',
    cxNif: 'The TIN field is incorrect for the selected country',
    cxCif: 'The TIN field is incorrect for the selected country. You can only enter a VAT number for a natural person.',
    cxPostalCode: 'The ZIP code is incorrect for the selected country - zone.',
    email: 'Invalid email',
    maxlength: 'You have entered more characters than allowed',
    maxlengthWithNumber: 'This field must contain no more than {{{maxLength}} characters',
    combinedMaxlength:
      '{{ firstControl }} and {{ secondControl }} combined must be at most {{maxLength}} characters (currently {{actualLength}})',
    date: {
      required: 'This field is mandatory',
      min: 'The date cannot be earlier than {{min}}',
      max: 'The date cannot be later than {{max}}.',
      pattern: 'Use the date format yyyy-mm-dd',
      invalid: 'Use a valid date',
    },
    minlength: 'This field must contain at least {{{minLength}} characters',
    min: 'Does not reach the minimum',
  },
  contactForm: {
    labels: {
      email: 'Email',
      message: 'Comments',
      subject: 'Subject',
      orderCode: 'Order number',
      accept: 'I accept the processing of my personal details for the activities outlined below.',
      requiredAction: 'Required Action',
      productsAffected: {
        label: 'Products and quantities affected',
        productDescription: 'Product description',
        quantity: 'Quantity',
        add: 'PRODUCT',
        remove: 'Remove product',
      },
      qualityProblem: 'Quality Problem',
      qualityProblems: {
        excessFat: 'Excess fat',
        smellFlavorColorProblems: 'Smell/flavor/color problems',
        vacuumLoss: 'Vacuum loss',
        excessLackCuring: 'Excess/lack of curing',
        other: 'Other reasons',
      },
    },
    placeholders: {
      ticketCauses: 'Select a reason',
      ticketTemplates: 'Select a cause',
      qualityProblems: 'Select a problem',
    },
    formTemplateTitle: 'Contact form for {{formTitle}}',
    submit: 'Send a request',
    updateSuccess: 'Request sent successfully',
    updateError: 'There has been an error trying to send your request. Please try again later.',
    modelNotFoundError: 'Order number does not relate to this email. Please correct it and try again.',
  },
};
