<ng-container *ngIf="(loggedIn$ | async) === false">
  <form (ngSubmit)="onSubmit()" [formGroup]="checkoutLoginForm">
    <h3 class="d-flex cj-delivery-address__title">
      <span class="">{{
        'checkoutLogin.emailAddress.label' | cxTranslate
      }}</span>
      <a
        class="cj-delivery-address__login"
        [routerLink]="{ cxRoute: 'login' } | cxUrl"
        >{{ 'checkoutLogin.signIn' | cxTranslate }}</a
      >
    </h3>

    <div class="form-group">
      <label>
        <input
          required="true"
          type="email"
          name="email"
          class="form-control"
          formControlName="email"
          placeholder="{{
            'checkoutLogin.emailAddress.placeholder' | cxTranslate
          }}"
          [readOnly]="isGuest$ | async"
        />
        <cx-form-errors
          [control]="checkoutLoginForm.get('email')"
        ></cx-form-errors>
      </label>
    </div>
  </form>
</ng-container>
