import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { CjCmsBanner } from './banner.model';

@Component({
  selector: 'cj-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjBannerComponent {
  constructor(protected component: CmsComponentData<CjCmsBanner>) {}
}
