import { ChangeDetectionStrategy, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { TranslationService, WindowRef } from '@spartacus/core';
import { CmsComponentData, ICON_TYPE } from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  CjCmsContactFormComponent,
  CsTicketCause,
  CsTicketConfiguration,
  CsTicketContactForm,
  CsTicketQualityProblem,
  CsTicketTemplate,
} from './contact-form.model';
import { ContactFormService } from './contact-form.service';

@Component({
  selector: 'cj-custom-contact-form',
  templateUrl: './contact-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjContactFormComponent implements OnDestroy, OnInit {
  @HostBinding('class') styleClasses: string | undefined;
  private ORDER_CODE_PREFIX = 'Storefront=';
  protected subscription = new Subscription();
  protected busy$ = new BehaviorSubject(false);
  isUpdating$ = this.busy$;
  selectedTicketCause: CsTicketCause | undefined;
  selectedTicketTemplate: CsTicketTemplate | undefined;
  requiredOrderCode = false;
  isReturnForQuality = false;
  contactForm: FormGroup = this.customContactFormService.form;
  qualityProblems: { code: CsTicketQualityProblem; name: string }[] | undefined;
  iconTypes = ICON_TYPE;

  ticketCauses$: Observable<CsTicketCause[]> | undefined;
  data$: Observable<CjCmsContactFormComponent> = this.component.data$.pipe(
    tap((data) => {
      this.styleClasses = data?.styleClasses ?? '';
      if (data) {
        this.ticketCauses$ = this.customContactFormService.getContactFormList(data.templateConfigurations!).pipe(
          tap(() => {
            if (this.selectedTicketCause) {
              this.onChangeTicketCause(null!);
            }
          }),
        );
      }
    }),
  );

  isLoaded$ = this.customContactFormService.getContactFormLoaded();

  constructor(
    private component: CmsComponentData<CjCmsContactFormComponent>,
    protected translation: TranslationService,
    protected customContactFormService: ContactFormService,
    protected userAccountFacade?: UserAccountFacade,
    protected winRef?: WindowRef,
  ) {
    this.setQualityProblems();
  }

  ngOnInit(): void {
    this.subscription.add(
      this.userAccountFacade!.get().subscribe((user) => {
        if (user?.uid) {
          this.contactForm.get('email')?.patchValue(user.uid);
        }
      }),
    );
    this.subscription.add(
      this.customContactFormService.getSendContactFormSuccess().subscribe((success) => {
        if (success) {
          this.onSuccess();
        }
      }),
    );
    this.subscription.add(
      this.customContactFormService.getSendContactFormError().subscribe((error) => {
        if (error) {
          this.onError();
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.customContactFormService.clearContactFormList();
    this.customContactFormService.resetForm();
  }

  private onSuccess(): void {
    this.busy$.next(false);
    this.onChangeTicketCause(null!);
    this.customContactFormService.resetForm();
    this.customContactFormService.clearSendContactForm();
    this.scrollToTop();
  }

  private onError(): void {
    this.busy$.next(false);
    this.customContactFormService.clearSendContactForm();
  }

  onChangeTicketCause(ticketCause: CsTicketCause): void {
    this.selectedTicketCause = ticketCause;
    if (this.selectedTicketCause?.templates!.length === 1) {
      this.contactForm.get('templateCode')?.patchValue(this.selectedTicketCause.templates[0].code);
      this.onChangeTicketCauseTemplate(this.selectedTicketCause.templates[0]);
    } else {
      this.contactForm.get('templateCode')?.patchValue(null);
      this.selectedTicketTemplate = null!;
    }
  }

  onChangeTicketCauseTemplate(ticketTemplate: CsTicketTemplate): void {
    this.selectedTicketTemplate = ticketTemplate;
    this.requiredOrderCode = this.displayOrderCode();
    this.isReturnForQuality = this.selectedTicketTemplate.configuration === CsTicketConfiguration.RETURN_FOR_QUALITY;
    this.customContactFormService.toggleTemplateControls(this.selectedTicketTemplate.configuration!);
  }

  displayOrderCode(): boolean {
    return [CsTicketConfiguration.WITH_ORDER_CODE, CsTicketConfiguration.RETURN_FOR_QUALITY].includes(
      this.selectedTicketTemplate?.configuration!,
    );
  }

  addProductAffected(): void {
    this.customContactFormService.addProductAffected();
  }

  removeProductAffected(index: number): void {
    this.customContactFormService.removeProductAffected(index);
  }

  get productsAffectedControls(): any[] {
    return this.customContactFormService.productsAffectedFormArray.controls;
  }

  onSubmit(): void {
    if (!this.contactForm.valid) {
      this.contactForm.markAllAsTouched();
      return;
    }
    this.busy$.next(true);
    const form = this.contactForm.value as CsTicketContactForm;
    delete form.causeCode;
    if (this.displayOrderCode()) {
      form.orderCode = this.ORDER_CODE_PREFIX + this.contactForm.get('orderCode')!.value?.trim();
    }
    this.customContactFormService.sendContactForm(form, this.selectedTicketTemplate?.configuration!);
  }

  private scrollToTop(): void {
    this.winRef?.nativeWindow?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  setQualityProblems(): void {
    this.subscription.add(
      combineLatest([
        this.translation.translate('contactForm.labels.qualityProblems.excessFat'),
        this.translation.translate('contactForm.labels.qualityProblems.smellFlavorColorProblems'),
        this.translation.translate('contactForm.labels.qualityProblems.vacuumLoss'),
        this.translation.translate('contactForm.labels.qualityProblems.excessLackCuring'),
        this.translation.translate('contactForm.labels.qualityProblems.other'),
      ]).subscribe(
        ([textExcessFat, textSmellFlavorColorProblems, textVacuumLoss, textExcessLackCuring, textOther]) =>
          (this.qualityProblems = [
            {
              code: CsTicketQualityProblem.EXCESS_FAT,
              name: textExcessFat,
            },
            {
              code: CsTicketQualityProblem.SMELL_FLAVOR_COLOR_PROBLEMS,
              name: textSmellFlavorColorProblems,
            },
            {
              code: CsTicketQualityProblem.VACUUM_LOSS,
              name: textVacuumLoss,
            },
            {
              code: CsTicketQualityProblem.EXCESS_LACK_CURING,
              name: textExcessLackCuring,
            },
            {
              code: CsTicketQualityProblem.OTHER,
              name: textOther,
            },
          ]),
      ),
    );
  }
}
