import { Component } from '@angular/core';
import { OrderDetailItemsComponent } from '@spartacus/order/components';
import { Observable, map, tap } from 'rxjs';
import { CjCartItemDisplayType } from 'src/app/cms-components/cart/base/components/cart-shared/cart.model';
import { CjOrder } from '../../../order.model';

@Component({
  selector: 'cj-order-detail-items',
  templateUrl: './order-detail-items.component.html',
  styleUrl: './order-detail-items.component.scss',
})
export class CjOrderDetailItemsComponent extends OrderDetailItemsComponent {
  cartItemDisplayTypes = CjCartItemDisplayType;

  override order$: Observable<CjOrder> = this.orderDetailsService.getOrderDetails().pipe(
    map((o) => o as CjOrder),
    tap((order) => {
      this.pickupConsignments = this.getGroupedConsignments(order, true);
      this.deliveryConsignments = this.getGroupedConsignments(order, false);

      this.pickupUnconsignedEntries = this.getUnconsignedEntries(order, true);
      this.deliveryUnConsignedEntries = this.getUnconsignedEntries(order, false);
    }),
  );
}
