import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserIdService } from '@spartacus/core';
import { Observable, take, tap } from 'rxjs';
import { CjActiveCartService } from '../../cart/base/core/facade/active-cart.service';
import { CjGiftCard } from './model/gift-card.model';

@Component({
  selector: 'cj-gift-card-form',
  templateUrl: './gift-card-form.component.html',
  styleUrls: ['./gift-card-form.component.scss'],
})
export class CjGiftCardFormComponent implements OnInit {
  giftCards$: Observable<CjGiftCard[]> = this.activeCartService.getAvailableGiftCards();
  selectedGiftCard$: Observable<CjGiftCard | undefined> = this.activeCartService.getGiftCard().pipe(
    tap((selectedGiftCard) => {
      selectedGiftCard &&
        this.giftCardForm.patchValue({ gift: true, giftCard: selectedGiftCard, giftCardMessage: selectedGiftCard.giftCardMessage });
    }),
  );

  @Output() submitGiftCard: EventEmitter<{ gift: boolean; giftCard: CjGiftCard; giftCardMessage: string }> = new EventEmitter();

  giftCardForm: FormGroup = this.fb.group({
    gift: [false],
    giftCard: [null, Validators.required],
    giftCardMessage: [''],
  });

  constructor(
    protected activeCartService: CjActiveCartService,
    protected userIdService: UserIdService,
    protected fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    // Force first subscription to initialize the form
    this.selectedGiftCard$.pipe(take(1)).subscribe();
  }

  resetForm() {
    this.giftCardForm.reset();
  }

  submit(): void {
    if (this.giftCardForm.valid || this.giftCardForm.get('gift')?.value === false) {
      this.submitGiftCard.emit(this.giftCardForm.value);
    }
  }
}
