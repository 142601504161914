import { CmsComponent } from '@spartacus/core';

export interface CjCmsProductCard extends CmsComponent {
  displayType?: CjProductCardDisplayType;
  product?: string;
}

export enum CjProductCardDisplayType {
  OPTION_ONE = 'OPTION_ONE',
  OPTION_TWO = 'OPTION_TWO',
  OPTION_THREE = 'OPTION_THREE',
}
