<cx-spinner class="overlay" *ngIf="isUpdating$ | async"> </cx-spinner>

<form (ngSubmit)="onSubmit()" [formGroup]="form" class="cj-update-profile">
  <h2 class="cj-update-profile__title">
    {{ 'updateProfileForm.contactInfo' | cxTranslate }}
  </h2>

  <!-- Name -->
  <div class="form-group">
    <label class="label-content required">{{
      'updateProfileForm.firstName.label' | cxTranslate
    }}</label>
    <input
      type="text"
      class="form-control"
      name="name"
      placeholder="{{
        'updateProfileForm.firstName.placeholder' | cxTranslate
      }}"
      formControlName="name"
    />
    <cx-form-errors [control]="form.get('name')"></cx-form-errors>
  </div>

  <!-- Email -->
  <div class="form-group">
    <label class="label-content"
      >{{ 'updateProfileForm.email' | cxTranslate }}

      <span
        class="tooltip-toggle"
        [attr.aria-label]="'updateProfileForm.updateEmailText' | cxTranslate"
        tabindex="0"
      >
        <i class="tooltip-toggle__icon icon-info"></i>
      </span>
    </label>
    <input
      required="true"
      type="email"
      name="uid"
      formControlName="uid"
      placeholder="{{
        'updateEmailForm.newEmailAddress.placeholder' | cxTranslate
      }}"
      class="form-control"
      [readOnly]="true"
    />
    <cx-form-errors [control]="form.get('uid')"></cx-form-errors>
  </div>

  <!-- NIF -->
  <div class="form-group">
    <label class="label-content">
      {{ 'updateProfileForm.documentIdentifier' | cxTranslate }}
    </label>
    <input
      type="text"
      name="documentIdentifier"
      formControlName="documentIdentifier"
      class="form-control"
      [readOnly]="service.isDocumentIdentifierReadOnly"
    />
    <cx-form-errors [control]="form.get('documentIdentifier')"></cx-form-errors>
  </div>

  <h2 class="cj-update-profile__title">
    {{ 'updateProfileForm.addressInfo' | cxTranslate }}
  </h2>

  <div class="form-group" formGroupName="defaultAddress">
    <label class="label-content required">
      {{ 'updateProfileForm.phone' | cxTranslate }}
    </label>
    <input
      [maxLength]="20"
      type="text"
      class="form-control"
      name="phone"
      formControlName="phone"
    />
  </div>

  <div class="form-group" formGroupName="defaultAddress">
    <label class="label-content required">{{
      'updateProfileForm.address' | cxTranslate
    }}</label>
    <input
      [maxLength]="35"
      type="text"
      class="form-control"
      name="line1"
      formControlName="line1"
    />
    <cx-form-errors
      [control]="form.get('defaultAddress.line1')"
    ></cx-form-errors>
    <input
      [maxLength]="35"
      type="text"
      class="form-control"
      name="line2"
      formControlName="line2"
    />
    <cx-form-errors
      [control]="form.get('defaultAddress.line2')"
    ></cx-form-errors>
  </div>

  <div class="form-group" formGroupName="defaultAddress">
    <label class="label-content required">{{
      'updateProfileForm.postalCode' | cxTranslate
    }}</label>
    <input
      [maxLength]="12"
      type="text"
      class="form-control"
      name="postalCode"
      formControlName="postalCode"
    />
    <cx-form-errors
      [control]="form.get('defaultAddress.postalCode')"
    ></cx-form-errors>
  </div>

  <div class="form-group" formGroupName="defaultAddress">
    <label class="label-content required">{{
      'updateProfileForm.town' | cxTranslate
    }}</label>
    <input
      [maxLength]="25"
      type="text"
      class="form-control"
      name="town"
      formControlName="town"
    />
    <cx-form-errors
      [control]="form.get('defaultAddress.town')"
    ></cx-form-errors>
  </div>

  <ng-container *ngIf="countries$ | async as countries">
    <ng-container *ngIf="countries.length !== 0" formGroupName="defaultAddress">
      <div class="form-group" aria-required="true" formGroupName="country">
        <label class="label-content required">{{
          'updateProfileForm.country.label' | cxTranslate
        }}</label>
        <ng-select
          class="country-select"
          formControlName="isocode"
          [searchable]="true"
          [clearable]="false"
          [items]="countries"
          bindLabel="name"
          bindValue="isocode"
          placeholder="{{ 'updateProfileForm.country.placeholder' | cxTranslate }}"
          (change)="countrySelected($event)"
        >
        </ng-select>
        <cx-form-errors
          [control]="form.get('defaultAddress.country.isocode')"
        ></cx-form-errors>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="regions$ | async as regions">
    <ng-container *ngIf="regions.length !== 0" formGroupName="defaultAddress">
      <div class="form-group" aria-required="true" formGroupName="region">
        <label class="label-content required">{{
          'updateProfileForm.region.label' | cxTranslate
        }}</label>
        <ng-select
          class="region-select"
          formControlName="isocode"
          [searchable]="true"
          [clearable]="false"
          [items]="regions"
          bindLabel="name"
          bindValue="isocode"
          placeholder="{{ 'updateProfileForm.region.placeholder' | cxTranslate }}"
          (change)="regionSelected($event)"
        >
        </ng-select>
        <cx-form-errors
          [control]="form.get('defaultAddress.region.isocode')"
        ></cx-form-errors>
      </div>
    </ng-container>
  </ng-container>

  <button
    class="btn btn-block btn-primary"
    [disabled]="form.disabled"
    *ngIf="!isButtonDisable"
  >
    {{ 'common.update' | cxTranslate }}
  </button>
</form>
