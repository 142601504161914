<ng-container *ngIf="componentData.data$ | async as data">
  <h4 class="cj-footer-info__title" *ngIf="data.title">{{ data.title }}</h4>
  <ng-container *ngIf="paragraphs$ | async as paragraphs">
    <ng-container *ngFor="let paragraph$ of paragraphs">
      <ng-container
        *ngIf="paragraph$ | async as paragraph"
        [cxComponentWrapper]="{
          flexType: paragraph.typeCode,
          typeCode: paragraph.typeCode,
          uid: paragraph.uid
        }"
      ></ng-container>
    </ng-container>
  </ng-container>
</ng-container>
