import { Component } from '@angular/core';
import { isNotNullable } from '@spartacus/core';
import { ProductImagesComponent } from '@spartacus/storefront';
import { Observable, distinctUntilChanged, filter, map, of, tap } from 'rxjs';
import { CjProduct } from 'src/app/shared/models/product.model';
import { CjProductImages } from './product-images.model';

@Component({
  selector: 'cj-product-images',
  templateUrl: './product-images.component.html',
  styleUrls: ['./product-images.component.scss'],
})
export class CjProductImagesComponent extends ProductImagesComponent {
  protected override product$: Observable<CjProduct> = this.currentProductService.getProduct().pipe(
    filter(isNotNullable),
    distinctUntilChanged(),
    tap((p: CjProduct) => {
      this.mainMediaContainer.next(p.images?.['PRIMARY'] ? p.images['PRIMARY'] : {});
    }),
  );
  thumbsOverride$: Observable<Observable<CjProductImages>[]> = this.product$.pipe(map((p: CjProduct) => this.cjCreateThumbs(p)));

  /**
   * Return an array of CarouselItems for the product thumbnails.
   * In case there are less then 2 thumbs, we return null.
   */
  private cjCreateThumbs(product: CjProduct): Observable<any>[] {
    if (
      !product.images ||
      !product.images['GALLERY'] ||
      (Array.isArray(product.images['GALLERY']) && product.images['GALLERY'].length < 2)
    ) {
      return [];
    }

    return (Array.isArray(product.images['GALLERY']) ? product.images['GALLERY'] : [product.images['GALLERY']]).map((c) =>
      of({ container: c }),
    );
  }
}
