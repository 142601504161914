import { Component } from '@angular/core';
import { ResetPasswordComponent } from '@spartacus/user/profile/components';

@Component({
  selector: 'cj-reset-password-component',
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss',
})
export class CjResetPasswordComponent extends ResetPasswordComponent {

}
