import { ChangeDetectorRef, Component } from '@angular/core';
import { CmsComponent, CmsService, Product } from '@spartacus/core';
import { ProductListComponentService, ProductScrollComponent } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { CjProduct } from 'src/app/shared/models/product.model';
import { CjProductCardDisplayType } from '../../card/product-card.model';
import { CjProducGridBanner, CjProductGridElement } from '../product-list.model';
import { CjProductScrollComponentService } from './product-scroll-component.service';

@Component({
  selector: 'cj-product-scroll',
  templateUrl: './product-scroll.component.html',
  styleUrls: ['./product-scroll.component.scss'],
})
export class CjProductScrollComponent extends ProductScrollComponent {
  displayTypes = CjProductCardDisplayType;
  elements$: Observable<CjProductGridElement[]> = this.productScrollComponentService.elements$;

  constructor(
    productListComponentService: ProductListComponentService,
    ref: ChangeDetectorRef,
    private readonly productScrollComponentService: CjProductScrollComponentService,
    private readonly cmsService: CmsService,
  ) {
    super(productListComponentService, ref);
  }

  scroll(totalPages: number | undefined) {
    this.productScrollComponentService.scrollPage(totalPages);
  }

  isProduct(element: Product | CjProducGridBanner): boolean {
    return 'code' in element;
  }

  asProduct(element: Product | CjProducGridBanner): CjProduct {
    return element as CjProduct;
  }

  asBanner(element: Product | CjProducGridBanner): CjProducGridBanner {
    return element as CjProducGridBanner;
  }

  getBanner(banner: string): Observable<CmsComponent | null> {
    return this.cmsService.getComponentData(banner);
  }
}
