import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { CmsLinkComponent } from '@spartacus/core';
import { CjCmsUtilsService } from 'src/app/shared/utils/cms-utils.service';
import { OsborneMenuCardComponent, OsborneMenuCollapsableComponent } from '../menu.model';
import { HamburgerMenuService } from '@spartacus/storefront';
import { Subscription } from 'rxjs';
@Component({
  selector: 'cj-menu-collapsable',
  templateUrl: './menu-collapsable.component.html',
  styleUrls: ['./menu-collapsable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjMenuCollapsableComponent implements OnDestroy {
  @Input() data?: OsborneMenuCollapsableComponent | null;
  @Input() isExpanded = false;

  @Output() openMenuItem: EventEmitter<string> = new EventEmitter();

  private subscription: Subscription;

  constructor(
    protected cmsUtils: CjCmsUtilsService,
    private hamburgerMenuService: HamburgerMenuService,
  ) {
    this.subscription = this.hamburgerMenuService.isExpanded.subscribe((isExpanded) => {
      if (!isExpanded) {
        this.openMenuItem.emit('');
      }
    });
  }

  toggleMenu(uuid: string): void {
    this.openMenuItem.emit(!this.isExpanded ? uuid : '');
  }

  getCards(uids: string) {
    return this.cmsUtils.getComponentDataList<OsborneMenuCardComponent>(uids);
  }
  getLinks(uids: string) {
    return this.cmsUtils.getComponentDataList<CmsLinkComponent>(uids);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
