<div
  *ngIf="component.data$ | async as data"
  class="cj-product-simple-multimedia-banner"
>
  <div class="cj-product-simple-multimedia-banner__info">
    <h2 *ngIf="data.title" class="cj-product-simple-multimedia-banner__title">
      {{ data.title }}
    </h2>
    <ng-container
      *ngIf="data.description as description"
      class="cj-product-simple-multimedia-banner__description"
      [cxComponentWrapper]="{
        flexType: description.typeCode,
        typeCode: description.typeCode,
        uid: description.uid
      }"
    ></ng-container>
  </div>
  <cx-media
    *ngIf="data.image"
    [container]="data.image"
    class="cj-product-simple-multimedia-banner__img cj-media--parallax"
    gsapAnimation
    [animationType]="'parallaxVertical'"
  ></cx-media>
</div>
