import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CmsLinkComponent, CmsService, WindowRef } from '@spartacus/core';
import { CmsComponentData, NavigationService } from '@spartacus/storefront';
import { Observable, fromEvent } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { CjCmsUtilsService } from '../../../shared/utils/cms-utils.service';
import { OsborneMenuCollapsableComponent, OsborneMenuComponent } from './menu.model';
import { BREAKPOINTS } from 'src/app/shared/constants/screen-breakpoints';
@Component({
  selector: 'cj-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjMenuComponent implements OnInit {
  screenWidth?: number;
  breakpoints = BREAKPOINTS;
  openMenuItem?: string;

  collapsables$: Observable<Observable<OsborneMenuCollapsableComponent>[]> = this.component.data$.pipe(
    map((data) => data.collapsableMenus),
    filter((collapsables) => !!collapsables),
    map((collapsables) => {
      if (!collapsables) {
        return [];
      }
      return this.cmsUtils.getComponentDataList<OsborneMenuCollapsableComponent>(collapsables);
    }),
  );
  nonCollapsables$: Observable<Observable<CmsLinkComponent>[]> = this.component.data$.pipe(
    map((data) => data.nonCollapsableMenus),
    filter((nonCollapsables) => !!nonCollapsables),
    map((nonCollapsables) => {
      if (!nonCollapsables) {
        return [];
      }
      return this.cmsUtils.getComponentDataList<CmsLinkComponent>(nonCollapsables);
    }),
  );
  links$: Observable<Observable<CmsLinkComponent>[]> = this.component.data$.pipe(
    map((data) => data.links),
    filter((links) => !!links),
    map((links) => {
      if (!links) {
        return [];
      }
      return this.cmsUtils.getComponentDataList<CmsLinkComponent>(links);
    }),
  );

  constructor(
    protected component: CmsComponentData<OsborneMenuComponent>,
    protected service: NavigationService,
    protected cmsService: CmsService,
    protected cmsUtils: CjCmsUtilsService,
    private readonly windowRef: WindowRef,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.screenWidth = this.windowRef.nativeWindow?.innerWidth;

    if (this.windowRef.nativeWindow) {
      fromEvent(this.windowRef.nativeWindow, 'resize')
        .pipe(
          map(() => this.windowRef.nativeWindow?.innerWidth),
          startWith(this.windowRef.nativeWindow?.innerWidth),
        )
        .subscribe((size) => {
          this.screenWidth = size;
          this.cdr.detectChanges();
        });
    }
  }

  changeOpenMenuItem(item: string) {
    this.openMenuItem = item;
  }
}
