import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CartCouponModule, CartSharedModule, CartValidationWarningsModule } from '@spartacus/cart/base/components';
import { CmsConfig, I18nModule, UrlModule, provideConfig } from '@spartacus/core';
import { PromotionsModule } from '@spartacus/storefront';
import { CjCartSharedModule } from '../cart-shared/cart-shared.module';
import { CjCartDetailsComponent } from './cart-details.component';

@NgModule({
  imports: [
    CartSharedModule,
    CjCartSharedModule,
    CommonModule,
    CartCouponModule,
    RouterModule,
    UrlModule,
    PromotionsModule,
    I18nModule,
    CartValidationWarningsModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CartComponent: {
          component: () => import('./cart-details.component').then((m) => m.CjCartDetailsComponent),
        },
      },
    }),
  ],
  declarations: [CjCartDetailsComponent],
  exports: [CjCartDetailsComponent],
})
export class CjCartDetailsModule {}
