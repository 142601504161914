import { ChangeDetectionStrategy, Component, ElementRef, ViewChild, ViewContainerRef } from '@angular/core';
import { CmsComponentData, LaunchDialogService } from '@spartacus/storefront';
import { take } from 'rxjs';
import { POPUP_DIALOG } from './popup-dialog/popup-dialog-layout.config';
import { CjCmsPopupComponent } from './popup.model';
@Component({
  selector: 'cj-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjPopupComponent {
  @ViewChild('popupButton') popupButton?: ElementRef;

  constructor(
    readonly component: CmsComponentData<CjCmsPopupComponent>,
    private readonly dialogService: LaunchDialogService,
    private readonly vcr: ViewContainerRef,
  ) {}

  openDialog(data: CjCmsPopupComponent) {
    if (data.text) {
      this.dialogService
        .openDialog(POPUP_DIALOG, this.popupButton, this.vcr, { title: data.title, icon: data.icon, component: data.text })
        ?.pipe(take(1))
        .subscribe();
    }
  }
}
