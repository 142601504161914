import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CurrencyService, LanguageService } from '@spartacus/core';
import { ICON_TYPE, LanguageCurrencyComponent, LaunchDialogService } from '@spartacus/storefront';
import { SITE_CONTEXT_SELECTOR_DIALOG } from './site-context-selector-dialog/site-context-selector-layout.config';

@Component({
  selector: 'cj-language-currency',
  templateUrl: './language-currency.component.html',
  styleUrls: ['./language-currency.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjLanguageCurrencyComponent extends LanguageCurrencyComponent {
  iconTypes = ICON_TYPE;

  activeLang$ = this.languageService.getActive();
  activeCurr$ = this.currencyService.getActive();

  constructor(
    private readonly languageService: LanguageService,
    private readonly currencyService: CurrencyService,
    private readonly launchDialogService: LaunchDialogService,
  ) {
    super();
  }

  openDialog(): void {
    this.launchDialogService.openDialogAndSubscribe(SITE_CONTEXT_SELECTOR_DIALOG);
  }
}
