import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CardWithAddress, CheckoutDeliveryAddressComponent, CheckoutStepService } from '@spartacus/checkout/base/components';
import { CheckoutDeliveryAddressService } from '@spartacus/checkout/base/core';
import { CheckoutDeliveryAddressFacade, CheckoutDeliveryModesFacade } from '@spartacus/checkout/base/root';
import {
  Address,
  AuthService,
  GlobalMessageService,
  OCC_USER_ID_ANONYMOUS,
  TranslationService,
  UserAddressService,
  getLastValueSync,
} from '@spartacus/core';
import { Card, LaunchDialogService } from '@spartacus/storefront';
import { AddressBookComponentService } from '@spartacus/user/profile/components';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { Observable, Subscription, combineLatest, filter, map, of, shareReplay, switchMap, take, tap } from 'rxjs';
import { CjCheckoutPaymentService } from 'src/app/core/checkout/facade/checkout-payment.service';
import { CjAddressUtilsService } from 'src/app/shared/utils/address-utils.service';
import { CjActiveCartService } from '../../cart/base/core/facade/active-cart.service';
import { CjCart, CjUser } from '../../cart/base/models/cart.model';
import { ADDRESS_FORM_DIALOG } from '../../myaccount/address-book/address-form/address-form-dialog/address-form-layout.config';
import { CjAddressFormComponent } from '../../myaccount/address-book/address-form/address-form.component';
import { CjAddressFormLayout } from '../../myaccount/address-book/address-form/address-form.model';
import { BillingAddressSubmit, CjCheckoutBillingAddressComponent } from '../checkout-billing-address/checkout-billing-address.component';
import { CjCheckoutLoginComponent } from '../checkout-login/checkout-login.component';

@Component({
  selector: 'cj-delivery-address',
  templateUrl: './checkout-delivery-address.component.html',
  styleUrls: ['./checkout-delivery-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjCheckoutDeliveryAddressComponent extends CheckoutDeliveryAddressComponent implements OnDestroy {
  addressFormLayouts = CjAddressFormLayout;

  @ViewChild('guestLoginForm') guestLoginForm?: CjCheckoutLoginComponent;
  @ViewChild('shippingAddressForm') deliveryAddressForm?: CjAddressFormComponent;
  @ViewChild('billingAddressForm') billingAddressForm?: CjCheckoutBillingAddressComponent;
  @ViewChild('newAddressButton') newAddressButton?: ElementRef;

  user$: Observable<CjUser | undefined> = this.userProfileService.get();
  cart$: Observable<CjCart> = this.activeCartFacade.getActive().pipe(
    filter((cart) => cart && !!Object.keys(cart).length),
    map((cart) => cart as CjCart),
    shareReplay(1),
  );
  loggedIn$: Observable<boolean> = this.authService.isUserLoggedIn();

  protected sub$ = new Subscription();

  email?: string;
  deliveryAddress?: Address;
  nif?: string;
  billingAddress?: Address | null;

  isEmailSaved$: Observable<boolean> = this.loggedIn$.pipe(
    switchMap((loggedIn) =>
      loggedIn ? of(true) : this.cart$.pipe(map((cart) => !!(this.email?.toLowerCase() && cart.user?.uid?.includes(this.email)))),
    ),
    shareReplay(1),
  );
  isDeliveryAddressSaved$: Observable<boolean> = this.loggedIn$.pipe(
    switchMap((loggedIn) =>
      loggedIn
        ? of(true)
        : this.cart$.pipe(
            map(
              (cart) =>
                !!(
                  cart.deliveryAddress &&
                  this.deliveryAddress &&
                  this.addressUtils.compareAdresses(this.deliveryAddress, cart.deliveryAddress)
                ),
            ),
          ),
    ),
    shareReplay(1),
  );
  isNifSaved$: Observable<boolean> = this.cart$.pipe(
    map((cart) => !!(cart.user?.documentIdentifier || this.billingAddress === null)),
    shareReplay(1),
  );
  isBillingAddressSaved$: Observable<boolean> = this.cart$.pipe(
    map((cart) =>
      this.billingAddress !== null
        ? !!(
            this.billingAddress &&
            cart.paymentAddress &&
            (this.billingAddress.id === cart.deliveryAddress?.id ||
              this.addressUtils.compareAdresses(this.billingAddress, cart.paymentAddress))
          )
        : !cart.paymentAddress,
    ),
    shareReplay(1),
  );

  constructor(
    override userAddressService: UserAddressService,
    override checkoutDeliveryAddressFacade: CheckoutDeliveryAddressFacade,
    override activatedRoute: ActivatedRoute,
    override translationService: TranslationService,
    override activeCartFacade: CjActiveCartService,
    override checkoutStepService: CheckoutStepService,
    override checkoutDeliveryModesFacade: CheckoutDeliveryModesFacade,
    override globalMessageService: GlobalMessageService,

    private readonly vcr: ViewContainerRef,
    private readonly authService: AuthService,
    private readonly dialogService: LaunchDialogService,
    private readonly service: AddressBookComponentService,
    private readonly checkoutDeliveryAddressService: CheckoutDeliveryAddressService,
    private readonly checkoutPaymentService: CjCheckoutPaymentService,
    private readonly addressUtils: CjAddressUtilsService,
    private readonly userProfileService: UserProfileFacade,
  ) {
    super(
      userAddressService,
      checkoutDeliveryAddressFacade,
      activatedRoute,
      translationService,
      activeCartFacade,
      checkoutStepService,
      checkoutDeliveryModesFacade,
      globalMessageService,
    );
  }

  override showNewAddressForm(): void {
    this.dialogService
      .openDialog(ADDRESS_FORM_DIALOG, this.newAddressButton, this.vcr)
      ?.pipe(
        take(1),
        switchMap(() => this.dialogService.dialogClose.pipe(take(1))),
      )
      .subscribe((response: Address | string | undefined) => {
        if (response && typeof response !== 'string') {
          this.service.addUserAddress(response);
        }
      });
  }

  protected override createCards(): Observable<CardWithAddress[]> {
    return combineLatest([
      this.getSupportedAddresses(),
      this.selectedAddress$,
      this.translationService.translate('checkoutAddress.defaultDeliveryAddressTitle'),
    ]).pipe(
      tap(([addresses, selected]) => this.selectDefaultAddress(addresses, selected)),
      map(([addresses, selected, textDefaultTitle]) =>
        addresses?.map((address, i) => ({
          address,
          card: this.getAddressCardContent(address, selected, textDefaultTitle, i + 1),
        })),
      ),
    );
  }

  getAddressCardContent(address: Address, selected: Address | undefined, textDeliveryAddress: string, cardNumber: number): Card {
    return {
      role: 'region',
      title: address.companyName || textDeliveryAddress + ' ' + cardNumber,
      textBold: address.firstName + ' ' + address.lastName,
      text: [
        [address.line1, address.line2, address.town].join(', '),
        address.postalCode,
        address.cellphone || address.phone,
        address.email,
      ],
      customClass: selected && selected.id === address.id ? 'selected' : '',
    } as Card;
  }

  override next(): void {
    // Discard all pending subscriptions
    // this.sub$.unsubscribe();
    // this.sub$ = new Subscription();

    // Request all forms
    this.loggedIn$.pipe(take(1)).subscribe((loggedIn) => {
      if (!loggedIn) {
        this.requestGuestEmail();
        this.requestDeliveryAddress();
      }
      const user = getLastValueSync(this.user$);
      if (user?.documentIdentifier && user?.defaultAddress?.id) {
        // Save user nif in current cart if not already set
        this.cart$
          .pipe(
            filter((cart) => !cart.user?.documentIdentifier),
            take(1),
            switchMap(() => {
              this.nif = user.documentIdentifier;
              return this.activeCartFacade.setDocumentIdentifier({
                name: user.defaultAddress?.firstName,
                documentIdentifier: user.documentIdentifier,
                defaultAddress: {
                  country: { isocode: user.defaultAddress?.country?.isocode },
                },
              } as CjUser);
            }),
          )
          .subscribe();

        // Set user billing address as cart billing address
        this.billingAddress = user.defaultAddress;
        this.checkoutPaymentService.setBillingAddress(user.defaultAddress.id);
      } else {
        this.requestBillingAddress();
      }
    });

    this.sub$.add(
      combineLatest([this.isEmailSaved$, this.isDeliveryAddressSaved$, this.isNifSaved$, this.isBillingAddressSaved$])
        .pipe(
          filter((array: boolean[]) => array.every(Boolean)),
          take(1),
        )
        .subscribe(() => super.next()),
    );
  }

  requestGuestEmail() {
    // Only request email if cart has not been claimed
    this.sub$.add(
      this.cart$.pipe(take(1)).subscribe((cart) => {
        if (cart.user?.name?.toLowerCase() === OCC_USER_ID_ANONYMOUS) {
          this.guestLoginForm?.onSubmit();
        } else {
          this.email = cart.user?.uid?.split('|').reverse()[0];
        }
      }),
    );
  }
  saveEmail(email: string | undefined) {
    if (email) {
      this.email = email;
      this.activeCartFacade.addEmail(email);
    }
  }

  requestDeliveryAddress() {
    // Wait until email is set
    this.sub$.add(this.isEmailSaved$.pipe(filter(Boolean), take(1)).subscribe(() => this.deliveryAddressForm?.verifyAddress(this.email)));
  }
  saveDeliveryAddress(address: Address | undefined) {
    if (address) {
      this.deliveryAddress = address;
      this.checkoutDeliveryAddressService.createAndSetAddress(address);
    } else {
      this.sub$.add(this.cart$.pipe(take(1)).subscribe((cart) => (this.deliveryAddress = cart.deliveryAddress)));
    }
  }

  requestBillingAddress() {
    // Wait until email and deliveryAddress are set
    this.sub$.add(
      combineLatest([this.isEmailSaved$, this.isDeliveryAddressSaved$])
        .pipe(
          filter((array: boolean[]) => array.every(Boolean)),
          take(1),
        )
        .subscribe(() => this.billingAddressForm?.onSubmit()),
    );
  }
  saveBillingAddress(billingAddress: BillingAddressSubmit) {
    if (billingAddress) {
      if (billingAddress.requestBilling) {
        // Save nif only if not already set
        this.cart$
          .pipe(
            filter((cart) => !cart.user?.documentIdentifier),
            take(1),
            switchMap((cart) => {
              return this.activeCartFacade.setDocumentIdentifier({
                name: billingAddress.address?.firstName || cart.deliveryAddress?.firstName,
                documentIdentifier: billingAddress.nif,
                defaultAddress: {
                  country: { isocode: billingAddress.address?.country?.isocode || cart.deliveryAddress?.country?.isocode },
                },
              } as CjUser);
            }),
          )
          .subscribe(() => this.activeCartFacade.reloadActiveCart());

        if (billingAddress.sameAsDelivery) {
          // Wait until nif is set
          this.sub$.add(
            this.isNifSaved$
              .pipe(
                filter(Boolean),
                take(1),
                switchMap(() =>
                  this.cart$.pipe(
                    filter((cart: CjCart) => !!cart.deliveryAddress?.id),
                    take(1),
                  ),
                ),
              )
              .subscribe((cart) => {
                if (cart.deliveryAddress?.id) {
                  this.billingAddress = cart.deliveryAddress;
                  this.checkoutPaymentService.setBillingAddress(cart.deliveryAddress?.id);
                }
              }),
          );
        } else {
          if (billingAddress.address) {
            this.sub$.add(
              this.isNifSaved$.pipe(filter(Boolean), take(1)).subscribe(() => {
                this.billingAddress = billingAddress.address;
                this.checkoutPaymentService.createAndSetBillingAddress(billingAddress.address!);
              }),
            );
          } else {
            this.sub$.add(this.cart$.pipe(take(1)).subscribe((cart) => (this.billingAddress = cart.paymentAddress)));
          }
        }
      } else {
        this.checkoutPaymentService.unsetBillingAddress();
        this.billingAddress = null;
      }
    }
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
