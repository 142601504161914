import { Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CjCmsUtilsService } from 'src/app/shared/utils/cms-utils.service';
import { OsborneFooterISOsComponent, OsborneFooterIconComponent } from '../footer-navigation.model';
@Component({
  selector: 'cj-footer-isos',
  templateUrl: './footer-isos.component.html',
  styleUrls: ['./footer-isos.component.scss'],
})
export class CjFooterIsosComponent {
  constructor(
    protected cmsUtils: CjCmsUtilsService,
    protected componentData: CmsComponentData<OsborneFooterISOsComponent>,
  ) {}

  icons$: Observable<Observable<OsborneFooterIconComponent>[]> = this.componentData.data$.pipe(
    map((data) => {
      if (!data.icons) {
        return [];
      }
      return this.cmsUtils.getComponentDataList<OsborneFooterIconComponent>(data.icons);
    }),
  );
}
