import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { CjCmsRectangularBanner } from './rectangular-banner.model';

@Component({
  selector: 'cj-rectangular-banner',
  templateUrl: './rectangular-banner.component.html',
  styleUrls: ['./rectangular-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjRectangularBannerComponent {
  constructor(protected component: CmsComponentData<CjCmsRectangularBanner>) {}
}
