import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { CmsComponentData, LaunchDialogService } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { CjCmsUtilsService } from 'src/app/shared/utils/cms-utils.service';
import { WARRANTY_BANNER_DIALOG } from './warranty-banner-dialog/warranty-banner-dialog.config';
import { CjCmsOsborneBannerComponent, CjCmsOsborneModalComponent, CjCmsOsborneWarrantyComponent } from './warranty-banner.model';
import { BREAKPOINTS } from '../../../shared/constants/screen-breakpoints';
import { WindowRef } from '@spartacus/core';

@Component({
  selector: 'cj-warranty-banner',
  templateUrl: './warranty-banner.component.html',
  styleUrls: ['./warranty-banner.component.scss'],
})
export class CjWarrantyBannerComponent implements AfterViewInit, OnDestroy {
  breakpoints = BREAKPOINTS;
  data$ = this.componentData.data$;
  icons$ = this.data$.pipe(map((data) => (data.icons ? this.cmsUtils.getComponentDataList<CjCmsOsborneBannerComponent>(data.icons) : [])));

  screenWidth?: number;
  isExpanded = true;
  private resizeSubscription?: Subscription;

  constructor(
    protected componentData: CmsComponentData<CjCmsOsborneWarrantyComponent>,
    protected cmsUtils: CjCmsUtilsService,
    private launchDialogService: LaunchDialogService,
    private windowRef: WindowRef,
  ) {}

  ngAfterViewInit() {
    if (this.windowRef.isBrowser()) {
      this.resizeSubscription = this.windowRef.resize$.subscribe(() => this.updateWindowSize());
      this.updateWindowSize();
    }
  }

  ngOnDestroy() {
    this.resizeSubscription?.unsubscribe();
  }

  private updateWindowSize() {
    if (this.windowRef.nativeWindow) {
      this.screenWidth = this.windowRef.nativeWindow.innerWidth;
      this.isExpanded = this.screenWidth > this.breakpoints.SM;
    }
  }

  openDialog(data: CjCmsOsborneModalComponent | undefined): void {
    if (this.screenWidth && this.screenWidth <= this.breakpoints.SM) {
      this.isExpanded = !this.isExpanded;
    } else if (data) {
      this.launchDialogService.openDialogAndSubscribe(WARRANTY_BANNER_DIALOG, undefined, data);
    }
  }
}
