import { NgModule } from '@angular/core';
import { CjOccUserAddressAdapter } from 'src/app/core/occ/adapters/user/occ-user-address.adapter';
import { CjLoginModule } from './account/login/login.module';
import { CjUserAccountModule } from './account/user-account.module';
import { CjUserAddressAdapter } from './connectors/address/user-address.adapter';
import { CjUserProfileComponentsModule } from './profile/user-profile-components.module';
import { CjUserProfileModule } from './profile/user-profile.module';
import { EffectsModule } from '@ngrx/effects';
import { CjUserAddressesEffects } from './store/effects/user-addresses.effect';

@NgModule({
  imports: [
    CjLoginModule,
    CjUserProfileComponentsModule,
    CjUserAccountModule,
    CjUserProfileModule,
    EffectsModule.forFeature([CjUserAddressesEffects]),
  ],
  providers: [
    {
      provide: CjUserAddressAdapter,
      useClass: CjOccUserAddressAdapter,
    },
  ],
})
export class CjUserModule {}
