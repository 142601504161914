import { Component, ElementRef, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { RoutingService, TranslationService } from '@spartacus/core';
import { OrderHistoryComponent } from '@spartacus/order/components';
import { Order, OrderHistoryFacade, ReplenishmentOrderHistoryFacade } from '@spartacus/order/root';
import { LaunchDialogService } from '@spartacus/storefront';
import { Observable, Subscription, take, tap } from 'rxjs';
import { CjOrderHistoryList } from '../../order.model';
import { ORDER_DETAIL_REORDER_DIALOG } from '../order-details/order-detail-actions/order-detail-reorder-dialog/order-detail-reorder-layout-config';

@Component({
  selector: 'cj-order-history',
  templateUrl: './order-history.component.html',
  styleUrl: './order-history.component.scss',
})
export class CjOrderHistoryComponent extends OrderHistoryComponent implements OnDestroy {
  @ViewChild('element') reOrder: ElementRef | undefined;
  protected subscription = new Subscription();
  private CJ_PAGE_SIZE = 5;

  override orders$: Observable<CjOrderHistoryList | undefined> = this.orderHistoryFacade.getOrderHistoryList(this.CJ_PAGE_SIZE).pipe(
    tap((orders: CjOrderHistoryList | undefined) => {
      this.setOrderHistoryParams(orders);
    }),
  );

  constructor(
    protected override routing: RoutingService,
    protected override orderHistoryFacade: OrderHistoryFacade,
    protected override translation: TranslationService,
    protected override replenishmentOrderHistoryFacade: ReplenishmentOrderHistoryFacade,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
  ) {
    super(routing, orderHistoryFacade, translation, replenishmentOrderHistoryFacade);
  }

  onReorderClick(order: Order) {
    if (order?.code) {
      this.launchDialogService
        .openDialog(ORDER_DETAIL_REORDER_DIALOG, this.reOrder, this.vcr, { orderCode: order.code })
        ?.pipe(take(1))
        .subscribe();
    }
  }

  override ngOnDestroy() {
    this.orderHistoryFacade.clearOrderList();

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
