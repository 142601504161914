<div class="row mt-4 cj-billing-address">
  <form [formGroup]="billingForm" (submit)="onSubmit()" class="col-sm-12">
    <!-- Request Billing -->
    <div class="form-group mb-0">
      <label class="d-flex align-items-center mb-0">
        <input type="checkbox" class="form-control position-relative ml-0 my-0" formControlName="requestBilling" />
        <span class="label-content required ml-3 mb-0">{{ 'addressForm.requestBilling' | cxTranslate }}</span>
      </label>
    </div>

    <!-- Same as billing address -->
    <ng-container *ngIf="billingForm.get('requestBilling')?.value">
      <div class="form-group mb-0 mt-3">
        <label class="d-flex align-items-center mb-0">
          <input type="checkbox" class="form-control position-relative ml-0 my-0" formControlName="sameAsDelivery" />
          <span class="label-content required ml-3 mb-0">{{ 'addressForm.sameAsDelivery' | cxTranslate }}</span>
        </label>
      </div>
      <!-- Nif -->
      <div class="form-group mb-0 mt-3">
        <label class="mb-0">
          <span class="label-content required d-none">{{ 'addressForm.nif.label' | cxTranslate }}</span>
          <input
            required="true"
            type="input"
            class="form-control"
            placeholder="{{ 'addressForm.nif.placeholder' | cxTranslate }}"
            formControlName="nif"
            [readOnly]="nif$ | async"
          />
        </label>
      </div>
    </ng-container>
  </form>

  <div class="col-12">
    <ng-container *ngIf="billingForm.get('requestBilling')?.value && !billingForm.get('sameAsDelivery')?.value">
      <cj-address-form
        #billingAddressForm
        (submitAddress)="submit($event)"
        [addressData]="(billingAddress$ | async) || {}"
        [showActionBtns]="false"
        [formLayout]="addressFormLayouts.BILLING"
      ></cj-address-form>
    </ng-container>
  </div>
</div>
