/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { HamburgerMenuService } from '@spartacus/storefront';
import { CjHamburgerMenuService } from './hamburger-menu.service';

@NgModule({
  imports: [CommonModule, I18nModule],
  providers: [
    { provide: HamburgerMenuService, useClass: CjHamburgerMenuService },
  ],
})
export class CjHamburgerMenuModule {}
