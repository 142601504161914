import { ChangeDetectionStrategy, Component, OnDestroy, ViewChild, inject } from '@angular/core';
import { CheckoutDeliveryModeComponent } from '@spartacus/checkout/base/components';
import { Subscription, filter, take } from 'rxjs';
import { CjActiveCartService } from '../../cart/base/core/facade/active-cart.service';
import { CjGiftCardFormComponent } from '../checkout-gift-card/gift-card-form.component';
import { CjGiftCard } from '../checkout-gift-card/model/gift-card.model';

@Component({
  selector: 'cj-delivery-mode',
  templateUrl: './checkout-delivery-mode.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjCheckoutDeliveryModeComponent extends CheckoutDeliveryModeComponent implements OnDestroy {
  @ViewChild('giftCardForm') giftCardForm?: CjGiftCardFormComponent;
  private readonly activeCartService: CjActiveCartService = inject(CjActiveCartService);
  protected sub$ = new Subscription();

  override next(): void {
    this.giftCardForm?.submit();
  }

  giftCardSubmit(card: { gift: boolean; giftCard: CjGiftCard; giftCardMessage: string }) {
    if (card?.gift) {
      this.activeCartService.setGiftCard(card?.giftCard?.code!, card.giftCardMessage);

      this.sub$.add(
        this.activeCartService
          .getActive()
          .pipe(
            filter(
              (cart) =>
                cart.giftCardProduct?.code === card.giftCard?.code && cart.giftCardProduct?.giftCardMessage === card.giftCardMessage,
            ),
            take(1),
          )
          .subscribe(() => super.next()),
      );
    } else {
      this.giftCardForm?.resetForm();
      this.sub$.add(
        this.activeCartService
          .getActive()
          .pipe(
            take(1),
            filter((cart) => !!cart.giftCardProduct),
          )
          .subscribe(() => this.activeCartService.removeGiftCard()),
      );

      this.sub$.add(
        this.activeCartService
          .getActive()
          .pipe(
            filter((cart) => !!cart && !cart.giftCardProduct),
            take(1),
          )
          .subscribe(() => super.next()),
      );
    }
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
