import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PaymentType } from '@spartacus/cart/base/root';
import { CheckoutPaymentTypeComponent } from '@spartacus/checkout/b2b/components';
import { B2BPaymentTypeEnum } from '@spartacus/checkout/b2b/root';
import { CheckoutStepService } from '@spartacus/checkout/base/components';
import { CheckoutQueryReloadEvent, CheckoutStepType } from '@spartacus/checkout/base/root';
import { EventService, GlobalMessageService, PaymentDetails, isNotUndefined } from '@spartacus/core';
import { Observable, combineLatest, distinctUntilChanged, filter, map, take, tap } from 'rxjs';
import { CjCheckoutPaymentService } from 'src/app/core/checkout/facade/checkout-payment.service';
import { CjActiveCartService } from '../../cart/base/core/facade/active-cart.service';
import { CjCart } from '../../cart/base/models/cart.model';
import { CjRedsysService } from '../checkout-place-order/redsys/redsys.service';
import { CjCheckoutPaymentTypeFacade } from './facade/checkout-payment-type.facade';
import { CjPaymentType } from './model/payment-types.model';

@Component({
  selector: 'cj-payment-type',
  templateUrl: './checkout-payment-type.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjCheckoutPaymentTypeComponent extends CheckoutPaymentTypeComponent implements AfterViewInit {
  redsysCards$: Observable<PaymentDetails[]> = this.redsysService.getRedsysCards();

  selectedCard$: Observable<PaymentDetails | undefined> = this.checkoutPaymentFacade.getPaymentDetailsState().pipe(
    filter((state) => !state.loading && !state.error),
    map((state) => state.data),
  );

  override paymentTypes$: Observable<PaymentType[]> = this.checkoutPaymentTypeFacade.getPaymentTypes();
  override typeSelected$: Observable<PaymentType> = combineLatest([
    this.activeCartService.getActive().pipe(filter((cart) => !!cart)),
    this.paymentTypes$,
  ]).pipe(
    map(([{ paymentMode }, availablePaymentTypes]: [CjCart, PaymentType[]]) => {
      if (
        paymentMode &&
        availablePaymentTypes.find((availablePaymentType) => {
          return availablePaymentType.code === paymentMode.code;
        })
      ) {
        return paymentMode;
      }
      if (availablePaymentTypes.length) {
        this.busy$.next(true);
        this.checkoutPaymentTypeFacade.setPaymentType(availablePaymentTypes[0].code as string).subscribe({
          complete: () => this.onSuccess(),
          error: () => this.onError(),
        });
        return availablePaymentTypes[0];
      }
      return undefined;
    }),
    filter(isNotUndefined),
    distinctUntilChanged(),
    tap((selected) => {
      this.typeSelected = selected?.code;
      this.checkoutStepService.resetSteps();
      this.checkoutStepService.disableEnableStep(CheckoutStepType.PAYMENT_DETAILS, selected?.code === B2BPaymentTypeEnum.ACCOUNT_PAYMENT);
    }),
  );

  saveCardFormControl = new FormControl();
  paymentType = CjPaymentType;

  constructor(
    protected override checkoutPaymentTypeFacade: CjCheckoutPaymentTypeFacade,
    protected override checkoutStepService: CheckoutStepService,
    protected override activatedRoute: ActivatedRoute,
    protected override globalMessageService: GlobalMessageService,
    private readonly activeCartService: CjActiveCartService,
    private readonly checkoutPaymentFacade: CjCheckoutPaymentService,
    private readonly redsysService: CjRedsysService,
    private readonly cdr: ChangeDetectorRef,
    private readonly eventService: EventService,
  ) {
    super(checkoutPaymentTypeFacade, checkoutStepService, activatedRoute, globalMessageService);
  }

  ngAfterViewInit(): void {
    this.checkoutPaymentTypeFacade
      .getSavePaymentCard()
      .pipe(take(1))
      .subscribe((saved) => {
        this.saveCardFormControl.setValue(saved);
        this.cdr.detectChanges();
      });
  }

  getCardText(redsysCard: PaymentDetails): string {
    return [redsysCard.cardNumber, redsysCard.accountHolderName, [redsysCard.expiryMonth, redsysCard.expiryYear].join('/')].join(' | ');
  }

  override changeType(code: string): void {
    this.busy$.next(true);
    this.typeSelected = code;

    this.checkoutPaymentTypeFacade
      .setPaymentType(code)
      .pipe(take(1))
      .subscribe({
        complete: () => this.onSuccess(),
        error: () => this.onError(),
      });
  }

  setSavePaymentCard() {
    this.checkoutPaymentTypeFacade.setSavePaymentCard(this.saveCardFormControl.value);
  }

  setRedsysCard(card?: PaymentDetails) {
    this.busy$.next(true);

    if (card) {
      this.checkoutPaymentFacade
        .setPaymentDetails(card)
        .pipe(take(1))
        .subscribe({
          complete: () => this.onSuccess(),
          error: () => this.onError(),
        });
    } else {
    }
  }

  deleteRedsysCard(card: PaymentDetails) {
    if (card.id) {
      this.redsysService
        .deleteRedsysCard(card.id)
        .pipe(take(1))
        .subscribe({
          complete: () => this.onSuccess(),
          error: () => this.onError(),
        });
    }
  }

  protected override onSuccess(): void {
    super.onSuccess();
    this.eventService.dispatch({}, CheckoutQueryReloadEvent);
  }

  protected override onError(): void {
    super.onError();
    this.eventService.dispatch({}, CheckoutQueryReloadEvent);
  }
}
