import { Component } from '@angular/core';
import { OrderDetailActionsComponent, OrderDetailsService } from '@spartacus/order/components';
import { LaunchDialogService } from '@spartacus/storefront';
import { ORDER_DETAIL_CANCEL_DIALOG } from './order-detail-cancel-dialog/order-detail-cancel-layout.config';
import { ORDER_DETAIL_MODIFY_DIALOG } from './order-detail-modify-dialog/order-detail-modify-layout.config';
import { Order } from '@spartacus/order/root';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'cj-order-detail-actions',
  templateUrl: './order-detail-actions.component.html',
  styleUrl: './order-detail-actions.component.scss',
})
export class CjOrderDetailActionsComponent extends OrderDetailActionsComponent {

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    protected override orderDetailsService: OrderDetailsService,
    private readonly launchDialogService: LaunchDialogService,
  ) {
    super(orderDetailsService);
  }

  downloadInvoice(order: Order): void {
    this.loading$.next(true);
  }

  openModifyDialog(): void {
    this.launchDialogService.openDialogAndSubscribe(ORDER_DETAIL_MODIFY_DIALOG, undefined);
  }

  openCancelDialog(): void {
    this.launchDialogService.openDialogAndSubscribe(ORDER_DETAIL_CANCEL_DIALOG, undefined);
  }
}
