import { Component } from '@angular/core';
import { Country, Region, UserAddressService } from '@spartacus/core';
import { UpdateProfileComponent } from '@spartacus/user/profile/components';
import { BehaviorSubject, Observable, switchMap, tap } from 'rxjs';
import { CjUpdateProfileComponentService } from './update-profile-component.service';

@Component({
  selector: 'cj-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrl: './update-profile.component.scss',
})
export class CjUpdateProfileComponent extends UpdateProfileComponent {
  countries$?: Observable<Country[]>;
  regions$: Observable<Region[]> | undefined;
  selectedCountry$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  isButtonDisable: boolean = false;

  constructor(
    protected override service: CjUpdateProfileComponentService,
    protected userAddressService: UserAddressService,
  ) {
    super(service);
  }

  ngOnInit() {
    this.isDocumentIdentifierReadOnly();

    // Fetching countries
    this.countries$ = this.userAddressService.getDeliveryCountries().pipe(
      tap((countries: Country[]) => {
        if (Object.keys(countries).length === 0) {
          this.userAddressService.loadDeliveryCountries();
        }
      }),
    );

    // Fetching regions
    this.regions$ = this.selectedCountry$.pipe(
      switchMap((country) => this.userAddressService.getRegions(country)),
      tap((regions: Region[]) => {
        const regionControl = this.form
          .get('defaultAddress')
          ?.get('region.isocode');
        if (regions && regions.length > 0) {
          regionControl?.enable();
        } else {
          regionControl?.disable();
        }
      }),
    );
  }

  isDocumentIdentifierReadOnly() {
    const documentIdentifierControl = this.form.get('documentIdentifier');
    documentIdentifierControl?.valueChanges.subscribe(() => {
      if (
        documentIdentifierControl?.status === 'VALID' &&
        !documentIdentifierControl?.dirty
      ) {
        this.service.isDocumentIdentifierReadOnly = true;
      }
    });
  }

  countrySelected(country: Country | undefined): void {
    this.form
      .get('defaultAddress')
      ?.get('country')
      ?.get('isocode')
      ?.setValue(country?.isocode);
    this.selectedCountry$.next(country?.isocode ?? '');
  }

  regionSelected(region: Region): void {
    this.form
      .get('defaultAddress')
      ?.get('region')
      ?.get('isocode')
      ?.setValue(region.isocode);
  }

  override onSubmit(): void {
    this.service.newUpdateProfile(() => {
      this.isButtonDisable = true;
    });
  }

  ngOnDestroy(): void {
    // Desuscribirse para evitar fugas de memoria
    this.service.subscription.unsubscribe();
  }
}
