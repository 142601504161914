import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { Cart } from '@spartacus/cart/base/root';
import { CheckoutLoginComponent } from '@spartacus/checkout/base/components';
import { AuthService, OCC_USER_ID_ANONYMOUS } from '@spartacus/core';
import { CustomFormValidators } from '@spartacus/storefront';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'cj-checkout-login',
  templateUrl: './checkout-login.component.html',
})
export class CjCheckoutLoginComponent extends CheckoutLoginComponent {
  @Input() setEmail = true;
  @Output() private submitEmail: EventEmitter<string | undefined> = new EventEmitter();

  private readonly authService = inject(AuthService);
  protected loggedIn$: Observable<boolean> = this.authService.isUserLoggedIn();

  isGuest$ = this.activeCartFacade.getActive().pipe(
    map((cart: Cart) => {
      this.checkoutLoginForm.get('email')?.setValue(cart?.user?.uid?.split('|')[1] || '');
      return cart?.user?.uid !== OCC_USER_ID_ANONYMOUS;
    }),
  );

  override checkoutLoginForm: UntypedFormGroup = this.formBuilder.group({
    email: ['', { validators: [Validators.required, CustomFormValidators.emailValidator], updateOn: 'blur' }],
  });

  override onSubmit() {
    if (this.checkoutLoginForm.valid) {
      const email = this.checkoutLoginForm.get('email')?.value;

      if (this.setEmail) {
        this.activeCartFacade.addEmail(email);
      } else {
        this.submitEmail.emit(email);
      }
    } else {
      this.checkoutLoginForm.markAllAsTouched();
      this.submitEmail.emit(undefined);
    }
  }
}
