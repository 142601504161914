<div class="cx-modal-container cj-added-to-cart-dialog" (esc)="dismissModal('Escape pressed')">
  <div *ngIf="cart$ | async as cart" class="cx-modal-content">
    <!-- Modal Header -->
    <div class="cx-dialog-header modal-header">
      <p class="cx-dialog-title modal-title">{{ 'addToCart.title' | cxTranslate }}</p>
      <p class="cx-dialog-subtitle modal-subtitle">{{ 'addToCart.subtitle' | cxTranslate }}</p>
      <button
        type="button"
        class="close"
        attr.aria-label="{{ 'addToCart.closeModal' | cxTranslate }}"
        (click)="dismissModal('Cross click')"
      >
        <span aria-hidden="true">
          <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
        </span>
      </button>
    </div>

    <!-- Modal Body -->
    <div class="cx-dialog-body modal-body">
      <div *ngIf="cart.entries as entries">
        <cj-cart-item-list
          [items]="entries"
          [promotionLocation]="promotionLocation"
          [displayType]="cartItemDisplayTypes.MINI_CART"
        ></cj-cart-item-list>
      </div>
      <p class="cart-total row mx-0" *ngIf="cart.totalPrice?.formattedValue">
        <span class="col pl-0">{{ 'addToCart.total' | cxTranslate }}</span>
        <span class="col-auto pr-0">{{ cart.totalPrice?.formattedValue }}</span>
      </p>
      <a class="btn btn-block btn-primary" [routerLink]="{ cxRoute: 'checkout' } | cxUrl">
        {{ 'addToCart.checkoutButton' | cxTranslate }}
      </a>
      <a class="btn btn-block btn-outline-primary" [routerLink]="{ cxRoute: 'cart' } | cxUrl">
        {{ 'addToCart.cartButton' | cxTranslate }}
      </a>
    </div>
  </div>
</div>
