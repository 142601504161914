<div *ngIf="component.data$ | async as data" class="cj-product-parts-banner">
  <h2 *ngIf="data.title" class="cj-product-parts-banner__title">{{ data.title }}</h2>
  <div class="cj-product-parts-banner__container">
    <ng-container *ngIf="screenWidth">
      <ng-container *ngIf="data.desktopImage && data.mobileImage">
        <cx-media
          *ngIf="data.desktopImage"
          [container]="screenWidth >= breakpoints.SM ? data.desktopImage : data.mobileImage"
          class="cj-product-parts-banner__img"
        ></cx-media>
      </ng-container>

      <ng-container *ngIf="characteristics$ | async as characteristicsList">
        <ng-container *ngFor="let characteristic$ of characteristicsList; index as i">
          <div
            *ngIf="characteristic$ | async as characteristic"
            (click)="toggleCharacteristic(i)"
            class="cj-product-characteristic"
            [class.active]="activeCharacteristic === i"
            [style.left.%]="screenWidth >= breakpoints.SM ? characteristic?.desktopCoordinates?.x : characteristic?.mobileCoordinates?.x"
            [style.top.%]="screenWidth >= breakpoints.SM ? characteristic?.desktopCoordinates?.y : characteristic?.mobileCoordinates?.y"
          >
            <button class="cj-product-characteristic__button">
              <span class="icomoon icon-plus"></span>
            </button>
            <p class="cj-product-characteristic__description" *ngIf="characteristic.description">
              {{ characteristic.description }}
            </p>
            <p class="cj-product-characteristic__title" *ngIf="characteristic.firstTitle">
              {{ characteristic.firstTitle }}
            </p>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
