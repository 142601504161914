import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CommandService, StateWithUser, UserAddressConnector, UserAddressService, UserIdService } from '@spartacus/core';
import { CjUserActions } from '../store/actions';

@Injectable({
  providedIn: 'root',
})
export class CjUserAddressService extends UserAddressService {
  constructor(
    protected override store: Store<StateWithUser>,
    protected override userIdService: UserIdService,
    protected override userAddressConnector: UserAddressConnector,
    protected override command: CommandService,
  ) {
    super(store, userIdService, userAddressConnector, command);
  }

  /**
   * Retrieves delivery countries of the current base store.
   */
  loadBaseStoreDeliveryCountries(): void {
    this.store.dispatch(new CjUserActions.LoadBaseSiteDeliveryCountries());
  }
}
