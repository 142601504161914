import { NgModule } from '@angular/core';
import { ActiveCartService, MultiCartService } from '@spartacus/cart/base/core';
import { CjAddToCartModule } from './base/components/add-to-cart/add-to-cart.module';
import { CjAddedToCartDialogModule } from './base/components/added-to-cart-dialog/added-to-cart-dialog.module';
import { CjCartDetailsModule } from './base/components/cart-details/cart-details.module';
import { CjCartSharedModule } from './base/components/cart-shared/cart-shared.module';
import { CjCartTotalsModule } from './base/components/cart-totals/cart-totals.module';
import { CjPopupModule } from './base/components/popup/popup.module';
import { CjActiveCartService } from './base/core/facade/active-cart.service';
import { CjMultiCartService } from './base/core/facade/multi-cart.service';

@NgModule({
  imports: [CjAddedToCartDialogModule, CjAddToCartModule, CjCartDetailsModule, CjCartSharedModule, CjCartTotalsModule, CjPopupModule],
  providers: [
    { provide: MultiCartService, useClass: CjMultiCartService },
    { provide: ActiveCartService, useClass: CjActiveCartService },
  ],
})
export class CjCartBaseModule {}
