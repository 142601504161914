import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthStatePersistenceService } from '@spartacus/core';
import { CjAuthStatePersistenceService } from './services/auth-state-persistence.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: AuthStatePersistenceService,
      useExisting: CjAuthStatePersistenceService,
    },
  ],
})
export class CjUserAuthModule {}
