import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { IconModule, ItemCounterModule, OutletModule } from '@spartacus/storefront';
import { CjAddToCartComponent } from './add-to-cart.component';
import { CjItemCounterModule } from 'src/app/cms-components/shared/components/item-counter/item-counter.module';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, I18nModule, IconModule, ItemCounterModule, OutletModule, CjItemCounterModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        ProductAddToCartComponent: {
          component: () => import('./add-to-cart.component').then(() => CjAddToCartComponent),
        },
      },
    }),
  ],
  declarations: [CjAddToCartComponent],
  exports: [CjAddToCartComponent],
})
export class CjAddToCartModule {}
