import { Injectable } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { CjWebAvailabilityType } from 'src/app/cms-components/cart/base/models/web-availability.model';

@Injectable({
  providedIn: 'root',
})
export class CjStructuredDataUtilsService {
  constructor(private readonly winRef: WindowRef) {}

  get currentUrl(): string {
    if (this.winRef.isBrowser() && this.winRef.location.href) return this.winRef.location.href;
    return '';
  }

  get hostUrl(): string {
    if (this.winRef.isBrowser() && this.winRef.location.origin?.length) {
      const host = this.winRef.location.origin;
      host.slice(0, -1);
      return host;
    }
    return '';
  }

  get siteName(): string {
    return 'Cinco Jotas';
  }

  get socialNetworkUrls(): string[] {
    return [
      'https://www.facebook.com/CincoJotas',
      'https://twitter.com/cincojotas',
      'https://www.youtube.com/user/5JotasVideo',
      'https://www.instagram.com/cincojotas/',
    ];
  }

  parseAvailability(status: string) {
    switch (status) {
      case CjWebAvailabilityType.AVAILABLE:
        return 'https://schema.org/InStock';
      case CjWebAvailabilityType.LAST_UNITS:
        return 'https://schema.org/LimitedAvailability';
      case CjWebAvailabilityType.NOT_AVAILABLE:
        return 'https://schema.org/OutOfStock';
      case CjWebAvailabilityType.ONLINE_ONLY:
        return 'https://schema.org/OnlineOnly';
      case CjWebAvailabilityType.TEMPORARILY_OUT_OF_STOCK:
        return 'https://schema.org/OutOfStock';
      default:
        return undefined;
    }
  }
}
