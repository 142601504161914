export const checkout = {
  checkout: {
    securePurchase: 'Secure purchase',
  },
  checkoutAddress: {
    addNewAddress: 'Add new delivery address',
    viewAddresses: 'Go to my addresses',
    defaultDeliveryAddressTitle: 'Address {{count}}',
    deliveryAddressSelected: 'Delivery address selected',
  },
  checkoutGift: {
    giftWithMessage: 'Gift with a custom message',
    giftMessagePlaceholder: 'Message for gift',
  },
  checkoutPayment: {
    paymentType: {
      CreditCard: 'Payment by credit card',
      Bizum: 'Payment with Bizum',
      PayPal: 'Payment with PayPal',
    },
    newCard: 'New card',
    saveCard: 'Save this card for future purchases',
    confirmThatRead: 'By placing your order you accept our',
    termsAndConditions: 'Privacy policy',
    placeOrder: 'Place an order',
  },
  paypal: {
    error: {
      default: 'There has been an error with PayPal. Please try again later.',
      detail: 'Sorry, your transaction could not be processed. {{msg}}',
      cancel: 'Payment cancelled.',
    },
  },
  checkoutOrderConfirmation: {
    thankYou: 'Thank you very much for <br>trusting Cinco Jotas, <br><span>{{name}}</span>.',
    invoiceArriveTime: 'In 24-48 business hours you will receive your order.',
    invoiceCancelTime: 'You have a margin of 30 minutes if you want to cancel the order',
    orderNumber: 'Order #: {{code}}',
    orderItems: 'My order',
    createYourAccount: 'Create your 5J account',
    createAccountRequirements: {
      requirements: 'You only need a password to:',
      followOrder: 'Track your order.',
      downloadInvoices: 'Download invoices.',
      buyQuicker: 'Buy faster.',
    },
    wantToCreateAccount: 'I want to create my Cinco Jotas account',
    createAccount: 'Create 5J account',
    createAccountForNext: 'Create an account with <b>{{email}}</b> for faster checkout on your next visit.',
    continueBuying: 'Continue buying',
    orderDetails: 'Order details',
  },
};
