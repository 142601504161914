import { Injectable } from '@angular/core';
import { ActiveCartFacade, Cart, MultiCartFacade } from '@spartacus/cart/base/root';
import { Command, CommandService, CommandStrategy, UserIdService } from '@spartacus/core';
import { Observable, combineLatest, map, switchMap, take } from 'rxjs';
import { CjReorderOrderConnector } from '../connectors/reorder-order.connector';

@Injectable()
export class CjReorderOrderService {
  protected reorderCommand: Command<{ orderId: string }, Cart> = this.commandService.create<{ orderId: string }, Cart>(
    ({ orderId }) => this.reorderPreconditions().pipe(switchMap((userId: string) => this.reorderOrderConnector.reorder(orderId, userId))),
    {
      strategy: CommandStrategy.CancelPrevious,
    },
  );

  constructor(
    private readonly commandService: CommandService,
    private readonly reorderOrderConnector: CjReorderOrderConnector,
    private readonly userIdService: UserIdService,
    private readonly activeCartFacade: ActiveCartFacade,
    private readonly multiCartFacade: MultiCartFacade,
  ) {}

  /**
   * Create cart from an existing order
   */
  reorder(orderId: string): Observable<Cart> {
    return this.reorderCommand.execute({ orderId });
  }

  protected reorderPreconditions(): Observable<string> {
    return combineLatest([this.userIdService.takeUserId(), this.activeCartFacade.getActiveCartId()]).pipe(
      take(1),
      map(([userId, cartId]) => {
        if (!userId) {
          throw new Error('Must be logged in to reorder');
        }

        if (cartId) {
          this.multiCartFacade.deleteCart(cartId, userId);
        }

        return userId;
      }),
    );
  }
}
