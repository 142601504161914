<ng-container *ngIf="(isUpdating$ | async) === false && (supportedDeliveryModes$ | async) as supportedDeliveryModes; else loading">
  <div role="status" [attr.aria-label]="'common.loaded' | cxTranslate"></div>
  <div [formGroup]="mode" class="cj-delivery-mode">
    <div role="radiogroup" class="row">
      <div class="col-6" *ngFor="let mode of supportedDeliveryModes" [class.selected]="mode.code === (selectedDeliveryModeCode$ | async)">
        <div class="form-check">
          <input
            [attr.aria-checked]="getAriaChecked(mode.code)"
            class="form-check-input"
            role="radio"
            type="radio"
            id="deliveryMode-{{ mode.code }}"
            (change)="changeMode(mode.code)"
            [value]="mode.code"
            formControlName="deliveryModeId"
          />
          <label class="cx-delivery-label form-check-label form-radio-label" for="deliveryMode-{{ mode.code }}">
            <div class="cj-delivery-mode-name">{{ mode.name }}</div>
            <div class="cj-delivery-mode-description">{{ mode.description }}</div>
            <div class="cj-delivery-price">
              {{ mode.deliveryCost?.value === 0 ? ('common.free' | cxTranslate | uppercase) : mode.deliveryCost?.formattedValue }}
            </div>
          </label>
        </div>
      </div>
    </div>
    <ng-template
      [cxOutlet]="CartOutlets.DELIVERY_MODE"
      [cxOutletContext]="{
        item: activeCartFacade.getActive() | async
      }"
    >
    </ng-template>
  </div>
</ng-container>

<ng-container *ngIf="activeCartFacade.hasPickupItems() | async">
  <h2 class="cx-checkout-title d-none d-lg-block d-xl-block">
    {{ 'checkoutMode.deliveryEntries' | cxTranslate }}
  </h2>

  <ng-template
    [cxOutlet]="CartOutlets.CART_ITEM_LIST"
    [cxOutletContext]="{
      items: activeCartFacade.getDeliveryEntries() | async,
      readonly: true
    }"
  >
  </ng-template>
</ng-container>

<cj-gift-card-form #giftCardForm (submitGiftCard)="giftCardSubmit($event)"></cj-gift-card-form>

<ng-template #loading>
  <div class="cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>

<ng-container cxInnerComponentsHost></ng-container>

<div class="row cx-checkout-btns">
  <div class="col-12">
    <button class="btn btn-block btn-primary" [disabled]="deliveryModeInvalid || (isSetDeliveryModeHttpError$ | async)" (click)="next()">
      {{ 'common.continue' | cxTranslate }}
    </button>
  </div>
</div>
