import { Component } from '@angular/core';
import { CmsVideoComponent } from '@spartacus/core';
import { CmsComponentData, LaunchDialogService } from '@spartacus/storefront';
import { CjCmsUtilsService } from 'src/app/shared/utils/cms-utils.service';
import { CjCmsMediaService } from 'src/app/shared/utils/media-utils.service';
import { VIDEO_DIALOG } from '../product-video-dialog/product-video-dialog.config';
import { CjCmsProductCutterBannerComponent } from './product-cutter-banner.model';

@Component({
  selector: 'cj-product-cutter-banner',
  templateUrl: './product-cutter-banner.component.html',
  styleUrls: ['./product-cutter-banner.component.scss'],
})
export class CjProductCutterBannerComponent {
  constructor(
    protected component: CmsComponentData<CjCmsProductCutterBannerComponent>,
    protected cmsUtils: CjCmsUtilsService,
    protected readonly launchDialogService: LaunchDialogService,
    protected cjCmsMediaService: CjCmsMediaService,
  ) {}

  openDialog(data: CmsVideoComponent | undefined): void {
    if (data) {
      this.launchDialogService.openDialogAndSubscribe(VIDEO_DIALOG, undefined, data);
    }
  }
}
