<cx-spinner class="overlay" *ngIf="isUpdating$ | async"></cx-spinner>
<div class="cj-login-form">
  <h3 class="cj-login-form__title">{{ 'loginForm.title' | cxTranslate }}</h3>
  <form (ngSubmit)="onSubmit()" [formGroup]="form">
    <label>
      <span class="label-content">{{ 'loginForm.emailAddress.label' | cxTranslate }}</span>
      <input
        required="true"
        type="email"
        class="form-control"
        formControlName="userId"
        placeholder="{{ 'loginForm.emailAddress.placeholder' | cxTranslate }}"
      />
      <cx-form-errors [control]="form.get('userId')"></cx-form-errors>
    </label>

    <label>
      <span class="label-content">{{ 'loginForm.password.label' | cxTranslate }}</span>
      <input
        required="true"
        type="password"
        class="form-control"
        placeholder="{{ 'loginForm.password.placeholder' | cxTranslate }}"
        formControlName="password"
        [attr.aria-label]="'loginForm.password.placeholder' | cxTranslate"
        cxPasswordVisibilitySwitch
      />
      <cx-form-errors [control]="form.get('password')"></cx-form-errors>
    </label>

    <a [routerLink]="{ cxRoute: 'forgotPassword' } | cxUrl" class="btn-link cj-login-form__btn-forgot">
      {{ 'loginForm.forgotPassword' | cxTranslate }}
    </a>

    <button type="submit" class="btn btn-block btn-primary cj-login-form__btn-login" [disabled]="form.disabled">
      {{ 'loginForm.signIn' | cxTranslate }}
    </button>
  </form>
</div>
