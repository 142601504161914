<ng-container *ngIf="component.data$ | async as data">
  <div
    *ngIf="data.productCard"
    class="product-two-columns__wrapper"
    [class]="data.position"
  >
    <cj-product-card
      *ngIf="data.productCard.product"
      class="product-two-columns__card"
      [code]="data.productCard.product"
      [displayType]="displayTypes.OPTION_THREE"
      [artistic]="false"
    ></cj-product-card>
    <cx-media
      *ngIf="data.media"
      class="product-two-columns__media cj-media--parallax"
      [container]="data.media"
      gsapAnimation
      [animationType]="'parallaxVertical'"
    ></cx-media>
  </div>
</ng-container>
