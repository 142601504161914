<div *ngIf="componentData.data$ | async as data" class="cj-product-vertical-banner">
  <h4 *ngIf="data.title" class="cj-product-vertical-banner__title">
    {{ data.title }}
  </h4>
  <p *ngIf="data.longDescription" class="cj-product-vertical-banner__text">
    {{ data.longDescription }}
  </p>
  <button *ngIf="data.bottomText && data.modal" (click)="openDialog(data.modal)" class="btn btn-link cj-product-vertical-banner__link">
    {{ data.bottomText }}
  </button>
</div>
