import { Component } from '@angular/core';
import { CmsComponentData, LaunchDialogService } from '@spartacus/storefront';
import { CjCmsOsborneModalComponent } from 'src/app/shared/models/modal.model';
import { CjCmsOsborneVerticalBannerComponent } from '../product-list-banner.model';
import { VERTICAL_BANNER_DIALOG } from './product-vertical-dialog/product-vertical-dialog.config';

@Component({
  selector: 'cj-product-vertical-banner',
  templateUrl: './product-vertical-banner.component.html',
  styleUrls: ['./product-vertical-banner.component.scss'],
})
export class CjProductVerticalBannerComponent {
  constructor(
    protected componentData: CmsComponentData<CjCmsOsborneVerticalBannerComponent>,
    protected launchDialogService: LaunchDialogService,
  ) {}

  openDialog(data: CjCmsOsborneModalComponent | undefined): void {
    if (data) {
      this.launchDialogService.openDialogAndSubscribe(VERTICAL_BANNER_DIALOG, undefined, data);
    }
  }
}
