import { Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { CjCmsProductMultimediaBannerComponent } from './product-multimedia-banner.model';

@Component({
  selector: 'cj-product-multimedia-banner',
  templateUrl: './product-multimedia-banner.component.html',
  styleUrls: ['./product-multimedia-banner.component.scss'],
})
export class CjProductMultimediaBannerComponent {
  constructor(protected component: CmsComponentData<CjCmsProductMultimediaBannerComponent>) {}
}
