import { Component } from '@angular/core';
import { CmsVideoComponent } from '@spartacus/core';
import { CmsComponentData, LaunchDialogService } from '@spartacus/storefront';
import { VIDEO_DIALOG } from '../product-video-dialog/product-video-dialog.config';
import { CjCmsVideoComponent } from './product-video.model';

@Component({
  selector: 'cj-product-video',
  templateUrl: './product-video.component.html',
  styleUrls: ['./product-video.component.scss'],
})
export class CjProductVideoComponent {
  constructor(
    protected component: CmsComponentData<CjCmsVideoComponent>,
    protected readonly launchDialogService: LaunchDialogService,
  ) {}

  openDialog(data: CmsVideoComponent | undefined): void {
    if (data) {
      this.launchDialogService.openDialogAndSubscribe(VIDEO_DIALOG, undefined, data);
    }
  }
}
