export const cart = {
  cartDetails: {
    title: 'My selection',
    continueShopping: 'Continue shopping',
    proceedToCheckout: 'Finish order',
  },
  validation: {
    cartEntriesChangeDuringCheckout: 'During checkout we found problems with your entries. Please review your cart.',
    cartEntryRemoved: '{{name}} was removed from the cart due to being out of stock.',
    productOutOfStock: '{{name}} has been removed from the cart due to insufficient stock.',
    lowStock: 'Quantity has reduced to {{quantity}} due to insufficient stock.',
    inProgress: 'Processing',
  },
  orderCost: {
    orderSummary: 'Order summary',
    subtotal: 'Subtotal',
    shipping: 'Shipping',
    estimatedShipping: 'Shipping',
    discount: 'Discount',
    salesTax: 'Sales tax',
    grossTax: 'VAT (not included)',
    grossIncludeTax: 'VAT (included)',
    taxMessage:
      'Tax is estimated at checkout is subject to slight change. The final tax (if applicable in your state) is shown in the Order Summary.',
    total: 'Total',
    toBeDetermined: 'To be determined',
  },
  voucher: {
    coupon: 'Coupon code',
    coupon_other: 'Coupon codes',
    couponLabel: 'Enter a promo code here',
    apply: 'Add',
    placeholder: 'Promo code',
    applyVoucherSuccess: '{{voucherCode}} has been applied.',
    removeVoucherSuccess: '{{voucherCode}} has been removed.',
    anchorLabel: 'Enter or remove your coupon code',
    vouchersApplied: 'Applied coupons',
    availableCoupons: 'Available coupons',
    availableCouponsLabel: 'You can add these coupons to this order.',
  },
};
