import { Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { CjCmsProductSimpleMultimediaBannerComponent } from './product-simple-multimedia-banner.model';

@Component({
  selector: 'cj-product-simple-multimedia-banner',
  templateUrl: './product-simple-multimedia-banner.component.html',
  styleUrls: ['./product-simple-multimedia-banner.component.scss'],
})
export class CjProductSimpleMultimediaBannerComponent {
  constructor(protected component: CmsComponentData<CjCmsProductSimpleMultimediaBannerComponent>) {}
}
