<div *ngIf="component.data$ | async as data" class="cj-product-comparative-banner">
  <h2 class="cj-product-comparative-banner__title" *ngIf="data.title">{{ data.title }}</h2>
  <ng-container
    *ngIf="data.description as description"
    [cxComponentWrapper]="{
      flexType: description.typeCode,
      typeCode: description.typeCode,
      uid: description.uid
    }"
  >
  </ng-container>

  <!-- TODO la imagen izquierda y la imagen derecha deben ser la misma -->
  <img-comparison-slider *ngIf="data.imageOsborne && data.imageOther">
    <!-- slot="first" imagen izquierda -->
    <cx-media slot="first" *ngIf="data.imageOsborne as imageOsborne" [container]="imageOsborne"></cx-media>
    <!-- slot="first" imagen derecha -->
    <cx-media slot="second" *ngIf="data.imageOther as imageOther" [container]="imageOther"></cx-media>
    <!-- slot="handle" para añadir icono -->
    <div slot="handle" class="cj-product-comparative-banner__handle"></div>
  </img-comparison-slider>
</div>
