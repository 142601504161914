import { Injectable } from '@angular/core';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { CheckoutPageMetaResolver } from '@spartacus/checkout/base/core';
import { BasePageMetaResolver, TranslationService } from '@spartacus/core';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CjCheckoutPageMetaResolver extends CheckoutPageMetaResolver {
  constructor(
    protected override translationService: TranslationService,
    protected override activeCartFacade: ActiveCartFacade,
    protected override basePageMetaResolver: BasePageMetaResolver,
  ) {
    super(translationService, activeCartFacade, basePageMetaResolver);
  }

  override resolveHeading(): Observable<string> {
    return this.basePageMetaResolver.resolveTitle().pipe(map((title) => title || ''));
  }
}
