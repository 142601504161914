import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CjCmsUtilsService } from 'src/app/shared/utils/cms-utils.service';
import { CjCmsIconsComponent, CjModalItemComponent } from './icons.model';

@Component({
  selector: 'cj-icons',
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjIconsComponent {
  icons$: Observable<Observable<CjModalItemComponent>[]> = this.component.data$.pipe(
    map((data) => data?.icons),
    filter((icons) => !!icons),
    map((icons) => {
      if (!icons) {
        return [];
      }
      return this.cmsUtils.getComponentDataList<CjModalItemComponent>(icons);
    }),
  );

  constructor(
    readonly component: CmsComponentData<CjCmsIconsComponent>,
    private readonly cmsUtils: CjCmsUtilsService,
  ) {}
}
