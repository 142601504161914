import { CmsComponent, CmsParagraphComponent } from '@spartacus/core';

export interface CjCmsContactFormComponent extends CmsComponent {
  title?: string;
  content?: CmsParagraphComponent;
  description?: string;
  templateConfigurations?: string;
}

export enum CsTicketConfiguration {
  DEFAULT = 'DEFAULT',
  WITH_ORDER_CODE = 'WITH_ORDER_CODE',
  RETURN_FOR_QUALITY = 'RETURN_FOR_QUALITY',
  RETURN_FOR_WITHDRAWAL = 'RETURN_FOR_WITHDRAWAL',
}

export enum CsTicketQualityProblem {
  EXCESS_FAT = 'EXCESS_FAT',
  SMELL_FLAVOR_COLOR_PROBLEMS = 'SMELL_FLAVOR_COLOR_PROBLEMS',
  VACUUM_LOSS = 'VACUUM_LOSS',
  EXCESS_LACK_CURING = 'EXCESS_LACK_CURING',
  OTHER = 'OTHER',
}

export interface CsTicketTemplate {
  code?: string;
  name?: string;
  configuration?: CsTicketConfiguration;
}

export interface CsTicketCause {
  code?: string;
  name?: string;
  templates?: CsTicketTemplate[];
}

export interface CsTicketCauseList {
  causes?: CsTicketCause[];
}

export interface CsTicketContactForm {
  email: string;
  message: string;
  templateCode: string;
  subject?: string;
  orderCode?: string;
  accept?: boolean;
  causeCode?: string;
  requiredAction?: string;
  qualityProblem?: string;
  productsAffected?: string;
}
