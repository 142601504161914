import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CmsLinkComponent } from '@spartacus/core';

@Component({
  selector: 'cj-menu-non-collapsable',
  templateUrl: './menu-non-collapsable.component.html',
  styleUrls: ['./menu-non-collapsable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjMenuNonCollapsableComponent {
  @Input() data?: CmsLinkComponent | null;
}
