import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { CjProductCardDisplayType } from '../../product/card/product-card.model';
import { CjCmsProductBanner } from './product-banner.model';

@Component({
  selector: 'cj-product-banner',
  templateUrl: './product-banner.component.html',
  styleUrls: ['./product-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjProductBannerComponent {
  displayTypes = CjProductCardDisplayType;
  constructor(protected component: CmsComponentData<CjCmsProductBanner>) {}
}
