import { Injectable, inject } from '@angular/core';
import { PageMeta } from '@spartacus/core';
import { BreadcrumbSchemaBuilder } from '@spartacus/storefront';
import { CjStructuredDataUtilsService } from '../../structured-data-utils-service';

@Injectable({
  providedIn: 'root',
})
export class CjBreadcrumbSchemaBuilder extends BreadcrumbSchemaBuilder {
  private readonly utils: CjStructuredDataUtilsService = inject(CjStructuredDataUtilsService);

  protected override collect(pageMeta: PageMeta | null): any {
    if (!pageMeta?.breadcrumbs) {
      return;
    }

    const crumbs = pageMeta.breadcrumbs.map((crumb, index) => {
      return {
        '@type': 'ListItem',
        position: index + 1,
        item: {
          '@id': this.utils.hostUrl + crumb.link,
          name: crumb.label,
        },
      };
    });

    return {
      '@type': 'BreadcrumbList',
      itemListElement: crumbs,
    };
  }
}
