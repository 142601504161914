import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CjCmsUtilsService } from 'src/app/shared/utils/cms-utils.service';
import { CjCmsProductCharacteristicsComponent } from './product-characteristics.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'cj-product-characteristics',
  templateUrl: './product-characteristics.component.html',
  styleUrls: ['./product-characteristics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjProductCharacteristicsComponent {
  private characteristicsItemId$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  characteristics$?: Observable<Observable<CjCmsProductCharacteristicsComponent>[]>;

  @Input() set characteristics(value: string) {
    if (value) {
      this.characteristicsItemId$.next(value);
    }
  }

  constructor(protected cmsUtils: CjCmsUtilsService) {
    this.characteristics$ = this.characteristicsItemId$.pipe(
      filter((uids) => !!uids),
      map((uid) => {
        if (!uid) {
          return [];
        }
        return this.cmsUtils.getComponentDataList<CjCmsProductCharacteristicsComponent>(uid);
      }),
    );
  }

  // getCharacteristics() {
  //   if (this.characteristics) {
  //     return this.cmsUtils.getComponentDataList<CjCmsProductCharacteristicsComponent>(
  //       this.characteristics,
  //     );
  //   }
  //   return [];
  // }
}
