<ng-container *ngIf="user$ | async as user; else login">
  <div class="cx-login-greet">
    <span class="initials">
      <a role="link" [routerLink]="{ cxRoute: 'orders' } | cxUrl">
        {{ getInitials(user) }}
      </a>
    </span>
    <span class="name">
      <a role="link" [routerLink]="{ cxRoute: 'orders' } | cxUrl">
        {{ user.name }}
      </a>
    </span>
  </div>
</ng-container>

<ng-template #login>
  <a role="link" [routerLink]="{ cxRoute: 'login' } | cxUrl">
    <i class="icomoon icon-user"></i>
    <span class="name">{{ 'miniLogin.signInRegister' | cxTranslate }}</span>
  </a>
</ng-template>
