import { Injectable } from '@angular/core';
import { Address, UserAddressConnector } from '@spartacus/core';
import { Observable } from 'rxjs';
import { CjUserAddressAdapter } from './user-address.adapter';

@Injectable({
  providedIn: 'root',
})
export class CjUserAddressConnector extends UserAddressConnector {
  constructor(protected override adapter: CjUserAddressAdapter) {
    super(adapter);
  }

  getAllFromBasestore(userId: string, addressId: string, address: Address): Observable<{}> {
    return this.adapter.updateDefault(userId, addressId, address);
  }
}
