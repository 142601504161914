import { Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { OsborneFooterLogoComponent } from '../footer-navigation.model';

@Component({
  selector: 'cj-footer-logo',
  templateUrl: './footer-logo.component.html',
  styleUrls: ['./footer-logo.component.scss'],
})
export class CjFooterLogoComponent {
  constructor(protected componentData: CmsComponentData<OsborneFooterLogoComponent>) {}
}
