<ng-container *ngIf="componentData.data$ | async as data">
  <h3 class="cj-footer-learn__title" *ngIf="data.title">{{ data.title }}</h3>
  <p class="cj-footer-learn__text" *ngIf="data.text">{{ data.text }}</p>
  <button
    *ngIf="(showNewsletter$ | async) === false && data.newsletterText"
    class="btn btn-link cj-footer-learn__btn"
    (click)="openNewsletter()"
  >
    {{ data?.newsletterText }}
  </button>
  <ng-container
    *ngIf="showNewsletter$ | async"
    [cxComponentWrapper]="{
      flexType: data.newsletterComponent.typeCode,
      typeCode: data.newsletterComponent.typeCode,
      uid: data.newsletterComponent.uid
    }"
  >
  </ng-container>
  <ul *ngIf="icons$ | async as icons" class="social-list list-unstyled mb-0 d-flex">
    <li *ngFor="let icon$ of icons" class="mr-3">
      <cx-generic-link *ngIf="icon$ | async as icon" [url]="icon.urlLink!">
        <cx-media [container]="icon?.media"></cx-media>
      </cx-generic-link>
    </li>
  </ul>
</ng-container>
