import { LayoutConfig } from '@spartacus/storefront';

export const productDetailsSlots = {
  lg: {
    pageFold: 'Summary',
  },
  pageFold: 'Summary',
  slots: [
    'Summary',
    'Section1',
    'Section2',
    'Section3',
    'Section4',
    'Section5',
    'Section6',
    'Section7',
    'Section8',
    'Section9',
    'Section10',
    'Section11',
    'UpSelling',
    'CrossSelling',
    'Section12',
    'Section13',
    'Section14',
    'Tabs',
    'PlaceholderContentSlot',
  ],
};

export const cjLayoutConfig: LayoutConfig = {
  layoutSlots: {
    header: {
      lg: {
        slots: ['PreHeader', 'SiteLogo', 'HeaderLinks', 'MiniCart'],
      },
      slots: ['PreHeader', 'SiteLogo', 'HeaderLinks', 'MiniCart'],
    },
    navigation: {
      lg: {
        slots: ['NavigationBar', 'SiteContext'],
      },
      slots: ['NavigationBar', 'SiteContext', 'LogInAccountSlot'],
    },
    footer: {
      slots: ['Footer'],
    },
    LandingPage2Template: {
      lg: {
        pageFold: 'Section3',
        slots: ['Section1', 'Section2', 'Section3', 'Section4', 'Section5', 'Section6', 'Section7', 'Section8', 'Section9'],
      },
      pageFold: 'Section3',
      slots: ['Section1', 'Section2', 'Section3', 'Section4', 'Section5', 'Section6', 'Section7', 'Section8', 'Section9'],
    },
    OsbornePLPTemplate: {
      pageFold: 'Section3',
      slots: ['Section1', 'Section2', 'Section3', 'Section4', 'Section5'],
    },
    ProductDetailsPageTemplate: productDetailsSlots,
    ProductDetailsJamonPageTemplate: productDetailsSlots,
    ProductDetailsPresaPageTemplate: productDetailsSlots,
    ProductDetailsAccesoriosPageTemplate: productDetailsSlots,
    ProductDetailsPaletaPageTemplate: productDetailsSlots,
    ProductDetailsLomoPageTemplate: productDetailsSlots,
    ProductDetailsSalchichonPageTemplate: productDetailsSlots,
    ProductDetailsMorconPageTemplate: productDetailsSlots,
    ProductDetailsLoncheadosPageTemplate: productDetailsSlots,
    CartPageTemplate: {
      pageFold: 'CenterRightContentSlot',
      slots: ['TopContent', 'CenterLeftContentSlot', 'CenterRightContentSlot', 'EmptyCartMiddleContent', 'BottomContentSlot'],
    },
    OrderConfirmationPageTemplate: {
      pageFold: 'SideContent',
      slots: ['TopContent', 'BodyContent', 'SideContent', 'BottomContent'],
    },
    AccountPageTemplate: {
      pageFold: 'SideContent',
      slots: ['TopContent', 'BodyContent', 'SideContent', 'BottomContentSlot'],
    },
  },
};
