<div class="cj-order-summary" *ngIf="cart">
  <div class="cx-summary-heading">
    {{ 'orderCost.orderSummary' | cxTranslate }}
  </div>

  <div *ngIf="(isCartPage$ | async) === false && showItems && cart.entries as entries" class="cx-summary-items">
    <cj-cart-item-list
      [items]="entries"
      [promotionLocation]="promotionLocation$ | async"
      [displayType]="cartItemDisplayTypes.ORDER_SUMMARY"
    ></cj-cart-item-list>
  </div>

  <div class="cx-summary-partials">
    <!-- Subtotal -->
    <div *ngIf="cart.subTotal?.formattedValue" class="cx-summary-row">
      <div class="col-6 cx-summary-label">
        {{ 'orderCost.subtotal' | cxTranslate }}
      </div>
      <div class="col-6 cx-summary-amount">
        {{ cart.subTotal?.formattedValue }}
      </div>
    </div>
    <!-- Discount -->
    <div class="cx-summary-row cx-summary-discount" *ngIf="cart.totalDiscounts?.value || 0 > 0">
      <div class="col-6 cx-summary-label">
        {{ 'orderCost.discount' | cxTranslate }}
      </div>
      <div class="col-6 cx-summary-amount">
        {{ cart.totalDiscounts?.formattedValue }}
      </div>
    </div>
    <!-- Delivery -->
    <div class="cx-summary-row">
      <div class="col-6 cx-summary-label">
        {{ (cart.deliveryCost?.formattedValue ? 'orderCost.shipping' : 'orderCost.estimatedShipping') | cxTranslate }}
      </div>
      <div class="col-6 cx-summary-amount">
        {{ cart.deliveryCost?.formattedValue ? cart.deliveryCost?.formattedValue : ('orderCost.toBeDetermined' | cxTranslate) }}
      </div>
    </div>
    <!-- Tax -->
    <div *ngIf="cart.totalTax?.formattedValue" class="cx-summary-row">
      <div class="col-6 cx-summary-label">
        {{
          cart.totalPriceWithTax?.value !== cart.totalPrice?.value
            ? ('orderCost.grossTax' | cxTranslate)
            : ('orderCost.grossIncludeTax' | cxTranslate)
        }}
        <span #vatPopupButton class="icomoon icon-info" (click)="openVatPopup()"></span>
      </div>
      <div class="col-6 cx-summary-amount">
        {{ cart.totalTax?.formattedValue }}
      </div>
    </div>
    <!-- Total -->
    <div *ngIf="cart.totalPriceWithTax?.formattedValue" class="cx-summary-row cx-summary-total">
      <div class="col-6 cx-summary-label">
        {{ 'orderCost.total' | cxTranslate }}
      </div>
      <div class="col-6 cx-summary-amount">
        {{ cart.totalPriceWithTax?.formattedValue }}
      </div>
    </div>
  </div>

  <cx-applied-coupons *ngIf="cart.appliedVouchers as vouchers" [vouchers]="vouchers" [isReadOnly]="true"></cx-applied-coupons>
</div>
