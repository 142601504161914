import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CmsComponentData, LaunchDialogService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CjCmsOsborneModalComponent } from 'src/app/shared/models/modal.model';
import { CjCmsUtilsService } from 'src/app/shared/utils/cms-utils.service';
import { DELIVERY_BANNER_DIALOG } from './delivery-banner-dialog/delivery-banner-dialog.config';
import { CjCmsDeliveryBannerComponent, CjCmsDeliveryItemComponent } from './delivery-banner.model';

@Component({
  selector: 'cj-delivery-banner',
  templateUrl: './delivery-banner.component.html',
  styleUrls: ['./delivery-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjDeliveryBannerComponent {
  cards$: Observable<Observable<CjCmsDeliveryItemComponent>[]> = this.component.data$.pipe(
    map((data) => data.cards),
    filter((cards) => !!cards),
    map((cards) => {
      if (!cards) {
        return [];
      }
      return this.cmsUtils.getComponentDataList<CjCmsDeliveryItemComponent>(cards);
    }),
  );

  constructor(
    protected component: CmsComponentData<CjCmsDeliveryBannerComponent>,
    protected readonly cmsUtils: CjCmsUtilsService,
    private readonly launchDialogService: LaunchDialogService,
  ) {}

  openDialog(data: CjCmsOsborneModalComponent | undefined): void {
    if (data) {
      this.launchDialogService.openDialogAndSubscribe(DELIVERY_BANNER_DIALOG, undefined, data);
    }
  }
}
