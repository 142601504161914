<div class="cj-newsletter" *ngIf="componentData.data$ | async as data">
  <div
    *ngIf="medias$ | async as medias"
    class="cj-newsletter__img"
    gsapAnimation
    [animationType]="'scrollHorizontal'"
  >
    <ng-container *ngFor="let media$ of medias">
      <ng-container
        *ngIf="media$ | async as media"
        [cxComponentWrapper]="{
          flexType: media.typeCode,
          typeCode: media.typeCode,
          uid: media.uid
        }"
      >
      </ng-container>
    </ng-container>
  </div>
  <div class="cj-newsletter__info">
    <h2 *ngIf="data.title" class="cj-newsletter__tit">{{ data.title }}</h2>

    <form [formGroup]="newsletterForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label>
          <span class="label-content">{{ data?.text }}</span>
          <input
            class="form-control"
            type="email"
            formControlName="email"
            placeholder="{{ 'newsletter.emailPlaceholder' | cxTranslate }}"
            required
          />
          <button class="btn btn-secondary" type="submit">
            {{ 'common.send' | cxTranslate }}
          </button>
          <cx-form-errors
            [control]="newsletterForm.get('email')"
          ></cx-form-errors>
        </label>
      </div>
      <ng-container *ngIf="newsletterSuccess$ | async; else formCheck">
        <p class="success">{{ 'newsletter.success' | cxTranslate }}</p>
      </ng-container>
      <ng-template #formCheck>
        <div class="form-check" *ngIf="template$ | async as template">
          <input
            class="form-check-input"
            type="checkbox"
            formControlName="acceptTerms"
          />
          <label class="form-check-label" for="acceptTerms">
            {{ 'newsletter.acceptThe' | cxTranslate }}
            <a [routerLink]="{ cxRoute: 'termsAndConditions' } | cxUrl">
              {{ 'newsletter.termsAndConditions' | cxTranslate }}
            </a>
          </label>
          <cx-form-errors
            [control]="newsletterForm.get('acceptTerms')"
          ></cx-form-errors>
        </div>
      </ng-template>
    </form>
  </div>
</div>
