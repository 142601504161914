import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalMessageService, GlobalMessageType, OccEndpointsService } from '@spartacus/core';
import { Media } from '@spartacus/storefront';
import { Observable, catchError, map, of } from 'rxjs';
import { CjMedia } from 'src/app/shared/components/media/media.model';

@Injectable({
  providedIn: 'root'
})
export class CjOrderDetailTicketDownloadService {

  constructor(
    protected http: HttpClient,
    protected occEndpointsService: OccEndpointsService,
    private globalMessageService: GlobalMessageService,
  ) { }

  /**
     * Retrieve order ticket download url
     * @param code order id
     * @param userId user id
     * @returns string with download url
     */
  getOrderTicketDownloadUrl(
    userId: string,
    code?: string,
  ): Observable<string> {
    const endpointUrl = this.occEndpointsService.buildUrl('orderTicket', {
      urlParams: { userId, code }
    });
    return this.http.get<CjMedia>(endpointUrl).pipe(
      map((response) => {
        if (!response.downloadUrl) {
          return '';
        }
        return this.occEndpointsService.getBaseUrl({
          baseUrl: true,
          prefix: false,
          baseSite: false,
        }) + response.downloadUrl;
      }),
      catchError((error) => {
        this.globalMessageService.add(
          error?.error?.errors[0]?.message ?? error.name,
          GlobalMessageType.MSG_TYPE_ERROR
        );
        return of('');
      }),
    );
  }
}
