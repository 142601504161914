import { Component } from '@angular/core';
import { Address } from '@spartacus/core';
import { Card, getAddressNumbers } from '@spartacus/storefront';
import { AddressBookComponent } from '@spartacus/user/profile/components';
import { Observable, combineLatest, map } from 'rxjs';

@Component({
  selector: 'cj-address-book',
  templateUrl: './address-book.component.html',
  styleUrl: './address-book.component.scss',
})
export class CjAddressBookComponent extends AddressBookComponent {
  override getCardContent(address: Address): Observable<Card> {
    return combineLatest([
      this.translation.translate('addressCard.default'),
      this.translation.translate('addressCard.setAsDefault'),
      this.translation.translate('common.delete'),
      this.translation.translate('common.edit'),
      this.translation.translate('addressBook.areYouSureToDeleteAddress'),
      this.translation.translate('addressCard.phoneNumber'),
      this.translation.translate('addressCard.mobileNumber'),
    ]).pipe(
      map(([defaultText, setAsDefaultText, textDelete, textEdit, textVerifyDeleteMsg]) => {
        let region = '';

        if (address.region && address.region.isocode) {
          region = address.region.isocode + ', ';
        }

        const actions: { name: string; event: string }[] = [];

        actions.push({ name: textEdit, event: 'edit' });

        if (!address.defaultAddress) {
          actions.push({ name: setAsDefaultText, event: 'default' });
          actions.push({ name: textDelete, event: 'delete' });
        }

        return {
          role: 'region',
          textBold: address.companyName,
          text: [
            address.firstName + ' ' + address.lastName,
            address.line1! + ', ' + address.line2! + ', ' + address.town,
            address.postalCode,
            address.phone,
            address.email,
          ],
          actions: actions,
          header: address.defaultAddress ? `${defaultText}` : '',
          deleteMsg: textVerifyDeleteMsg,
          label: address.defaultAddress ? 'addressBook.defaultDeliveryAddress' : 'addressBook.additionalDeliveryAddress',
        } as Card;
      }),
    );
  }
}
