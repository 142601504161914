import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CmsComponentData, CurrentProductService } from '@spartacus/storefront';
import { map } from 'rxjs';
import { CjProduct } from 'src/app/shared/models/product.model';
import { CjCmsProductDescriptionBanner } from './product-description-banner.model';

@Component({
  selector: 'cj-product-description-banner',
  templateUrl: './product-description-banner.component.html',
  styleUrls: ['./product-description-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjProductDescriptionBannerComponent {
  product$ = this.currentProductService.getProduct().pipe(map((p) => p as CjProduct));

  constructor(
    protected readonly component: CmsComponentData<CjCmsProductDescriptionBanner>,
    private readonly currentProductService: CurrentProductService,
  ) {}
}
