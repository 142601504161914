import { Component } from '@angular/core';
import { LoginFormComponent } from '@spartacus/user/account/components';
import { CjLoginFormComponentService } from './login-form-component.service';

@Component({
  selector: 'cj-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class CjLoginFormComponent extends LoginFormComponent {
  constructor(protected override service: CjLoginFormComponentService) {
    super(service);
  }
}
