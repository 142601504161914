import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, provideDefaultConfig } from '@spartacus/core';
import { MediaModule } from '@spartacus/storefront';
import { CjGsapAnimationDirectiveModule } from 'src/app/shared/directives/directives.module';
import { CjProductCardModule } from '../../product/card/product-card.module';
import { CjProductTwoColumnsComponent } from './product-two-columns.component';

@NgModule({
  imports: [CommonModule, MediaModule, CjProductCardModule, CjGsapAnimationDirectiveModule],
  providers: [
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        OsborneProductTwoColumnsComponent: {
          component: () => import('./product-two-columns.component').then((m) => m.CjProductTwoColumnsComponent),
        },
      },
    }),
  ],
  declarations: [CjProductTwoColumnsComponent],
  exports: [CjProductTwoColumnsComponent],
})
export class CjProductTwoColumnsModule {}
