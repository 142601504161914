<ng-container *ngIf="component.data$ | async as data">
  <div class="category-header-banner" [class.category-header-banner--no-background]="!data.media">
    <div class="category-header-banner__top">
      <h1 *ngIf="data.title" class="category-header-banner__title" [innerHTML]="data.title | sanitizeHtml"></h1>
      <p *ngIf="data.description" class="category-header-banner__description" [innerHTML]="data.description | sanitizeHtml"></p>

      <ng-container
        *ngIf="icons$ | async as component"
        [cxComponentWrapper]="{
          flexType: component.typeCode,
          typeCode: component.typeCode,
          uid: component.uid
        }"
      >
      </ng-container>

      <cj-category-navigation [navigationNodes]="navigationNodes$ | async"></cj-category-navigation>
    </div>

    <cj-media *ngIf="data.media" class="category-header-banner__background" [container]="data.media"></cj-media>
  </div>
</ng-container>
