export const checkout = {
  checkout: {
    securePurchase: 'Compra segura',
  },
  checkoutAddress: {
    addNewAddress: 'Añadir nueva dirección',
    viewAddresses: 'Ver mis direcciones',
    defaultDeliveryAddressTitle: 'Dirección {{count}}',
    deliveryAddressSelected: 'Dirección de entrega seleccionada',
  },
  checkoutGift: {
    giftWithMessage: 'Regalo con un mensaje personalizado',
    giftMessagePlaceholder: 'Mensaje para el regalo',
  },
  checkoutPayment: {
    paymentType: {
      CreditCard: 'Pago con tarjeta bancaria',
      Bizum: 'Pago con Bizum',
      PayPal: 'Pago con PayPal',
    },
    newCard: 'Nueva tarjeta',
    saveCard: 'Guardar esta tarjeta para próximas compras',
    confirmThatRead: 'Al realizar el pedido aceptas nuestra',
    termsAndConditions: 'Política de privacidad',
    placeOrder: 'Realizar pedido',
  },
  paypal: {
    error: {
      default: 'Ha habido un error con PayPal. Por favor, inténtelo de nuevo más tarde.',
      detail: 'No se ha podido completar la transacción. {{msg}}',
      cancel: 'Pago cancelado',
    },
  },
  checkoutOrderConfirmation: {
    thankYou: 'Muchas gracias por <br>confiar en Cinco Jotas, <br><span>{{name}}</span>.',
    invoiceArriveTime: 'En 24-48 horas laborables recibirás tu pedido.',
    invoiceCancelTime: 'Tienes un margen de 30 minutos por si quieres cancelar el pedido',
    orderNumber: 'Nº Pedido: {{code}}',
    orderItems: 'Mi pedido',
    createYourAccount: 'Crea tu cuenta 5J',
    createAccountRequirements: {
      requirements: 'Solo necesitas una contraseña para:',
      followOrder: 'Seguir tu pedido.',
      downloadInvoices: 'Descargar facturas.',
      buyQuicker: 'Comprar mas rápido.',
    },
    wantToCreateAccount: 'Quiero crear mi cuenta Cinco Jotas',
    createAccount: 'Crear cuenta 5J',
    createAccountForNext: 'Crea una cuenta con <b>{{email}}</b> para un checkout más rápido en tu próxima visita.',
    continueBuying: 'Continuar comprando',
    orderDetails: 'Detalle del pedido',
  },
};
