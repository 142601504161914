import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AddedToCartDialogComponent } from '@spartacus/cart/base/components';
import { filter } from 'rxjs/operators';
import { CjCartItemDisplayType } from '../cart-shared/cart.model';

@Component({
  selector: 'cj-added-to-cart-dialog',
  templateUrl: './added-to-cart-dialog.component.html',
  styleUrls: ['./added-to-cart-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjAddedToCartDialogComponent extends AddedToCartDialogComponent implements OnInit {
  cartItemDisplayTypes = CjCartItemDisplayType;

  override ngOnInit(): void {
    this.subscription.add(
      this.routingService
        .getRouterState()
        .pipe(filter((state) => !!state.nextState))
        .subscribe(() => this.dismissModal('dismiss')),
    );
  }
}
