import { Injectable } from '@angular/core';
import { CurrentProductService, SchemaBuilder } from '@spartacus/storefront';
import { Observable, map } from 'rxjs';
import { CjStructuredDataUtilsService } from '../../structured-data-utils-service';

@Injectable({
  providedIn: 'root',
})
export class CjWebPageSchemaBuilder implements SchemaBuilder {
  constructor(
    private readonly utils: CjStructuredDataUtilsService,
    private readonly currentProduct: CurrentProductService,
  ) {}

  build(): Observable<any> {
    // TODO: Ensure we are not in a FAQPage
    // Using currentProductService ensures we are not in a ProductPage
    return this.currentProduct.getProduct().pipe(
      map((product) =>
        !product
          ? {
              '@type': 'WebPage',
              '@id': this.utils.currentUrl + '#webpage',
              url: this.utils.currentUrl,
              breadcrumb: { '@id': this.utils.currentUrl + '#breadcrumb' },
              image: { '@id': this.utils.currentUrl + '#primaryimage' },
              hasPart: { '@id': this.utils.currentUrl + '#faq' },
              speakable: {
                '@type': 'SpeakableSpecification',
                xPath: ['/html/head/title', "/html/head/meta[@name='description']/@content"],
              },
              publisher: { '@id': this.utils.hostUrl + '/#organization' },
              isPartOf: { '@id': this.utils.hostUrl + '/#website' },
            }
          : {},
      ),
    );
  }
}
