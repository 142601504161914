import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActiveCartFacade, Cart } from '@spartacus/cart/base/root';
import { Address } from '@spartacus/core';
import { filter, map, take } from 'rxjs';
import { CjAddressUtilsService } from 'src/app/shared/utils/address-utils.service';
import { CjCart, CjUser } from '../../cart/base/models/cart.model';
import { CjAddressFormComponent } from '../../myaccount/address-book/address-form/address-form.component';
import { CjAddressFormLayout } from '../../myaccount/address-book/address-form/address-form.model';

@Component({
  selector: 'cj-billing-address',
  templateUrl: './checkout-billing-address.component.html',
  styleUrls: ['./checkout-billing-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjCheckoutBillingAddressComponent implements OnInit {
  protected addressFormLayouts = CjAddressFormLayout;
  billingAddress$ = this.activeCartFacade.getActive().pipe(
    filter((cart) => !!cart),
    map((cart: Cart) => (cart as CjCart).paymentAddress),
  );
  nif$ = this.activeCartFacade.getActive().pipe(
    filter((cart) => !!cart),
    map((cart: Cart) => (cart as CjCart).user?.documentIdentifier),
  );

  @ViewChild('billingAddressForm') private billingAddressForm?: CjAddressFormComponent;

  @Output() private submitAddress: EventEmitter<BillingAddressSubmit> = new EventEmitter();

  billingForm: UntypedFormGroup = this.fb.group({
    requestBilling: [false],
    sameAsDelivery: [true],
    nif: [''],
  });

  constructor(
    private readonly fb: FormBuilder,
    private readonly activeCartFacade: ActiveCartFacade,
    private readonly addressUtils: CjAddressUtilsService,
  ) {}

  ngOnInit(): void {
    this.activeCartFacade
      .getActive()
      .pipe(
        filter((cart) => !!cart && !!Object.keys(cart).length),
        take(1),
      )
      .subscribe((cart: Cart) => {
        const paymentAddress = (cart as CjCart).paymentAddress;

        this.billingForm?.patchValue({
          requestBilling: !!paymentAddress,
          sameAsDelivery:
            paymentAddress && cart.deliveryAddress && this.addressUtils.compareAdresses(paymentAddress, cart.deliveryAddress, true),
          nif: (cart.user as CjUser).documentIdentifier,
        });
      });
  }

  onSubmit() {
    if (this.billingForm.get('requestBilling')?.value) {
      if (this.billingForm.get('sameAsDelivery')?.value) {
        if (this.billingForm.get('nif')?.valid) {
          this.submitAddress.emit(this.billingForm.value);
        } else {
          this.billingForm.markAllAsTouched();
        }
      } else {
        this.billingAddressForm?.verifyAddress();
      }
    } else {
      this.submitAddress.emit(this.billingForm.value);
    }
  }

  protected submit(address: Address | undefined) {
    if (this.billingForm.valid) {
      this.submitAddress.emit({ ...this.billingForm.value, address });
    } else {
      this.billingForm.markAllAsTouched();
    }
  }
}
export interface BillingAddressSubmit {
  requestBilling: boolean;
  sameAsDelivery: boolean;
  nif: string;
  address?: Address;
}
