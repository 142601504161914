<ng-container *ngIf="giftCards$ | async as giftCards">
  <ng-container *ngIf="giftCards?.length">
    <form [formGroup]="giftCardForm" class="form-wrapper-cards" (ngSubmit)="submit()">
      <ng-container>
        <label class="custom-gift">
          <input type="checkbox" class="form-control" formControlName="gift" />
          <i class="icomoon icon-gift"></i>
          <span class="label-content required">{{ 'checkoutGift.giftWithMessage' | cxTranslate }}</span>
        </label>
      </ng-container>

      <ng-container *ngIf="giftCardForm.get('gift')?.value === true">
        <div class="wrapper-cards">
          <ng-container *ngFor="let giftCard of giftCards">
            <label class="gift-card" [class.selected]="giftCard.code === giftCardForm.get('giftCard')?.value?.code">
              <input type="radio" [value]="giftCard" formControlName="giftCard" hidden />
              <cx-media [container]="giftCard?.images" class="gift-card-image"></cx-media>
              <span class="gift-card-price">{{ giftCard.price?.formattedValue }}</span>
            </label>
          </ng-container>
        </div>

        <div class="form-group" *ngIf="giftCardForm.get('giftCard')?.value?.requiresMessage === true">
          <textarea
            [maxLength]="350"
            placeholder="{{ 'checkoutGift.giftMessagePlaceholder' | cxTranslate }}"
            formControlName="giftCardMessage"
            class="form-control"
            rows="4"
          ></textarea>
          <cx-form-errors [control]="giftCardForm.get('giftCardMessage')"></cx-form-errors>
        </div>
      </ng-container>
    </form>
  </ng-container>
</ng-container>
