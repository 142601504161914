<ng-container *ngIf="componentData.data$ | async as data">
  <ng-container *ngIf="screenWidth && data.mainLogo && data.mobileLogo">
    <ng-container
      *ngIf="screenWidth >= breakpoints.SM"
      [cxComponentWrapper]="{
        flexType: data.mainLogo.typeCode,
        typeCode: data.mainLogo.typeCode,
        uid: data.mainLogo.uid
      }"
    ></ng-container>
    <ng-container
      *ngIf="screenWidth <= breakpoints.SM"
      [cxComponentWrapper]="{
        flexType: data.mobileLogo.typeCode,
        typeCode: data.mobileLogo.typeCode,
        uid: data.mobileLogo.uid
      }"
    ></ng-container>
  </ng-container>
</ng-container>
