<div *ngIf="product$ | async as product" class="cj-product-summary">
  <ng-template [cxOutlet]="cjOutlets.NAME" [cxOutletContext]="{ product: product }">
    <h1 [innerHTML]="product?.name" class="cj-product-summary__title"></h1>
  </ng-template>

  <ng-template [cxOutlet]="cjOutlets.SUMMARY" [cxOutletContext]="{ product: product }">
    <div [innerHTML]="product?.shortDescription" class="cj-product-summary__summary"></div>
  </ng-template>

  <ng-template [cxOutlet]="cjOutlets.DESCRIPTION" [cxOutletContext]="{ product: product }">
    <div
      #descriptionParagraph
      [innerHTML]="product?.description5j"
      [ngClass]="{ 'limited-text': !limitedText }"
      class="cj-product-summary__description"
    ></div>
    <button *ngIf="shouldDisplayButton()" class="btn btn-link cj-product-summary__toggle-text" (click)="toggleText()">
      {{ !limitedText ? ('productDetail.common.showMore' | cxTranslate) : ('productDetail.common.showLess' | cxTranslate) }}
    </button>
  </ng-template>

  <ng-template [cxOutlet]="cjOutlets.PRICE" [cxOutletContext]="{ product: product }">
    <div [attr.aria-label]="'productSummary.newItemPrice' | cxTranslate" class="cj-product-summary__price">
      <span *ngIf="product?.discountPrice?.formattedValue" class="cj-product-summary__discount">
        {{ product?.discountPrice?.formattedValue }}
      </span>
      <span class="cj-product-summary__number" [class.discount]="product?.discountPrice?.formattedValue">
        {{ product?.price?.formattedValue }}
      </span>
      <span class="cj-product-summary__stock" *ngIf="product.stock?.stockLevelStatus" [class]="product?.webAvailability">
        {{ 'webAvailability.' + product?.webAvailability | cxTranslate: { count: product.stock?.stockLevel } }}
      </span>
    </div>
    <small *ngIf="product?.baseWeightUnitPrice?.formattedValue" class="cj-product-summary__weight-unit-price">
      {{ product?.baseWeightUnitPrice?.formattedValue }}
    </small>
  </ng-template>

  <!-- @TODO: Temp. Comment out share link while not in use by CMS -->
  <!-- <ng-container *cxOutlet="cjOutlets.SHARE">
          <div>
            <a href="#" class="share btn-link">
              {{ 'productSummary.share' | cxTranslate }}
            </a>
          </div>
        </ng-container> -->
</div>
