import { NgModule } from '@angular/core';
import { PRODUCT_NORMALIZER, ProductImageNormalizer } from '@spartacus/core';
import { CjProductImageNormalizer } from './converters/product-image-normalizer';
import { CjProductUrlNormalizer } from './converters/product-url-normalizer';

@NgModule({
  providers: [
    {
      provide: PRODUCT_NORMALIZER,
      useClass: CjProductUrlNormalizer,
      multi: true,
    },
    {
      provide: ProductImageNormalizer,
      useClass: CjProductImageNormalizer,
    },
  ],
})
export class CjProductOccModule {}
