export const userAccount = {
  loginForm: {
    title: 'Ya tengo una cuenta',
    forgotPassword: '¿Has olvidado tu contraseña?',
    signIn: 'Iniciar sesión',
    emailAddress: {
      label: 'Correo electrónico',
      placeholder: 'Correo electrónico',
    },
    password: {
      label: 'Contraseña',
      placeholder: 'Contraseña',
    },
    wrongEmailFormat: 'Este no es un formato de correo electrónico válido.',
  },
  miniLogin: {
    signInRegister: 'Iniciar sesión / Crear cuenta',
  },
};
