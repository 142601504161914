import { Component } from '@angular/core';
import { ICON_TYPE, LaunchDialogService } from '@spartacus/storefront';
import { filter, switchMap, take } from 'rxjs';
import { CjReorderOrderService } from 'src/app/core/order/facade/reorder-order.service';

@Component({
  selector: 'cj-order-detail-reorder-dialog',
  templateUrl: './order-detail-reorder-dialog.component.html',
  styleUrl: './order-detail-reorder-dialog.component.scss',
})
export class CjOrderDetailReorderDialogComponent {
  iconTypes = ICON_TYPE;

  constructor(
    private readonly reorderOrderService: CjReorderOrderService,
    private readonly dialogService: LaunchDialogService,
  ) {}

  dismissModal(reason: string) {
    this.dialogService.closeDialog(reason);
  }

  confirmReorder() {
    this.dialogService.data$
      .pipe(
        filter((data) => !!data?.orderCode),
        take(1),
        switchMap((data) => this.reorderOrderService.reorder(data.orderCode)),
        take(1),
      )
      .subscribe(() => this.dismissModal('success'));
  }
}
