<ng-container *ngIf="component.data$ | async as data">
  <div
    *ngIf="data.productCard || true"
    gsapAnimation
    [animationType]="'fadeInStaggered'"
    class="cj-product-banner"
  >
    <div class="cj-product-banner-content" [class]="data.productCardPosition">
      <h2 *ngIf="data.title" class="cj-product-banner-content__title">
        {{ data.title | uppercase }}
      </h2>

      <ng-container
        *ngIf="data.productCard as productCard"
        [cxComponentWrapper]="{
          flexType: productCard.typeCode,
          typeCode: productCard.typeCode,
          uid: productCard.uid
        }"
      ></ng-container>
    </div>
    <div class="cj-product-banner-image-container">
      <span *ngIf="data.title" class="cj-product-banner-content__title">{{
        data.title | uppercase
      }}</span>
      <cx-media
        *ngIf="data.image"
        class="cj-product-banner-image cj-media--parallax"
        [container]="data.image"
        gsapAnimation
        [animationType]="'parallaxVertical'"
      >
      </cx-media>
    </div>
  </div>
</ng-container>
