<div
  *ngIf="component.data$ | async as data"
  gsapAnimation
  [animationType]="'fadeInSimple'"
>
  <span
    *ngIf="data.title"
    class="banner-title"
    [innerHTML]="data.title | sanitizeHtml"
  ></span>
  <p
    *ngIf="data.description"
    class="banner-description"
    [innerHTML]="data.description | sanitizeHtml"
  ></p>
</div>
