import { Injectable } from '@angular/core';
import { Address } from '@spartacus/core';

export const ADDRESS_KEYS = ['firstName', 'lastName', 'line1', 'line2', 'postalCode', 'region', 'town,', 'phone'];

@Injectable({
  providedIn: 'root',
})
export class CjAddressUtilsService {
  /**
   * Check if all keys of origin address are present on compare address and if the values are the same
   * @param origin Address
   * @param compare Address
   * @returns true if addresses are equal
   */
  compareAdresses(origin: Address, compare: Address, compareEmail = false): boolean {
    if (!origin) return !compare;

    return Object.keys(origin).reduce((acc, key) => {
      if (!(compareEmail ? [...ADDRESS_KEYS, 'email'] : ADDRESS_KEYS).includes(key)) {
        return acc;
      }

      const originVal = (origin as any)[key];
      const compareVal = (compare as any)[key];

      return acc && originVal !== undefined && originVal !== null && originVal.toString() == compareVal?.toString();
    }, true);
  }
}
