import { Component, Input, OnInit } from '@angular/core';
import { CmsNavigationEntry, CmsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { FooterNavigationLinkComponent } from '../../footer-navigation.model';

@Component({
  selector: 'cj-footer-categories-entry',
  templateUrl: './footer-categories-entry.component.html',
  styleUrls: ['./footer-categories-entry.component.scss'],
})
export class CjFooterCategoriesEntryComponent implements OnInit {
  @Input() entry?: CmsNavigationEntry | null;
  data$: Observable<FooterNavigationLinkComponent> | undefined;

  constructor(private readonly cmsService: CmsService) {}

  ngOnInit(): void {
    if (this.entry?.itemId) {
      this.data$ = this.cmsService.getComponentData<FooterNavigationLinkComponent>(this.entry.itemId);
    }
  }
}
