import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { GenericLinkModule, IconModule, MediaModule, NavigationModule, PageComponentModule } from '@spartacus/storefront';
import { CjSiteContextSelectorModule } from '../../content/misc/site-context-selector/site-context-selector.module';
import { CjFooterCategoriesComponent } from './footer-categories/footer-categories.component';
import { CjFooterInfoComponent } from './footer-info/footer-info.component';
import { CjFooterIsosComponent } from './footer-isos/footer-isos.component';
import { CjFooterLearnComponent } from './footer-learn/footer-learn.component';
import { CjFooterLogoComponent } from './footer-logo/footer-logo.component';
import { CjFooterPaymentMethodsComponent } from './footer-payment-methods/footer-payment-methods.component';
import { CjFooterSocialsComponent } from './footer-socials/footer-socials.component';
import { CjNewsletterModule } from '../../content/newsletter/newsletter.module';
import { CjFooterCategoriesEntryComponent } from './footer-categories/footer-categories-entry/footer-categories-entry.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    GenericLinkModule,
    MediaModule,
    I18nModule,
    IconModule,
    PageComponentModule,
    NavigationModule,
    CjSiteContextSelectorModule,
    CjNewsletterModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        OsborneFooterLogoComponent: {
          component: () => import('./footer-logo/footer-logo.component').then((m) => m.CjFooterLogoComponent),
        },
        OsborneFooterLearnComponent: {
          component: () => import('./footer-learn/footer-learn.component').then((m) => m.CjFooterLearnComponent),
        },
        FooterNavigationComponent: {
          component: () => import('./footer-categories/footer-categories.component').then((m) => m.CjFooterCategoriesComponent),
        },
        OsborneFooterCardComponent: {
          component: () => import('./footer-info/footer-info.component').then((m) => m.CjFooterInfoComponent),
        },
        OsborneFooterPaymentMethodsComponent: {
          component: () =>
            import('./footer-payment-methods/footer-payment-methods.component').then((m) => m.CjFooterPaymentMethodsComponent),
        },
        OsborneFooterISOsComponent: {
          component: () => import('./footer-isos/footer-isos.component').then((m) => m.CjFooterIsosComponent),
        },
        OsborneFooterSocialsComponent: {
          component: () => import('./footer-socials/footer-socials.component').then((m) => m.CjFooterSocialsComponent),
        },
      },
    }),
  ],
  declarations: [
    CjFooterLogoComponent,
    CjFooterLearnComponent,
    CjFooterCategoriesComponent,
    CjFooterInfoComponent,
    CjFooterPaymentMethodsComponent,
    CjFooterIsosComponent,
    CjFooterSocialsComponent,
    CjFooterCategoriesEntryComponent,
  ],
  exports: [
    CjFooterLogoComponent,
    CjFooterLearnComponent,
    CjFooterCategoriesComponent,
    CjFooterInfoComponent,
    CjFooterPaymentMethodsComponent,
    CjFooterIsosComponent,
    CjFooterSocialsComponent,
    CjFooterCategoriesEntryComponent,
  ],
})
export class CjFooterNavigationModule {}
