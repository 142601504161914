import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { PageComponentModule } from '@spartacus/storefront';
import { CjProductCardModule } from 'src/app/cms-components/product/card/product-card.module';
import { CjProductSliderComponent } from './product-slider.component';

@NgModule({
  imports: [CommonModule, PageComponentModule, CjProductCardModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        OsborneProductSliderComponent: {
          component: () => import('./product-slider.component').then(() => CjProductSliderComponent),
        },
      },
    }),
  ],
  declarations: [CjProductSliderComponent],
  exports: [CjProductSliderComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CjProductSliderModule {}
