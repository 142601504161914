import { Component, AfterViewInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable, fromEvent } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { CjCmsUtilsService } from 'src/app/shared/utils/cms-utils.service';
import { CjCmsProductHealthyItemComponent, CjCmsProductHealthySliderComponent } from './product-healthy-slider.model';
import { BREAKPOINTS } from 'src/app/shared/constants/screen-breakpoints';
import { WindowRef } from '@spartacus/core';
import { Swiper } from 'swiper/types';

@Component({
  selector: 'cj-product-healthy-slider',
  templateUrl: './product-healthy-slider.component.html',
  styleUrls: ['./product-healthy-slider.component.scss'],
})
export class CjProductHealthySliderComponent implements AfterViewInit {
  @ViewChild('swiperContainer', { static: false }) swiperContainer?: ElementRef;

  screenWidth?: number;
  breakpoints = BREAKPOINTS;
  private swiperInstance: Swiper | null = null;

  constructor(
    protected component: CmsComponentData<CjCmsProductHealthySliderComponent>,
    protected cmsUtils: CjCmsUtilsService,
    private readonly windowRef: WindowRef,
    private cdr: ChangeDetectorRef,
  ) {}

  slides$: Observable<Observable<CjCmsProductHealthyItemComponent>[]> = this.component.data$.pipe(
    map((data) => data.items),
    filter((items) => !!items),
    map((items) => {
      if (!items) {
        return [];
      }
      return this.cmsUtils.getComponentDataList<CjCmsProductHealthyItemComponent>(items);
    }),
  );

  ngAfterViewInit(): void {
    this.screenWidth = this.windowRef.nativeWindow?.innerWidth;
    this.initializeSwiper();

    if (this.windowRef.nativeWindow) {
      fromEvent(this.windowRef.nativeWindow, 'resize')
        .pipe(
          map(() => this.windowRef.nativeWindow?.innerWidth),
          startWith(this.windowRef.nativeWindow?.innerWidth),
        )
        .subscribe((size) => {
          this.screenWidth = size;
          this.updateSwiper();
          this.cdr.detectChanges();
        });
    }
  }

  initializeSwiper(): void {
    if (this.swiperContainer?.nativeElement) {
      const swiperParams = {
        slidesPerView: 1,
        observer: true,
        observeParents: true,
        breakpoints: {
          768: { slidesPerView: 2 },
          1200: { slidesPerView: 3 },
        },
        on: {
          init: this.updateButtonVisibility.bind(this),
          slideChange: this.updateButtonVisibility.bind(this),
          observerUpdate: this.updateButtonVisibility.bind(this),
        },
      };

      Object.assign(this.swiperContainer.nativeElement, swiperParams);
      this.swiperContainer.nativeElement.initialize();
    }
  }

  updateButtonVisibility(): void {
    const prevButton = this.swiperContainer?.nativeElement.shadowRoot.querySelector('.swiper-button-prev');
    const nextButton = this.swiperContainer?.nativeElement.shadowRoot.querySelector('.swiper-button-next');

    if (prevButton) prevButton.style.display = prevButton.classList.contains('swiper-button-disabled') ? 'none' : '';
    if (nextButton) nextButton.style.display = nextButton.classList.contains('swiper-button-disabled') ? 'none' : '';
  }

  updateSwiper(): void {
    if (this.swiperInstance) {
      this.swiperInstance.update();
    }
  }
}
