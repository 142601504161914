import { Injectable } from '@angular/core';
import { CmsBannerComponentMedia, CmsResponsiveBannerComponentMedia, Image, ImageGroup } from '@spartacus/core';

@Injectable({
  providedIn: 'root',
})
export class CjCmsMediaService {
  getMedia(media: CmsBannerComponentMedia | CmsResponsiveBannerComponentMedia): Image | ImageGroup {
    if ('url' in media) {
      return media as Image;
    }
    return media as ImageGroup;
  }
}
