import { getCurrencySymbol } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Input,
  OnInit,
  Optional,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AddToCartComponent } from '@spartacus/cart/base/components/add-to-cart';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import {
  CmsAddToCartComponent,
  EventService,
  Product,
  isNotNullable,
} from '@spartacus/core';
import {
  CmsComponentData,
  CurrentProductService,
  ProductListItemContext,
} from '@spartacus/storefront';
import { filter, take } from 'rxjs';
import { CjProduct } from 'src/app/shared/models/product.model';
import { CjWebAvailabilityType } from '../../models/web-availability.model';

@Component({
  selector: 'cj-add-to-cart',
  templateUrl: './add-to-cart.component.html',
  styleUrls: ['./add-to-cart.component.scss'],
})
export class CjAddToCartComponent extends AddToCartComponent implements OnInit {
  @Input() override product!: CjProduct;

  static staticQuantity = 1;
  static staticPrice?: number;
  currencySymbol?: string;
  unitPrice?: number;
  discountPrice?: number;
  webAvailability?: string;

  webAvailabilityType = CjWebAvailabilityType;

  get quantityControl(): UntypedFormControl {
    return this.addToCartForm.get('quantity') as UntypedFormControl;
  }

  constructor(
    protected override currentProductService: CurrentProductService,
    protected override cd: ChangeDetectorRef,
    protected override activeCartService: ActiveCartFacade,
    protected override component: CmsComponentData<CmsAddToCartComponent>,
    protected override eventService: EventService,
    @Optional()
    protected override productListItemContext?: ProductListItemContext,
  ) {
    super(
      currentProductService,
      cd,
      activeCartService,
      component,
      eventService,
      productListItemContext,
    );
    this.quantityControl.valueChanges.subscribe(
      this.updateStaticQuantity.bind(this),
    );
  }

  protected updateStaticQuantity(value: number): void {
    if (this.unitPrice) {
      CjAddToCartComponent.staticQuantity = value;
      CjAddToCartComponent.staticPrice = this.discountPrice
        ? Number((this.discountPrice * value).toFixed(2))
        : Number((this.unitPrice * value).toFixed(2));
    }
  }

  override ngOnInit() {
    if (this.product) {
      this.initializeProduct(this.product);
    } else if (this.productCode) {
      // force hasStock and quantity for the time being, as we do not have more info:
      this.initializeDefaultProduct();
    } else {
      this.subscribeToProduct();
    }
  }

  protected initializeProduct(product: CjProduct): void {
    this.productCode = product.code ?? '';
    this.webAvailability = product.webAvailability ?? '';
    this.unitPrice = product.price?.value;
    this.discountPrice = product.discountPrice?.value;
    this.currencySymbol = product.price?.currencyIso
      ? getCurrencySymbol(product.price.currencyIso, 'narrow')
      : undefined;
    this.setStockInfo(product);
    this.cd.markForCheck();
  }

  protected initializeDefaultProduct(): void {
    this.quantity = 1;
    this.hasStock = true;
    this.cd.markForCheck();
  }

  protected subscribeToProduct(): void {
    const product$ = this.productListItemContext
      ? this.productListItemContext.product$
      : this.currentProductService.getProduct(['default']);
    this.subscription = product$
      .pipe(filter(isNotNullable))
      .subscribe((product: CjProduct) => {
        this.initializeProduct(product);
      });
  }

  protected override setStockInfo(product: Product): void {
    this.quantity = 1;
    this.addToCartForm.controls['quantity'].setValue(1);
    this.hasStock = Boolean(product.stock?.stockLevelStatus !== 'outOfStock');
    this.inventoryThreshold = product.stock?.isValueRounded ?? false;
    this.maxQuantity = product.stock?.stockLevel ?? (this.hasStock ? 5 : 0);
    if (this.productListItemContext) {
      this.showQuantity = false;
    }
  }

  override addToCart() {
    const quantity = CjAddToCartComponent.staticQuantity;
    if (this.pickupOptionCompRef instanceof ComponentRef) {
      this.pickupOptionCompRef.instance.intendedPickupLocation$
        .pipe(take(1))
        .subscribe((location: any) => {
          this.pickupStore =
            location?.pickupOption === 'pickup' ? location.name : undefined;
        });
    }

    this.activeCartService
      .getEntries()
      .pipe(take(1))
      .subscribe((cartEntries) => {
        this.activeCartService.addEntry(
          this.productCode,
          quantity,
          this.pickupStore,
        );
        this.eventService.dispatch(
          this.createCartUiEventAddToCart(
            this.productCode,
            quantity,
            cartEntries.length,
            this.pickupStore,
          ),
        );
      });
  }

  get price(): number | undefined {
    return CjAddToCartComponent.staticPrice;
  }
}
