import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CjArrayUtilsService {
  group<T>(elements: T[], size: number): T[][] {
    return elements.reduce((acc, element, index) => {
      const chunkIndex = Math.floor(index / size);

      if (!acc[chunkIndex]) {
        (acc[chunkIndex] as []) = [];
      }

      acc[chunkIndex].push(element);

      return acc;
    }, [] as T[][]);
  }
}
