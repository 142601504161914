<ng-container *ngIf="component.data$ | async as data">
  <ng-container *ngIf="productList$ | async as productList">
    <ng-container *ngIf="productList.length">
      <div class="cj-product-slider d-none d-lg-block">
        <h2 *ngIf="data.title" class="tit tit--h2 tit--center">{{ data.title }}</h2>
        <swiper-container
          slides-per-view="3"
          slides-per-group="3"
          space-between="24"
          navigation="true"
          observer="true"
          observe-parents="true"
        >
          <swiper-slide *ngFor="let product of productList">
            <cj-product-card [code]="product" [displayType]="data.displayType" [artistic]="true" [hover]="false"></cj-product-card>
          </swiper-slide>
        </swiper-container>
      </div>

      <!-- Mobile does not use swiper, hidden by styles -->
      <div class="products-wrapper d-block d-lg-none">
        <cj-product-card *ngFor="let product of productList" [code]="product" [displayType]="data.displayType" [artistic]="true" [hover]="false"></cj-product-card>
      </div>

      <ng-container
        *ngIf="data.link as link"
        [cxComponentWrapper]="{
          flexType: link.typeCode,
          typeCode: link.typeCode,
          uid: link.uid
        }"
      ></ng-container>
    </ng-container>
  </ng-container>
</ng-container>
