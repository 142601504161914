import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { CjProductCardDisplayType } from '../../product/card/product-card.model';
import { CjCmsProductTwoColumnsComponent } from './product-two-columns.model';

@Component({
  selector: 'cj-product-two-columns',
  templateUrl: './product-two-columns.component.html',
  styleUrls: ['./product-two-columns.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjProductTwoColumnsComponent {
  displayTypes = CjProductCardDisplayType;

  constructor(protected component: CmsComponentData<CjCmsProductTwoColumnsComponent>) {}
}
