import { NgModule } from '@angular/core';

@NgModule({
  imports: [],
  providers: [
    // CheckoutPageMetaResolver is provided in CjCheckoutModule due to context
    // { provide: CheckoutPageMetaResolver, useExisting: CjCheckoutPageMetaResolver },
  ],
})
export class CjMetaTagsModule {}
