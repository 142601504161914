<ng-container *ngIf="cart$ | async as cart">
  <ng-container *ngIf="entries$ | async as entries">
    <div *ngIf="cart.totalItems && cart.totalItems > 0" class="cart-details-wrapper">
      <cx-cart-validation-warnings></cx-cart-validation-warnings>
      <div class="cart-details-header d-flex align-items-center">
        <h2 class="cart-details-header__title m-0">
          {{ 'cartDetails.title' | cxTranslate }}
        </h2>
        <a class="link-underlined" [routerLink]="{ cxRoute: 'shop' } | cxUrl">{{ 'cartDetails.continueShopping' | cxTranslate }}</a>
      </div>
      <cj-cart-item-list
        [items]="entries"
        [cartIsLoading]="(cartLoaded$ | async) === false"
        [promotionLocation]="promotionLocation"
      ></cj-cart-item-list>
    </div>
  </ng-container>
</ng-container>
