import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { ProcessSelectors, RoutingService, StateWithProcess, UserIdService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CsTicketCause, CsTicketConfiguration, CsTicketContactForm } from './contact-form.model';
import { ContactFormActions } from './store/actions';
import { SEND_CONTACT_FORM_PROCESS_ID, StateWithContactForm } from './store/contact-form-state';
import { ContactFormSelectors } from './store/selector';
import { CustomValidators } from 'src/app/shared/utils/validators/custom-validators';

@Injectable({
  providedIn: 'root',
})
export class ContactFormService {
  constructor(
    protected store: Store<StateWithProcess<void>>,
    protected contactStore: Store<StateWithContactForm>,
    protected userIdService: UserIdService,
    protected routingService: RoutingService,
  ) {}

  form: FormGroup = new FormGroup({
    orderCode: new FormControl(
      {
        disabled: true,
        value: '',
      },
      Validators.required,
    ),
    email: new FormControl('', { validators: [Validators.required, Validators.maxLength(60), CustomValidators.emailValidator], updateOn: 'blur' }),
    message: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    subject: new FormControl('', Validators.required),
    templateCode: new FormControl(null),
    causeCode: new FormControl(null),
    accept: new FormControl(false, Validators.requiredTrue),
    qualityProblem: new FormControl(
      {
        disabled: true,
        value: null,
      },
      Validators.required,
    ),
    requiredAction: new FormControl(
      {
        disabled: true,
        value: '',
      },
      Validators.maxLength(255),
    ),
    productsAffected: new FormArray([this.createProductAffectedFormGroup()]),
  });

  createProductAffectedFormGroup(): FormGroup {
    return new FormGroup({
      productDescription: new FormControl('', Validators.required),
      quantity: new FormControl(1, [Validators.required, Validators.min(1)]),
    });
  }

  addProductAffected(): void {
    this.productsAffectedFormArray.push(this.createProductAffectedFormGroup());
  }

  removeProductAffected(index: number): void {
    this.productsAffectedFormArray.removeAt(index);
  }

  /**
   * Reset form fields except email
   */
  resetForm(): void {
    this.form.reset({
      email: this.form.get('email')!.value,
    });
    this.productsAffectedFormArray.clear();
    this.addProductAffected();
  }

  private toggleControls(enable: boolean, paths: string[]): void {
    paths.forEach((path: string) => {
      if (enable) {
        this.form.get(path)!.enable();
      } else {
        this.form.get(path)!.disable();
      }
      this.form.get(path)!.updateValueAndValidity();
    });
  }

  toggleTemplateControls(ticketConfiguration: CsTicketConfiguration): void {
    const toggleablePaths = ['orderCode', 'qualityProblem', 'requiredAction', 'productsAffected'];
    let enablePaths: string[];
    switch (ticketConfiguration) {
      case CsTicketConfiguration.WITH_ORDER_CODE:
        enablePaths = ['orderCode'];
        break;
      case CsTicketConfiguration.RETURN_FOR_QUALITY:
        enablePaths = ['orderCode', 'qualityProblem', 'requiredAction', 'productsAffected'];
        break;
      default:
        enablePaths = [];
        break;
    }
    this.toggleControls(
      false,
      toggleablePaths.filter((path) => !enablePaths.includes(path)),
    );
    this.toggleControls(true, enablePaths);
  }

  get productsAffectedFormArray(): FormArray {
    return this.form.get('productsAffected') as FormArray;
  }

  /**
   * Returns  Contact Form
   */
  getContactFormList(templateConfigurations: string): Observable<CsTicketCause[]> {
    return this.contactStore.pipe(
      select(ContactFormSelectors.getContactFormLoaderState),
      tap((customContactFormListState: any) => {
        const attemptedLoad = customContactFormListState.loading || customContactFormListState.success || customContactFormListState.error;
        if (!attemptedLoad) {
          this.loadContactFormList(templateConfigurations);
        }
      }),
      map((customContactFormListState: any) => customContactFormListState.value),
    );
  }

  /**
   * Returns a loaded flag for  Contact Form
   */
  getContactFormLoaded(): Observable<boolean> {
    return this.contactStore.pipe(select(ContactFormSelectors.getContactFormLoaded));
  }

  /**
   * Retrieves  Contact Form/CsTicketCauseList
   */
  loadContactFormList(templateConfigurations: string): void {
    this.store.dispatch(new ContactFormActions.LoadContactForm(templateConfigurations));
  }

  /**
   * Cleaning  Contact Form
   */
  clearContactFormList(): void {
    this.store.dispatch(new ContactFormActions.ClearContactForm());
  }

  /**
   * Send Contact Form.
   */
  sendContactForm(form: CsTicketContactForm, templateConfiguration: string): void {
    this.store.dispatch(
      new ContactFormActions.SendContactForm({
        form,
        templateConfiguration,
      }),
    );
  }

  /**
   * Returns the Send Contact Form loading flag
   */
  getSendContactFormLoading(): Observable<boolean> {
    return this.store.pipe(select(ProcessSelectors.getProcessLoadingFactory(SEND_CONTACT_FORM_PROCESS_ID)));
  }

  /**
   * Returns the Send Contact Form success flag
   */
  getSendContactFormSuccess(): Observable<boolean> {
    return this.store.pipe(select(ProcessSelectors.getProcessSuccessFactory(SEND_CONTACT_FORM_PROCESS_ID)));
  }

  /**
   * Returns the Send Contact Form state
   */
  getSendContactFormState(): Observable<any> {
    return this.store.pipe(select(ProcessSelectors.getProcessStateFactory(SEND_CONTACT_FORM_PROCESS_ID)));
  }

  /**
   * Returns the Send Contact Form error flag
   */
  getSendContactFormError(): Observable<boolean> {
    return this.store.pipe(select(ProcessSelectors.getProcessErrorFactory(SEND_CONTACT_FORM_PROCESS_ID)));
  }

  /**
   * Clears the process state of performing a newsletter subscription
   */
  clearSendContactForm(): void {
    this.store.dispatch(new ContactFormActions.ClearSendContactForm());
  }
}
