import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CjCmsProductSlider } from './product-slider.model';

@Component({
  selector: 'cj-product-slider',
  templateUrl: 'product-slider.component.html',
  styleUrls: ['product-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjProductSliderComponent {
  productList$: Observable<string[]> = this.component.data$.pipe(
    map((data) => data.productList),
    filter((productList) => !!productList),
    map((productList) => {
      if (!productList) {
        return [];
      }
      return productList.split(' ');
    }),
  );

  constructor(readonly component: CmsComponentData<CjCmsProductSlider>) {}
}
