<div *ngIf="component.data$ | async as data" class="cj-banner-faq-banner">
  <div class="cj-banner-faq-banner__header">
    <h4 class="cj-banner-faq-banner__title" *ngIf="data.title">{{ data.title }}</h4>
    <ng-container
      *ngIf="data.link as link"
      [cxComponentWrapper]="{
        flexType: link.typeCode,
        typeCode: link.typeCode,
        uid: link.uid,
      }"
    >
    </ng-container>
  </div>

  <div *ngIf="qas$ | async as qas" class="cj-banner-faq-banner__content">
    <div *ngFor="let qa$ of qas" class="cj-banner-faq-banner__item">
      <ng-container *ngIf="qa$ | async as qa">
        <p *ngIf="qa.question && qa.uid" (click)="toggleCollapse(qa.uid)" [class.collapsed]="isCollapsed(qa.uid)" class="cj-banner-faq-banner__question">
          {{ qa.question }}
        </p>
        <p *ngIf="qa.answer && qa.uid" [class.collapsed]="isCollapsed(qa.uid)" class="cj-banner-faq-banner__answer">
          {{ qa.answer }}
        </p>
      </ng-container>
    </div>
  </div>
</div>

<span *ngIf="structuredData$ | async as structuredData" [cxJsonLd]="structuredData"></span>
