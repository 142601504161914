import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { effects } from './effects/index';

@NgModule({
  imports: [CommonModule, EffectsModule.forFeature(effects)],
  providers: [],
})
export class CjUserStoreModule {}
