<ng-container *ngIf="data">
  <button
    *ngIf="data.link as link"
    type="button"
    class="menu-collapsable-button"
    [class.is-active]="isExpanded"
    [attr.aria-expanded]="isExpanded"
    [attr.aria-label]="'common.menu' | cxTranslate"
    (click)="toggleMenu(data.uuid)"
  >
    <span class="menu-collapsable-button__link">
      {{ link?.linkName }}
    </span>
    <span class="menu-collapsable-button__icon"></span>
  </button>

  <div
    *ngIf="data.cards"
    class="menu-collapsable-cards"
    [class.isExpanded]="isExpanded"
  >
    <div class="menu-image-cards">
      <ng-container *ngFor="let card$ of getCards(data.cards)">
        <cx-generic-link
          *ngIf="card$ | async as card"
          [url]="card?.link?.url || ''"
          class="menu-image-cards__link animation--top"
        >
          <cx-media [container]="card?.media"></cx-media>
          {{ card.link?.linkName }}
        </cx-generic-link>
      </ng-container>
    </div>

    <div *ngIf="data.links" class="menu-links-cards animation--top">
      <ng-container *ngFor="let link$ of getLinks(data.links)">
        <cx-generic-link
          *ngIf="link$ | async as link"
          [url]="link.url || ''"
          class="menu-links-cards__item"
        >
          {{ link.linkName }}
        </cx-generic-link>
      </ng-container>
    </div>
  </div>
</ng-container>
