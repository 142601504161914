export const myAccount = {
  myAccountCommon: {
    hello: 'Hello, ',
  },
  updatePasswordForm: {
    passwordInfo: 'Password',
    oldPassword: {
      label: 'Old Password',
      placeholder: 'Old Password',
    },
    oldPasswordIsRequired: 'Old password is required.',
    newPassword: {
      label: 'New Password',
      placeholder: 'New Password',
    },
    passwordMinRequirements: 'Password must be six characters minimum, with one uppercase letter, one number, one symbol',
    confirmPassword: {
      label: 'Confirm New Password',
      placeholder: 'Confirm Password',
    },
    bothPasswordMustMatch: 'Both password must match',
    passwordUpdateSuccess: 'Password updated with success',
    accessDeniedError: 'Access is denied',
  },
  updateProfileForm: {
    title: 'Treatment',
    firstName: {
      label: 'First and Last Name',
      placeholder: 'First and Last Name',
    },
    firstNameIsRequired: 'First name is required.',
    contactInfo: 'Contact',
    addressInfo: 'Billing Address',
    email: 'Email',
    documentIdentifier: 'NIF',
    phone: 'Phone Number (for carrier)',
    address: 'Address',
    postalCode: 'Postal Code',
    town: 'City',
    country: {
      label: 'City',
      placeholder: 'City',
    },
    region: {
      label: 'Province',
      placeholder: 'Province',
    },
    profileUpdateSuccess: 'Personal data updated successfully',
    updateEmailText: 'Para modificaciones del email, por favor ponte en contacto con sac_web@osborne.es'
  },
};
