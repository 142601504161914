<ng-container *ngIf="component.data$ | async as data">
  <ng-container *ngIf="data.icons">
    <h2 *ngIf="data.title" class="instagram-slider-title" [innerHTML]="data.title | sanitizeHtml"></h2>
    <ng-container *ngIf="chunkSize$ | async as size">
      <swiper-container
        [style.--instagram-slider-elements]="size"
        slides-per-view="1.8"
        centered-slides="true"
        centered-slides-bounds="true"
        [breakpoints]="{
          '750': {
            slidesPerView: 1.1,
            centeredSlidesBounds: true
          }
        }"
      >
        <ng-container *ngIf="iconGroups$ | async as iconGroups">
          <swiper-slide class="instagram-slider-group" *ngFor="let icons of iconGroups; index as i">
            <div class="instagram-slider-group-item" *ngFor="let icon$ of icons; index as j">
              <ng-container *ngIf="icon$ | async as icon">
                <cx-generic-link [url]="icon.routerLink || ''" [target]="icon.target">
                  <cx-media *ngIf="icon.media as media" [class.short-image]="(i * size + j) % 2" [container]="media"></cx-media>
                </cx-generic-link>
              </ng-container>
            </div>
          </swiper-slide>
        </ng-container>
      </swiper-container>
    </ng-container>
  </ng-container>
</ng-container>
