import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { CmsBannerComponentMedia, CmsResponsiveBannerComponentMedia, Image, ImageGroup } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CjCmsUtilsService } from 'src/app/shared/utils/cms-utils.service';
import { CjCmsAnimationSlide, CjCmsAnimationSlider } from './animation-slider.model';

@Component({
  selector: 'cj-animation-slider',
  templateUrl: './animation-slider.component.html',
  styleUrls: ['./animation-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CjAnimationSliderComponent implements AfterViewInit {
  @ViewChild('swiperContainer', { static: false }) swiperContainer?: ElementRef;

  slides$: Observable<Observable<CjCmsAnimationSlide>[]> = this.component.data$.pipe(
    map((data) => data.slides),
    filter((slides) => !!slides),
    map((slides) => {
      if (!slides) {
        return [];
      }
      return this.cmsUtils.getComponentDataList<CjCmsAnimationSlide>(slides);
    }),
  );

  constructor(
    readonly component: CmsComponentData<CjCmsAnimationSlider>,
    private readonly cmsUtils: CjCmsUtilsService,
  ) {}

  ngAfterViewInit(): void {
    this.initializeSwiper();
  }

  initializeSwiper(): void {
    if (this.swiperContainer?.nativeElement) {
      const swiperParams = {
        slidesPerView: 1,
        observer: true,
        observeParents: true,
        on: {
          init: this.updateButtonVisibility.bind(this),
          slideChange: this.updateButtonVisibility.bind(this),
          observerUpdate: this.updateButtonVisibility.bind(this),
        },
      };

      Object.assign(this.swiperContainer.nativeElement, swiperParams);
      this.swiperContainer.nativeElement.initialize();
    }
  }

  updateButtonVisibility(): void {
    const prevButton = this.swiperContainer?.nativeElement.shadowRoot.querySelector('.swiper-button-prev');
    const nextButton = this.swiperContainer?.nativeElement.shadowRoot.querySelector('.swiper-button-next');

    if (prevButton) prevButton.style.display = prevButton.classList.contains('swiper-button-disabled') ? 'none' : '';
    if (nextButton) nextButton.style.display = nextButton.classList.contains('swiper-button-disabled') ? 'none' : '';
  }

  getMedia(media: CmsBannerComponentMedia | CmsResponsiveBannerComponentMedia): Image | ImageGroup {
    if ('url' in media) {
      return media as Image;
    }
    return media as ImageGroup;
  }
}
