<div
  class="cj-item-counter"
  (click)="toggleOptions()"
  [class.open]="optionsOpen"
>
  <div class="cj-item-counter__selected-value">{{ control.value }}</div>
  <div class="cj-item-counter__options" [class.open]="optionsOpen">
    <div
      class="cj-item-counter__option"
      *ngFor="let option of quantityOptions"
      (click)="selectOption(option, $event)"
    >
      {{ option }}
    </div>
  </div>
</div>
