import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { CurrentProductService, ProductSummaryComponent } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { CjProduct } from 'src/app/shared/models/product.model';
import { CjProductDetailOutlets } from '../product-outlets.model';

@Component({
  selector: 'cj-product-summary',
  templateUrl: './product-summary.component.html',
  styleUrls: ['./product-summary.component.scss'],
})
export class CjProductSummaryComponent extends ProductSummaryComponent implements AfterViewInit {
  override product$: Observable<CjProduct | null> = this.currentProductService.getProduct(['default']);

  @ViewChild('descriptionParagraph', { static: false })
  descriptionParagraph: ElementRef | undefined;

  cjOutlets = CjProductDetailOutlets;
  limitedText = false;

  constructor(
    protected override currentProductService: CurrentProductService,
    private readonly windowRef: WindowRef,
  ) {
    super(currentProductService);
  }

  toggleText() {
    this.limitedText = !this.limitedText;
  }

  shouldDisplayButton(): boolean {
    if (this.descriptionParagraph && this.windowRef.nativeWindow) {
      const paragraph = this.descriptionParagraph.nativeElement;
      const lineHeight = parseInt(this.windowRef.nativeWindow.getComputedStyle(paragraph).lineHeight);
      const height = paragraph.clientHeight;
      const numLines = height / lineHeight;

      return numLines > 4;
    }
    return false;
  }

  ngAfterViewInit() {
    this.limitedText = this.shouldDisplayButton();
  }
}
