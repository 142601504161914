import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { CartActions, StateWithMultiCart } from '@spartacus/cart/base/core';
import { CheckoutStepService } from '@spartacus/checkout/base/components';
import { CheckoutStepType } from '@spartacus/checkout/base/root';
import { RoutingService, SemanticPathService } from '@spartacus/core';
import { OrderConfirmationGuard } from '@spartacus/order/components';
import { OrderFacade } from '@spartacus/order/root';
import { Observable, filter, map, of, switchMap, tap } from 'rxjs';
import { CjOrderHistoryService } from 'src/app/core/order/facade/order-history.service';

@Injectable({
  providedIn: 'root',
})
export class CjOrderConfirmationGuard extends OrderConfirmationGuard {
  constructor(
    protected override orderFacade: OrderFacade,
    protected override router: Router,
    protected override semanticPathService: SemanticPathService,
    private readonly orderHistoryFacade: CjOrderHistoryService,
    private readonly routingService: RoutingService,
    private readonly checkoutStepService: CheckoutStepService,
    private readonly store: Store<StateWithMultiCart>,
  ) {
    super(orderFacade, router, semanticPathService);
  }

  override canActivate(): Observable<boolean | UrlTree> {
    this.orderHistoryFacade.clearOrderDetails();

    return this.routingService.getNextPageContext().pipe(
      map((context) => context?.id.split('/').reverse()[0]),
      switchMap((orderCode) => {
        if (!orderCode) return of(this.navigateBackToCheckout());

        this.orderHistoryFacade.loadOrderDetails(orderCode);

        return this.orderHistoryFacade.getOrderDetailsState().pipe(
          filter(({ loading, success, error }) => !!(!loading && (success || error))),
          map(({ value }) => (value && Object.keys(value).length ? true : this.navigateBackToCheckout())),
          tap(() => this.store.dispatch(new CartActions.RemoveCart({ cartId: orderCode }))),
        );
      }),
    );
  }

  navigateBackToCheckout(): UrlTree {
    const stepRoute = this.checkoutStepService.getCheckoutStep(CheckoutStepType.PAYMENT_DETAILS)?.routeName || '';
    return this.router.parseUrl(this.semanticPathService.get(stepRoute) || '');
  }
}
