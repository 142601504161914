import { Component, ElementRef, Input, Optional, ViewChild, ViewContainerRef } from '@angular/core';
import { OrderSummaryComponent } from '@spartacus/cart/base/components';
import { PromotionLocation } from '@spartacus/cart/base/root';
import { RoutingService, SemanticPathService, TranslationService } from '@spartacus/core';
import { LaunchDialogService, OutletContextData } from '@spartacus/storefront';
import { Observable, map, take } from 'rxjs';
import { POPUP_DIALOG } from '../../popup/popup-dialog/popup-dialog-layout.config';
import { CjCartItemDisplayType } from '../cart.model';

@Component({
  selector: 'cj-order-summary',
  templateUrl: './order-summary.component.html',
})
export class CjOrderSummaryComponent extends OrderSummaryComponent {
  @ViewChild('vatPopupButton') vatPopupButton?: ElementRef;
  @Input() showItems? = true;

  cartItemDisplayTypes = CjCartItemDisplayType;
  isCartPage$: Observable<boolean> = this.routingService.getRouterState().pipe(
    map((state) => {
      const targetUrl = this.semanticPathService.get('cart');
      return targetUrl ? state.state.url.includes(targetUrl) : false;
    }),
  );
  promotionLocation$ = this.outlet?.context$.pipe(
    map((context) => (context.promotionLocation !== undefined ? context.promotionLocation : PromotionLocation.ActiveCart)),
  );

  constructor(
    private readonly semanticPathService: SemanticPathService,
    private readonly routingService: RoutingService,
    private readonly dialogService: LaunchDialogService,
    private readonly vcr: ViewContainerRef,
    private readonly translation: TranslationService,
    @Optional() override outlet?: OutletContextData<any>,
  ) {
    super(outlet);
  }

  openVatPopup() {
    this.translation
      .translate('orderCost.taxMessage')
      .pipe(take(1))
      .subscribe((text) => this.dialogService.openDialog(POPUP_DIALOG, this.vatPopupButton, this.vcr, { text })?.pipe(take(1)).subscribe());
  }
}
