import { Component, Input, inject } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import {
  AddressValidation,
  GlobalMessageService,
  Region,
  SiteConnector,
  TranslationService
} from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { AddressFormComponent } from '@spartacus/user/profile/components';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { of, switchMap, take, tap } from 'rxjs';
import { CjUserAddressService } from 'src/app/core/user/facade/user-address.service';
import { CustomValidators } from 'src/app/shared/utils/validators/custom-validators';
import { CjAddressFormLayout } from './address-form.model';

@Component({
  selector: 'cj-address-form',
  templateUrl: './address-form.component.html',
  styleUrl: './address-form.component.scss',
})
export class CjAddressFormComponent extends AddressFormComponent {
  siteConnector: SiteConnector = inject(SiteConnector);

  protected layouts = CjAddressFormLayout;
  @Input() formLayout: CjAddressFormLayout = CjAddressFormLayout.SHIPPING;
  @Input() showActionBtns = true;
  @Input() loadAllCountries = true;

  override addressForm: FormGroup = this.fb.group({
    companyName: [''],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: [
      '',
      { validators: [
        Validators.required,
        Validators.maxLength(60),
        CustomValidators.emailValidator,
      ], updateOn: 'blur'},
    ],
    phone: ['', [Validators.required, CustomValidators.phoneValidator]],
    line1: ['', [Validators.required, Validators.maxLength(35)]],
    line2: ['', Validators.maxLength(35)],
    postalCode: [
      '',
      [
        Validators.required,
        Validators.maxLength(12),
        CustomValidators.postalCode('country.isocode'),
      ],
    ],
    town: ['', [Validators.required, Validators.maxLength(25)]],
    country: this.fb.group({
      isocode: [null, Validators.required],
    }),
    region: this.fb.group({
      isocode: [null, Validators.required],
    }),
    defaultAddress: [false],
  });

  constructor(
    protected override fb: UntypedFormBuilder,
    protected override userAddressService: CjUserAddressService,
    protected override globalMessageService: GlobalMessageService,
    protected override translation: TranslationService,
    protected override launchDialogService: LaunchDialogService,
    protected override userProfileFacade: UserProfileFacade,
  ) {
    super(
      fb,
      userAddressService,
      globalMessageService,
      translation,
      launchDialogService,
      userProfileFacade,
    );
  }

  override ngOnInit() {
    if (this.formLayout === CjAddressFormLayout.GUEST) {
      this.addressForm.removeControl('email');
    }

    this.loadAllCountries
      ? this.userAddressService.loadDeliveryCountries()
      : this.userAddressService.loadBaseStoreDeliveryCountries();

    // Fetching countries
    this.countries$ = this.userAddressService.getDeliveryCountries();

    // Fetching titles
    this.titles$ = this.getTitles();

    // Fetching regions
    this.regions$ = this.selectedCountry$.pipe(
      switchMap((country) =>
        country ? this.siteConnector.getRegions(country) : of([]),
      ),
      tap((regions: Region[]) => {
        const regionControl = this.addressForm.get('region.isocode');
        if (regions && regions.length > 0) {
          regionControl?.enable();
        } else {
          regionControl?.disable();
        }
      }),
    );

    if (this.addressData && Object.keys(this.addressData).length !== 0) {
      this.addressForm.patchValue(this.addressData);

      this.countrySelected(this.addressData.country);
      if (this.addressData.region) {
        this.regionSelected(this.addressData.region);
      }
    }

    this.addresses$ = this.userAddressService.getAddresses();
  }

  override verifyAddress(emailOverride?: string): void {
    if (this.addressForm.valid) {
      const regionControl = this.addressForm.get('region');
      const isocode = regionControl?.value?.isocode;

      if (isocode) {
        this.regions$.pipe(take(1)).subscribe((regions: Region[]) => {
          if (regions.length) {
            const selectedRegion = regions.find(
              (region: Region) => region.isocode === isocode,
            );
            regionControl?.patchValue({
              isocodeShort: selectedRegion?.isocodeShort,
            });
          } else {
            regionControl?.reset();
          }
        });
      }

      if (this.addressForm.dirty) {
        this.subscription.add(
          this.userAddressService
            .verifyAddress(this.addressForm.value)
            .subscribe((value) => {
              this.handleAddressVerificationResults(value, emailOverride);
            }),
        );
      } else {
        // address form value not changed
        // ignore duplicate address
        this.submitAddress.emit(undefined);
      }
    } else {
      this.addressForm.markAllAsTouched();
    }
  }

  protected override handleAddressVerificationResults(
    results: AddressValidation,
    emailOverride?: string,
  ) {
    if (results.decision === 'ACCEPT') {
      // Guest delivery address removes email control and uses other value
      this.submitAddress.emit({
        email: emailOverride,
        ...this.addressForm.value,
      });
    } else {
      super.handleAddressVerificationResults(results);
    }
  }
}
