<ng-container *ngIf="characteristics$ | async as characteristicsList">
  <div
    class="cj-product-characteristics"
    gsapAnimation
    [animationType]="'fadeInStaggered'"
  >
    <ng-container *ngFor="let characteristic$ of characteristicsList">
      <div
        class="product-characteristic"
        *ngIf="characteristic$ | async as characteristic"
      >
        <p class="product-characteristic__title" *ngIf="characteristic.title">
          {{ characteristic.title }}
        </p>
        <p
          class="product-characteristic__first-title"
          *ngIf="characteristic.firstTitle"
        >
          {{ characteristic.firstTitle }}
        </p>
        <p
          class="product-characteristic__second-title"
          *ngIf="characteristic.secondTitle"
        >
          {{ characteristic.secondTitle }}
        </p>
        <p
          class="product-characteristic__description"
          *ngIf="characteristic.description"
        >
          {{ characteristic.description }}
        </p>
      </div>
    </ng-container>
  </div>
</ng-container>
