import { Injectable } from '@angular/core';
import { CurrentProductService, SchemaBuilder } from '@spartacus/storefront';
import { Observable, map } from 'rxjs';
import { CjProduct } from 'src/app/shared/models/product.model';
import { CjStructuredDataUtilsService } from '../../structured-data-utils-service';

@Injectable({
  providedIn: 'root',
})
export class CjItemPageSchemaBuilder implements SchemaBuilder {
  product?: CjProduct;
  get fullProductUrl() {
    return this.utils.hostUrl + this.product?.url;
  }

  constructor(
    private readonly currentProduct: CurrentProductService,
    private readonly utils: CjStructuredDataUtilsService,
  ) {}

  build(): Observable<any> {
    // Using currentProductService ensures we are in a ProductPage
    return this.currentProduct.getProduct().pipe(
      map((p: CjProduct | null) => {
        if (p) {
          this.product = p;

          let schema: any = {
            '@type': 'ItemPage',
            '@id': this.fullProductUrl + '#webpage',
            url: this.fullProductUrl,
            breadcrumb: { '@id': this.fullProductUrl + '#breadcrumb' },
            hasPart: { '@id': this.fullProductUrl + '#faq' },
            speakable: {
              '@type': 'SpeakableSpecification',
              xPath: ['/html/head/title', "/html/head/meta[@name='description']/@content"],
            },
            publisher: { '@id': this.utils.hostUrl + '/#organization' },
            isPartOf: { '@id': this.utils.hostUrl + '/#website' },
          };

          if (p.images) schema.image = { '@id': this.fullProductUrl + '#primaryimage' };

          return schema;
        }
        return {};
      }),
    );
  }
}
