import { Injectable, OnDestroy } from '@angular/core';
import { ConfigInitializerService, isNotUndefined } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { CjBaseStoreService } from 'src/app/cms-components/content/misc/site-context-selector/facades/base-store.service';
import { BaseStoreStatePersistenceService } from './base-store-persistence.service';

@Injectable({ providedIn: 'root' })
export class CjBaseStoreInitializer implements OnDestroy {
  constructor(
    protected baseStoreService: CjBaseStoreService,
    protected persistenceService: BaseStoreStatePersistenceService,
    protected configInit: ConfigInitializerService,
  ) {}

  protected subscription: Subscription | undefined;

  /**
   * Initializes the value of the active language.
   */
  initialize(): void {
    this.subscription = this.configInit
      .getStable('context')
      .pipe(
        switchMap(() => this.persistenceService.initSync()),
        switchMap(() => this.setFallbackValue()),
      )
      .subscribe();
  }

  protected setFallbackValue(): Observable<unknown> {
    return this.configInit.getStable('context').pipe(tap(() => this.setDefaultStore()));
  }

  protected setDefaultStore(): void {
    if (!this.baseStoreService.isInitialized()) {
      this.baseStoreService
        .getBaseStores()
        .pipe(
          filter(isNotUndefined),
          filter((stores) => stores.length > 0),
          take(1),
        )
        .subscribe((stores) => {
          this.baseStoreService.setActive(stores[0]);
        });
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
