import { Component } from '@angular/core';
import { CmsNavigationComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';

@Component({
  selector: 'cj-footer-categories',
  templateUrl: './footer-categories.component.html',
  styleUrls: ['./footer-categories.component.scss'],
})
export class CjFooterCategoriesComponent {
  collapsedNodes: Set<string> = new Set();

  constructor(protected componentData: CmsComponentData<CmsNavigationComponent>) {}

  toggleCollapse(nodeUid: string): void {
    if (this.collapsedNodes.has(nodeUid)) {
      this.collapsedNodes.delete(nodeUid);
    } else {
      this.collapsedNodes.add(nodeUid);
    }
  }

  isCollapsed(nodeUid: string): boolean {
    return this.collapsedNodes.has(nodeUid);
  }
}
